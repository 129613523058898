<template>
  <div class="modal">
    <div class="card">
      <div class="card-header">
        <h1>Welcome to DashQuill 👋</h1>
        <p>We're excited to have you! To get started, please select some template patient forms that you would like to start your account with.</p>
      </div>
      <form class="card-body">
        <transition name="appear" mode="out-in">
          <div class="form-list" v-if="loaded">
            <TemplateListItem v-for="form of forms" :key="form.id" :form="form" @mousedown.prevent="toggleFormSelected(form)" />
          </div>
        </transition>
      </form>
      <div class="card-footer">
        <button class="button primary" @click.prevent="copyFormsIntoAccount()">
          <img v-if="pending" class="loading" src="/loading.svg" alt="Loading" />
          <span v-else>Continue</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {copyFormTemplates, fetchFormTemplates} from '@/api'
import TemplateListItem from '../../components/account-submissions/TemplateListItem.vue';

export default {
  name: "WelcomeModal",
  data() {
    return {
      loaded: false,
      pending: false,
      forms: []
    }
  },
  async mounted() {
    try {
      this.loaded = false;
      this.forms = await fetchFormTemplates();
      this.forms = this.forms.map(form => {
        form.selected = false;
        return form;
      })
      console.info('Fetched form templates from backend');
    } catch (err) {
      console.error(err);
      alert('Failed to fetch form templates from backend', err);
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    async copyFormsIntoAccount() {
      if (this.pending) return;

      try {
        this.pending = true;

        // Get forms we are copying
        const formIDs = this.forms.filter(form => form.selected).map(form => form.id);
        console.info('Copying the following forms into account', formIDs);

        // Copy the forms
        await copyFormTemplates(formIDs)
        console.info('Succesfully copied forms into account!');
        this.$emit('closeModal');
        this.$router.replace('/submissions');

      } catch (err) {
        console.error('Failed to copy forms into account', err);
        alert('Failed to copy form templates into account', err);
      } finally {
        this.pending = false;
      }
    },
    toggleFormSelected(form) {
      form.selected = !form.selected;
    },
  },
  components: { TemplateListItem },
  emits: ["closeModal"],
};
</script>

<style scoped>
.card {
  max-width: 700px;
}

.card-body {
  height: 350px;
  overflow-y: auto;
  padding: 10px;
  margin: -10px;
}

</style>