<template>
  <div class="component">
    <p v-if="component.type == 'PARAGRAPH'" :data-whitespace="component.whitespace">{{component.label}}</p>
    <h3 v-else-if="component.headerSize == 'h3'">{{component.label}}</h3>
    <h2 v-else-if="component.headerSize == 'h2'">{{component.label}}</h2>
    <h1 v-else>{{component.label}}</h1>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent">
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  methods: {
    deleteComponent() {
      this.$emit('deleteComponent')
    }
  },
  emits: ['deleteComponent']
};
</script>

<style scoped>
.component {
  position: relative;
  display: block;
  flex: 1;
}

h1 {
  margin-bottom: 0px;
  margin-top: 50px;
}

h2 {
  margin-bottom: 0px;
  margin-top: 25px;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #FFF;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

p[data-whitespace="true"] {
  white-space: pre-line
}
</style>