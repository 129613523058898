<template>
  <div class="form-check-list">
    <div class="check-list-item" v-for="item of items" :key="item.form.id">
      <div class="checkbox-container">
        <input
          class="checkbox"
          type="checkbox"
          name="checkbox"
          :id="`checkbox-${item.form.id}`"
          v-model="item.isSelected"
          @input="$emit('updated')"
        />
        <label class="checkmark" :for="`checkbox-${item.form.id}`"></label>
      </div>
      <label class="label">{{ item.form.name }}</label>
    </div>
  </div>
</template>

<script>
import { store } from "@/api";

export default {
  name: "FormCheckList",
  data() {
    return {
      store,
      items: []
    };
  },
  async created() {
    this.items = store.forms.map((form) => {
      return {
        form,
        isSelected: false,
      };
    });
  },
  methods: {
    getSelectedFormIds() {
      return this.items.filter(item => item.isSelected).map(item => item.form.id);
    },
    setSelectedFormIds(ids) {
      const set = new Set(ids);
      for (let item of this.items) {
        item.isSelected = set.has(item.form.id);
      }
    },
  },
  emits: ['updated']
};
</script>

<style scoped>
.form-check-list {
  max-height: 150px;
  overflow-y: auto;
}

.check-list-item {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 10px;
}

.label {
  display: block;
  margin-left: 10px;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #5b6879;
  cursor: pointer !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: var(--color-primary);
}
.checkbox-container .checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>