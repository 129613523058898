<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 550px">
      <div class="card-header">
        <h1>User List</h1>
        <p>Create user logins for your staff so that they can easily sign into your DashQuill account.</p>
      </div>
      <div class="card-body">
        <div class="user-list">
          <div class="user-list-item">
            <img src="@/assets/user-admin.png" alt="User Icon" class="user-icon">
            <span class="email">{{ account.email }}</span>
          </div>
          <div class="user-list-item" v-for="user of users" :key="user.id">
            <img src="@/assets/user-member.png" alt="User Icon" class="user-icon">
            <span class="email">{{ user.email }}</span>
            <img src="@/assets/trash.png" alt="Delete User" class="icon-sm" @mousedown.prevent="$emit('openModal', 'delete-user', user)">
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="button primary" @click="$emit('openModal', 'create-user')">Add New User</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UserListModal",
  props: {
    account: Object,
    users: Array
  },
  methods: {
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
  },
  emits: ["closeModal", "openModal"],
};
</script>

<style scoped>
.user-list-item {
  width: 100%;
  padding: 10px;
  height: 52px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #00000022;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-icon {
  display: block;
  height: 24px;
  margin-right: 10px;
}

.card-body .icon-sm {
  display: block;
  height: 32px;
  filter: saturate(0);
}

.email {
  flex: 1;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>