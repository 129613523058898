<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

:root {
  --color-primary: #4b2f9d;
  --color-secondary: #5f6578;
  --color-link: #3a71fb;
  --color-patient: #4b2f9d;
  /* TODO: Update colors to be transparent */
  --color-patient-field: #9e9e9e22;
  --color-patient-field-error: #b8000022;
  --color-patient-field-filled: #00b81322;
}

* {
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

body {
  background: #e1e6ea;
}

#full {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.card {
  padding: 50px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow-y: auto;
}

.card-header {
  margin-bottom: 50px;
}

.card-footer {
  margin-top: 75px;
}

.button {
  display: block;
  font-size: 20px;
  padding: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.button.primary {
  background: var(--color-primary);
  color: #FFF;
}

.button.danger {
  background: #aa0000;
  color: #FFF;
}

.button.small {
  width: auto;
  font-size: 16px;
  padding: 10px 15px;
}

.form-group {
  width: 100%;
  display: block;
}

.form-group:not(:first-child) {
  margin-top: 50px;
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 50px;
}

.form-row .form-group {
  margin-top: 0px;
}

.form-row .form-group:not(:last-child) {
  margin-right: 25px;
}

.form-label {
  display: block;
  width: 100%;
  color: var(--color-secondary);
  margin-bottom: 5px;
}

.form-control {
  display: block;
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--color-secondary);
}

.form-control::placeholder {
  opacity: 0.5;
}

h1 {
  margin-bottom: 10px;
  font-weight: bold;
}

p {
  line-height: 28px;
}

a {
  color: var(--color-link);
}

#scroll {
  padding: 25px;
  padding-top: 100px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#scroll::-webkit-scrollbar {
  display: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: all ease-in-out 0.3s;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(20px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.modal-enter-active,
.modal-leave-active {
  transition: all ease-in-out 0.2s;
}

.modal-enter-from,
.modal-leave-to  {
  opacity: 0;
}

.appear-enter-active,
.appear-leave-active {
  transition: all ease-in-out 0.2s;
}

.appear-enter-from,
.appear-leave-to  {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-in-out 0.2s;
}

.fade-enter-from,
.fade-leave-to  {
  opacity: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: #131b28c8;
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 101;
  padding: 10px;
}

@media screen and (max-width: 800px) {
  .form-row {
    flex-direction: column;
  }

  .form-row .form-group:not(:first-child) {
    margin-top: 50px;
  }

  .card {
    padding: 35px;
  }
} 


.error {
  display: block;
  width: 100%;
  text-align: center;
  color: #aa0000;
  margin-top: 25px;
}

.loading {
  display: block;
  height: 24px;
  margin: auto;
}

.button .button-icon {
  display: block;
  height: 20px;
}

.button-list {
  display: flex;
  flex-direction: row;
  margin: -10px;
}

.button-list .button {
  margin: 10px;
}

</style>
