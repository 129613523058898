<template>
  <div class="modal">
    <div class="card" style="max-width: 600px">
      <div class="card-header">
        <h1>Secure Form Filler</h1>
        <p>Welcome to our office! Please enter the <u>legal full name and date of birth of the patient</u> you will be
          filling out forms for.</p>
      </div>
      <form class="card-body" @submit.prevent="submitForm">
        <div class="form-row">
          <div class="form-group">
            <label for="first" class="label">Patient First Name</label>
            <input id="first" type="text" ref="first" name="first" class="input" v-model="first" autocomplete="off"
              data-lpignore="true" data-form-type="other" :filled="isTextFilled(first)" />
          </div>
          <div class="form-group">
            <label for="last" class="label">Patient Last Name</label>
            <input id="last" type="text" ref="last" name="last" class="input" v-model="last" autocomplete="off"
              data-lpignore="true" data-form-type="other" :filled="isTextFilled(last)" />
          </div>
        </div>
        <div class="form-group">
          <label for="last" class="label">Patient Date of Birth</label>
          <input id="dob" type="tel" ref="dob" name="dob" class="input" v-model="dob" placeholder="mm/dd/yyyy"
            autocomplete="off" data-lpignore="true" data-form-type="other" :filled="isDateFilled(dob)"
            @input="formatDateOfBirth()" />
        </div>
        <input type="submit" value="" style="display: none">
      </form>
      <div class="card-footer">
        <button class="button submit" @mousedown="submitForm">
          <span>Begin Forms</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;

export default {
  name: "WelcomeModal",
  data() {
    return {
      first: "",
      last: "",
      dob: "",
      error: "",
      pending: false,
    };
  },
  mounted() {
    document.documentElement.style.width = '100vw'
    document.documentElement.style.position = 'fixed';
    document.documentElement.style.top = '0px';

    window.addEventListener('resize', this.resizeModalPage);
    this.resizeModalPage();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeModalPage);

    document.documentElement.style.width = ''
    document.documentElement.style.position = '';
    document.documentElement.style.top = '';
  },
  methods: {
    resizeModalPage() {
      const el = this.$el;
      el.style.height = `${window.innerHeight}px`
    },
    submitForm() {
      if (!this.isFormFilled()) {
        return;
      }

      this.$emit('submitForm', this.first, this.last, this.dob);
    },
    isFormFilled() {
      if (!this.first) {
        return false;
      }

      if (!this.last) {
        return false;
      }

      if (!this.dob) {
        return false;
      }

      return true;
    },
    isTextFilled(value) {
      return value.length > 0;
    },
    isDateFilled() {
      return dateRegex.test(this.dob);
    },
    formatDateOfBirth() {
      const nums = this.dob.replace(/[^0-9]/g, '');
      if (nums.length <= 2) {
        this.dob = `${nums.substring(0, 2)}`
      } else if (nums.length <= 4) {
        this.dob = `${nums.substring(0, 2)}/${nums.substring(2, 4)}`
      } else {
        this.dob = `${nums.substring(0, 2)}/${nums.substring(2, 4)}/${nums.substring(4, 8)}`
      }
    }
  },
  emits: ["submitForm"],
};
</script>

<style scoped>
.modal {
  background: #131b28c8;
}

.card {
  border-radius: 0px;
}

.button.submit {
  padding: 15px 25px;
  background: var(--color-patient);
  color: #fff;
}

.button.submit:disabled {
  background: #535353;
}

.form-row {
  margin-top: 0px;
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 45px;
  opacity: 1;
}

.input::placeholder {
  opacity: 0.5;
}
</style>