<template>
  <div class="modal" @mousedown="closeModal">
    <transition name="slide" mode="out-in">
      <!-- Patient Contact Card -->
      <div class="card" v-if="activeCard == 'patient-contact'">
        <div class="card-header">
          <h1>Intake Patient</h1>
          <p>
            Enter the contact information of a patient you want to send one or
            more forms to.
          </p>
        </div>
        <form class="card-body" @submit.prevent="openSelectFormsCard">
          <div class="form-group">
            <label for="name" class="form-label">Patient Name</label>
            <input type="text" class="form-control" id="name" name="name" ref="name" v-model="name" placeholder="John Smith"
              autocomplete="off" data-lpignore="true" data-form-type="other" />
          </div>
          <div class="form-group">
            <label for="contact" class="form-label">Patient email address or phone number</label>
            <input id="contact" type="text" name="contact" class="form-control" v-model="contact" autocomplete="off"
              data-lpignore="true" data-form-type="other" />
          </div>
          <input type="submit" style="display: none" />
        </form>
        <div class="card-footer">
          <button class="button primary" @click="openSelectFormsCard">
            Continue
          </button>
          <p class="error" v-if="error">{{ error }}</p>
        </div>
      </div>
      <!-- Form Select Card -->
      <div class="card" v-else-if="activeCard == 'select-forms'">
        <div class="card-header">
          <h1>Select Forms</h1>
          <p>
            Which forms should be sent to <u>{{ contact }}</u> for completion?
          </p>
        </div>
        <form class="card-body">
          <div class="form-group" v-if="store.presets.length > 0">
            <label for="preset" class="form-label">Presets</label>
            <select name="preset" id="preset" class="form-control" @input="onSelectPreset">
              <option value=""></option>
              <option v-for="preset of store.presets" :key="preset.id" :value="preset.id">{{ preset.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label">Forms to send</label>
            <FormCheckList ref="checklist" />
          </div>
        </form>
        <div class="card-footer">
          <button class="button primary" @click="createSubmission">
            <img v-if="pending" class="loading" src="/loading.svg" alt="Loading" />
            <span v-else>Send Forms</span>
          </button>
          <p class="error" v-if="error">{{ error }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FormCheckList from "../../components/FormCheckList.vue";
import { submissionInvite, fetchAccountForms, fetchAccountPresets, parallel, store } from "@/api";

const phoneRegex = /^[0-9]{10}$/
const emailRegex = /^.+@.+\..+$/

export default {
  name: "IntakePatientModal",
  data() {
    return {
      store,
      activeCard: "patient-contact",
      name: "",
      contact: "",
      pending: null,
      error: ""
    };
  },
  async created() {
    try {
      await parallel(fetchAccountForms(), fetchAccountPresets())
      console.info('Fetched forms and presets');
    } catch (err) {
      console.error(err);
      alert('ERROR: ' + err.message);
    }
  },
  mounted() {
    setTimeout(() => {
      const nameRef = this.$refs.name;
      if (nameRef) {
        nameRef.focus();
      }
    }, 100)
  },
  methods: {
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
    openSelectFormsCard() {
      this.error = "";

      if (!this.name || this.name === "") {
        this.error = "Please provide an patient name."
        return;
      }

      // If it has a non-digit character, treat it as an email
      const contact = this.contact;
      if (/\D/.test(contact)) {
        if (!emailRegex.test(contact)) {
          this.error = 'Email should have format like name@email.com.'
          return;
        }
      } else{
        if (!phoneRegex.test(contact)) {
          this.error = 'Phone number should have format like 7075066725.'
          return;
        }
      }

      this.activeCard = "select-forms";
    },
    onSelectPreset(event) {
      const target = event.target;
      const value = target.value;

      const preset = this.store.presets.find(p => p.id == value);
      if (preset) {
        this.$refs.checklist.setSelectedFormIds(preset.forms);
      } else {
        this.$refs.checklist.setSelectedFormIds([]);
      }
    },
    async createSubmission() {
      this.error = "";

      let shareEmail, sharePhoneNumber;
      if (/^[0-9]{10}$/g.test(this.contact)) {
        sharePhoneNumber = this.contact;
      } else {
        shareEmail = this.contact;
      }

      try {
        this.pending = true;
        const checklist = this.$refs.checklist;
        const forms = checklist.getSelectedFormIds();
        await submissionInvite(this.name, forms, shareEmail, sharePhoneNumber);
        this.$emit('closeModal');
      } catch (err) {
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
  },
  components: { FormCheckList },
  emits: ["closeModal"],
};
</script>