<template>
  <div class="component">
    <label class="label">{{ component.label
    }}<span class="optional-tag" v-if="component.optional">(optional)</span></label>
    <div class="fake-file input" filled="true" v-if="shouldShowFakeFile()" @click="this.$refs.file.click()">
      <input type="button" value="Choose File">
      <span>Uploaded</span>
    </div>
    <input :style="shouldShowFakeFile() ? { display: 'none' } : {}" ref="file" class="input" type="file"
      accept="image/jpeg,image/png" @input="resetValue" :name="component.label" :disabled="!interactable"
      :filled="dummy && isComponentFilled()" :error="error" @change="onFileChanged" />
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent" />
  </div>
</template>

<script>
export default {
  name: "FileComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  data() {
    return {
      error: false,
      value: "", // can be string or blob
      dummy: 0,
    };
  },
  methods: {
    updateValue(value) {
      this.value = value;
    },
    onFileChanged() {
      const el = this.$refs.file;
      if (el && el.files && el.files.length > 0) {
        const file = el.files[0];
        this.downscaleImageFile(file).then((newFile) => {
          console.info(`File was downscaled. Old size = ${file.size}. New size = ${newFile.size}`);
          this.value = newFile;
          this.$emit('onUpdateValue', this.value);
        })
      } else {
        this.downscaledFile = null;
      }
    },
    resetValue() {
      this.value = "";
      this.dummy++; // To trigger a refresh of component each time its changed
    },
    deleteComponent() {
      this.$emit("deleteComponent");
    },
    isComponentFilled() {
      if (this.value == "") return false;
      
      // File or file ID works
      return true;
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    },
    shouldShowFakeFile() {
      return this.isComponentFilled() && typeof(this.value) == "string";
    },
    async downscaleImageFile(file, maxWidth=1000, maxHeight=1000) {
      return new Promise((resolve, reject) => {
        // Create an image to read the dimensions of the input file
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          // Create a canvas and get the context
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calculate the new dimensions
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          // Set the canvas dimensions
          canvas.width = width;
          canvas.height = height;

          // Draw the image scaled
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to file
          canvas.toBlob(blob => {
            const downscaledFile = new File([blob], `${this.component.label}.jpg`, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            resolve(downscaledFile);
          }, 'image/jpeg');

          // Clean up the URL object created
          URL.revokeObjectURL(img.src);
        }

        img.onerror = () => {
          reject();
        }
      })
    }
  },
  emits: ["onUpdateValue", "deleteComponent"],
};
</script>

<style scoped>
.component {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 45px;
  opacity: 1;
  border-radius: 0px;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #fff;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.optional-tag {
  margin-left: 5px;
  opacity: 0.5;
}

.fake-file input {
  font-size: 20px;
  font-family: 'Rubik';
  margin-right: 4px;
}
</style>