<template>
  <div id="full">
    <transition name="slide" mode="out-in">
      <!-- Request 2FA Card -->
      <div class="card" v-if="activeCard == 'about-practice'">
        <div class="card-header">
          <h1>Create an account</h1>
          <p>
            Have an account already?
            <router-link to="/login">Click here to login</router-link>.
          </p>
        </div>
        <form class="card-body" @submit.prevent="openLoginCard">
          <div class="form-group" style="position: relative">
            <label for="name" class="form-label">Practice Name</label>
            <input id="name" type="text" name="name" class="form-control" v-model="name" @input="checkNameStatus"
              autocomplete="off" data-lpignore="true" data-form-type="other" />
            <img src="@/assets/check.png" title="Available" class="check-name" v-if="nameStatus == 'available'" />
            <img src="@/assets/error.png" title="Not available" class="check-name"
              v-if="nameStatus == 'unavailable' || nameStatus == 'error'" />
          </div>
          <div class="form-group">
            <label for="practiceType" class="form-label">Practice Type</label>
            <select id="practiceType" name="practiceType" class="form-control" v-model="practiceType">
              <option value="FamilyMedicine">Family Medicine</option>
              <option value="InternalMedicine">Internal Medicine</option>
              <option value="Pediatrics">Pediatrics</option>
              <option value="GeneralSurgery">General Surgery</option>
              <option value="Cardiology">Cardiology</option>
              <option value="ObstetricsAndGynecology">
                Obstetrics and Gynecology
              </option>
              <option value="Psychiatry">Psychiatry</option>
              <option value="Neurology">Neurology</option>
              <option value="Radiology">Radiology</option>
              <option value="EmergencyMedicine">Emergency Medicine</option>
              <option value="Dermatology">Dermatology</option>
              <option value="GeneralDentistry">Dentistry</option>
              <option value="Orthodontics">Orthodontics</option>
              <option value="PhysicalTherapy">Physical Therapy</option>
              <option value="Psychology">Psychology</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="form-group">
            <label for="practiceEMR" class="form-label">Practice EMR</label>
            <select id="practiceEMR" name="practiceEMR" class="form-control" v-model="practiceEMR">
              <option value="PracticeFusion">Practice Fusion</option>
              <option value="Epic">Epic Systems</option>
              <option value="Cerner">Cerner</option>
              <option value="Athenahealth">Athenahealth</option>
              <option value="EClinicalWorks">eClinicalWorks</option>
              <option value="NextGen">NextGen Healthcare</option>
              <option value="Kareo">Kareo</option>
              <option value="AdvancedMD">AdvancedMD</option>
              <option value="Other">Other</option>
              <option value="None">None</option>
            </select>
          </div>
          <input type="submit" style="display: none" />
        </form>
        <div class="card-footer">
          <button class="button primary" @click.prevent="openCreateAccountCard">
            Continue
          </button>
          <p v-if="error" class="error">{{ error }}</p>
        </div>
      </div>
      <!-- Security Card -->
      <div class="card" v-else-if="activeCard == 'create-account'">
        <div class="card-header">
          <h1>Secure your account</h1>
        </div>
        <form class="card-body" @submit.prevent="createAccount">
          <div class="form-group">
            <label for="email" class="form-label">Email Address</label>
            <input id="email" type="text" name="email" class="form-control" placeholder="team@yourpractice.com"
              v-model="email" />
          </div>
          <div class="form-group">
            <label for="phoneNumber" class="form-label">Phone Number</label>
            <input id="phoneNumber" type="text" name="phoneNumber" class="form-control" placeholder="(123) 123-1234"
              v-model="phoneNumber" @input="updatePhoneNumber" />
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="password" class="form-label">Password</label>
              <input id="password" type="password" name="password" class="form-control" v-model="password" />
            </div>
            <div class="form-group">
              <label for="repassword" class="form-label">Repeat password</label>
              <input id="repassword" type="password" name="repassword" class="form-control" v-model="repassword" />
            </div>
          </div>
          <input type="submit" style="display: none" />
        </form>
        <div class="card-footer">
          <button class="button primary" @click="createAccount">
            <img v-if="pending" src="/loading.svg" alt="Loading" class="loading">
            <span v-else>Create Account</span>
          </button>
          <p v-if="error" class="error">{{ error }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { isNameAvailable, register } from "@/api";

export default {
  name: "RegisterView",
  data() {
    return {
      activeCard: "about-practice",
      name: "",
      practiceType: "",
      pracitceEMR: "",
      email: "",
      phoneNumber: "",
      password: "",
      repassword: "",
      nameStatus: "",
      nameChecker: null,
      error: "",
    };
  },
  methods: {
    async createAccount() {
      this.error = "";

      if (!this.email) {
        this.error = "Please enter an email address.";
        return;
      }

      if (!this.phoneNumber) {
        this.error = "Please enter a phone number.";
        return;
      }

      if (!this.password) {
        this.error = "Please enter a password";
        return;
      }

      if (this.password != this.repassword) {
        this.error = "Passwords do not match. Please try again.";
        return;
      }

      try {
        this.pending = true;
        const phoneNums = this.phoneNumber.replace(/[^0-9]/g, '');
        await register(this.name, this.practiceType, this.practiceEMR, this.email, phoneNums, this.password);
        this.$router.push('/submissions?welcome=true');
      } catch (err) {
        console.error('Failed to register account', err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    openCreateAccountCard() {
      if (this.nameStatus != "available") {
        this.error = "Practice name is taken! Please try another name.";
        return;
      }

      if (!this.practiceType) {
        this.error = "Please select a practice type.";
        return;
      }

      if (!this.practiceEMR) {
        this.error = "Please select an EMR system.";
        return;
      }

      this.activeCard = "create-account";
      this.error = "";
    },
    checkNameStatus() {
      this.nameStatus = "none";

      if (this.nameChecker) {
        clearTimeout(this.nameChecker);
      }

      const name = this.name;

      // Check if name is available
      this.nameChecker = setTimeout(async () => {
        try {
          const isAvailable = await isNameAvailable(name);
          this.nameStatus = isAvailable ? "available" : "unavailable";
        } catch (err) {
          this.nameStatus = "error";
        }
      }, 500);
    },
    updatePhoneNumber() {
      let phoneNumber = this.phoneNumber;
      const nums = phoneNumber.replace(/[^0-9]/g, '');
      if (nums.length <= 0) {
        phoneNumber = '';
      } else if (nums.length <= 3) {
        phoneNumber = `(${nums.substring(0, 3)}`
      } else if (nums.length <= 6) {
        phoneNumber = `(${nums.substring(0, 3)}) ${nums.substring(3, 6)}`
      } else {
        phoneNumber = `(${nums.substring(0, 3)}) ${nums.substring(3, 6)}-${nums.substring(6, 10)}`
      }
      this.phoneNumber = phoneNumber;
    }
  },
};
</script>

<style scoped>
#full {
  background-image: url("@/assets/wave.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-name {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 30px;
  z-index: 100;
}
</style>