<template>
  <nav>
    <router-link to="/" id="logo">
      <img src="@/assets/logo.png" alt="DashQuill Logo" />
    </router-link>
    <div class="left">
      <router-link class="nav-link" to="/submissions">
        <span>Submissions</span>
      </router-link>
      <router-link class="nav-link" to="/forms">
        <span>Account Forms</span>
      </router-link>
      <router-link class="nav-link" to="/presets">
        <span>Account Presets</span>
      </router-link>
    </div>
    <div class="right">
      <a class="nav-link" href="#" @click.prevent="attemptLogout">
        <span>Logout</span>
      </a>
      <router-link class="nav-link" to="/settings">
        <img src="@/assets/profile.png" />
      </router-link>
    </div>
  </nav>
</template>

<script>
import {logout} from '@/api'

export default {
  name: "AccountNavbar",
  methods: {
    async attemptLogout() {
      try {
        await logout();
        this.$router.push('/login');
      } catch (err) {
        alert('Failed to logout from account');
      }
    }
  }
};
</script>

<style scoped>
nav {
  position: fixed;
  background: #fff;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  height: 70px;
  box-shadow: 0px 0px 8px #00000022;
  z-index: 100;
}

.left {
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
}

.right {
  display: flex;
  align-items: center;
  height: 100%;
}

#logo {
  display: block;
  width: 110px;
}

#logo img {
  width: 100%;
}

.nav-link {
  margin-left: 25px;
  color: #000;
  text-decoration: none;
}

.nav-link.is-active {
  text-shadow: 0px 0px 1px #000;
}

.nav-link img {
  display: block;
  height: 24px;
}
</style>