<template>
  <div class="template-list-item" :selected="form.selected">
    <div class="icon-col">
      <img src="@/assets/form.png" alt="Form" class="form-icon">
    </div>
    <div class="description-col">
      <h3>{{form.name}}</h3>
      <p>{{form.description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateListItem",
  props: {
    form: Object
  }
};
</script>

<style scoped>

.form-icon {
  height: 40px;
}

.icon-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.template-list-item {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #FFF;
  box-shadow: 0px 0px 4px #00000022;
  cursor: pointer;
  border-radius: 10px;
}

.template-list-item[selected="true"] {
  box-shadow: 0px 0px 8px #1d7607aa;
  background: #e8ffe3;
}

.template-list-item:not(:last-child) {
  margin-bottom: 20px;
}

.description-col {
  flex: 1;
  margin-left: 20px;
}

h3 {
  margin-bottom: 10px;
}
</style>