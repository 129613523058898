<template>
  <div class="ehr-field-search-tab">
    <div class="form-group">
      <div class="form-label">{{emrJson.name}} Field</div>
      <!-- If not editing, show the tag -->
      <div v-if="!editing" class="selected-ehr-field" @click="beginEditing()">
        <span>{{ getSelectedKeyLabel() }} ({{ getSelectedKeyCategory() }})</span>
      </div>
      <!-- If editing, show the search bar + dropdown -->
      <input v-if="editing" ref="input" type="text" class="form-control" placeholder="Search..." @focus="focusSearch" @blur="blurSearch" v-model="query">
      <div class="dropdown-list" v-if="editing">
        <div class="dropdown-item-section" v-for="section of getSectionsToShow()" :key="section">
          <div class="category-title">
            <h3>{{ section.category }}</h3>
          </div>
          <div class="dropdown-item-field" v-for="field of section.fields" :key="field" @mousedown="selectField(field)">
            <span>{{ field.label }}</span>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmrFieldSearchTab',
  props: {
    emrJson: Object,
    selectedKey: String,
    scope: String
  },
  data() {
    return {
      editing: false,
      query: '',
    }
  },
  watch: {
    selectedKey: {
      handler() {
        this.editing = false;
      },
      immediate: true
    }
  },
  methods: {
    isDoNotImport() {
      return this.selectedKey && this.selectedKey.startsWith('doNotImport');
    },
    focusSearch() {
      this.editing = true;
    },
    blurSearch() {
      this.editing = false;
    },
    getSectionsToShow() {
      if (!this.emrJson) return [];

      // Build the list of sections
      const sections = [];
      for (let field of this.emrJson.fields) {
        if (!field.scope || !field.scope.includes(this.scope)) continue;
        if (!field.key) continue;

        // Check if it matches the query
        const label = field.label;
        const category = field.category;
        if (!this.queryMatchesText(category) && !this.queryMatchesText(label)) {
          continue;
        }

        // Append the section if it doesnt exist in the result yet
        let section = sections.find(section => section.category == category);
        if (section == null) {
          section = { category: category, fields: [] }
          sections.push(section);
        }

        // Push the field
        section.fields.push(field);
      }

      return sections;
    },
    selectField(field) {
      this.editing = false;
      this.$emit('updateEhrField', field);
    },
    queryMatchesText(text) {
      if (this.query.length < 1) {
        return true;
      }

      return text.toLowerCase().indexOf(this.query.toLowerCase()) >= 0;
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
    getSelectedField() {
      return this.emrJson.fields.find(field => {
        if (!field.unique) {
          return field.key == this.selectedKey;
        } else {
          return this.selectedKey.startsWith(field.key);
        }
      })
    },
    getSelectedKeyCategory() {
      const selectedField = this.getSelectedField();
      if (!selectedField) {
        return 'Unknown'
      }
      return selectedField.category;
    },
    getSelectedKeyLabel() {
      const selectedField = this.getSelectedField();
      if (!selectedField) {
        return 'Unknown'
      }
      return selectedField.label;
    },
    beginEditing() {
      this.editing = true;

      setTimeout(() => {
        const input = this.$refs.input;
        input.focus();
      })
    }
  },
  emits: ['updateEhrField']
}
</script>

<style scoped>
.form-group {
  position: relative;
}

.dropdown-list {
  left: 0px;
  top: 100%;
  position: absolute;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000044;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  z-index: 1000;
}

.dropdown-item-section:not(:first-child) {
  margin-top: 20px;
}

.category-title {
  padding: 10px;
  background: #F5F5F5;
}

.category-title h3 {
  font-weight: normal;
  opacity: 0.5;
}

.dropdown-item-field {
  padding: 10px;
}

.dropdown-item-field:hover {
  background: #eef3ff;
  cursor: pointer;
}

.selected-ehr-field {
  padding: 10px;
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
  cursor: pointer;
}

.selected-ehr-field span {
  font-size: 20px;
}

.form-control {
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
}
</style>