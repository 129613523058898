<template>
  <div class="account-list-item">
    <img src="@/assets/doctor.png" v-if="account.permissionLevel == 'Customer'" alt="Customer Account" class="icon">
    <img src="@/assets/employee.png" v-if="account.permissionLevel == 'Employee'" alt="Employee Account" class="icon">
    <img src="@/assets/admin.png" v-if="account.permissionLevel == 'Admin'" alt="Admin Account" class="icon">
    <h3 class="list-item-name"><span class="name" :active="store.account && store.account.id == account.id">{{
      account.name }}</span> <span class="marker" v-if="account.lastAction">{{ account.lastAction }} -
        {{ formatDate(account.lastActionDate) }}</span></h3>
    <span class="list-item-date">Joined {{ formatDate(account.dateJoined) }}</span>
  </div>
</template>

<script>
import { store } from '@/api';

export default {
  name: 'AccountListItem',
  props: {
    account: Object
  },
  data() {
    return {
      store: store
    };
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return 'just now'
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`
      }
      if (min <= 60) {
        return `${min} minutes ago`
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return '1 hour ago'
      }
      if (hr <= 24) {
        return `${hr} hours ago`
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return '1 day ago'
      }
      if (days <= 7) {
        return `${days} days ago`
      }

      return date.toLocaleDateString();
    }
  },
}
</script>

<style scoped>
.account-list-item {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 4px #00000022;
  cursor: pointer;
  border-radius: 10px;
}

.account-list-item:not(:last-child) {
  margin-bottom: 20px;
}

.icon {
  display: block;
  height: 32px;
}

.icon-sm {
  display: block;
  height: 32px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

h3 {
  margin-left: 20px;
  font-weight: normal;
}

.list-item-date {
  margin-right: 20px;
  text-align: right;
  flex: 1;
  opacity: 0.5;
  font-size: 18.72px;
}

.list-item-name .name[active="true"] {
  font-weight: bold;
}

.marker {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
  text-transform: uppercase;
}
</style>