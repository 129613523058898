<template>
  <div class="conditional-list-tab form-group">
    <div class="form-group-inner">
      <div class="form-label">Only show if...</div>
      <select class="form-control" v-model="conditionCloned.key" @change="onDropdownComponentSelected">
        <option v-for="cmp of getDropdownComponents()" :key="cmp.key" :value="cmp.key">{{ cmp.label }}</option>
      </select>
    </div>
    <div class="form-group-inner" v-if="selectedDropdownComponent">
      <div class="form-label">is equal to...</div>
      <select class="form-control" v-model="conditionCloned.value" @change="updateCondition">
        <option v-for="opt of selectedDropdownComponent.options" :key="opt" :value="opt" class="form-control">{{ opt }}</option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OptionsListTab',
  props: {
    component: Object,
    allComponents: Array
  },
  data() {
    return {
      editing: false,
      conditionCloned: {key: '', value: ''},
      selectedDropdownComponent: null
    }
  },
  watch: {
    component: {
      handler() {
        this.conditionCloned = structuredClone(this.component.condition)
        this.onDropdownComponentSelected();
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.onMouseDown);
  },
  unmounted() {
    window.removeEventListener('mousedown', this.onMouseDown);
  },
  methods: {
    beginEditing() {
      if (this.editing) return false;

      setTimeout(() => {
        this.editing = true;
      })
    },
    onMouseDown(event) {
      const fieldsList = this.$refs.fieldsList;
      if (this.editing && fieldsList != null) {
        if (!fieldsList.contains(event.target)) {
          this.editing = false;
        }
      }
    },
    onDropdownComponentSelected() {
      const component = this.allComponents.find(cmp => cmp.key == this.conditionCloned.key);
      this.selectedDropdownComponent = component;
      this.updateCondition();
    },
    getDropdownComponents() {
      if (!this.allComponents) return [];
      return this.allComponents
        .filter(component => component.type == 'DROPDOWN')
        .filter(component => component.key != this.component.key)
    },
    updateCondition() {
      this.$emit('updateCondition', this.conditionCloned)
    }
  },
  emits: ['updateCondition']
}
</script>

<style scoped>
.form-group {
  position: relative;
}

.dropdown-list {
  left: 0px;
  top: 100%;
  position: absolute;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000044;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  z-index: 1000;
}

.dropdown-item-section:not(:first-child) {
  margin-top: 20px;
}

.dropdown-item-component {
  padding: 10px;
}

.dropdown-item-component:hover {
  background: #eef3ff;
  cursor: pointer;
}

.form-control {
  cursor: pointer;
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
  border-radius: 5px;
}

.dropdown-item-field:hover {
  background: #eef3ff;
  cursor: pointer;
}

.form-group-inner:not(:first-child) {
  margin-top: 20px;
}
</style>