import { reactive } from 'vue';

export const store = reactive({
  forms: [],
  presets: [],
  submissions: [],
  submissionPage: 1, // One index based 
  submissionPageSize: 10,
  submissionQuery: "",
  totalPages: 1,
  account: {},
  users: []
})

export async function checkIfLoggedIn() {
  try {
    const res = await fetch(process.env.VUE_APP_API + '/account/check-if-logged-in', {
      method: 'POST',
      credentials: 'include'
    });
    return res.status == 200;
  } catch (err) {
    return false;
  }
}

export async function isNameAvailable(name) {
  const resJson = await postJson("/account/is-name-available", { name });
  const isNameAvailable = resJson.isNameAvailable;
  if (isNameAvailable == null) throw Error("Malformed response from backend");
  return isNameAvailable;
}

export async function requestTwoFactor(email, password) {
  await postJson("/account/request-2fa", {
    email, password
  });
}

export async function login(email, password, twoFactorCode) {
  await postJson("/account/login", {
    email, password, twoFactorCode
  });
}

export async function logout() {
  await postJson("/account/logout");
}

export async function register(name, practiceType, practiceEMR, email, phoneNumber, password) {
  await postJson("/account/signup", {
    name, practiceType, practiceEMR, email, phoneNumber, password
  });
}

export async function fetchUserList() {
  const resJson = await postJson("/account/list-users");
  if (!resJson.users) throw Error('Malformed response from backend');
  store.users = resJson.users;
}

export async function createUser(email, password) {
  const resJson = await postJson("/account/create-user", {email, password});
  if (!resJson.user) throw Error('Malformed response from backend');
  store.users.push(resJson.user);
}

export async function deleteUser(id) {
  await postJson("/account/delete-user", {id});
  store.users = store.users.filter(user => user.id != id)
}

export async function downloadSubmission(submissionID) {
  const res = await fetch(process.env.VUE_APP_API + '/submission/download', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: submissionID })
  });

  if (res.status !== 200) {
    throw Error(res.statusText);
  }

  const blob = await res.blob();
  return blob
}

export async function getSubmissionValues(submissionID, formID, versionID) {
  const json = await postJson('/submission/copy-values', {id: formID, version: versionID, submissionID: submissionID});
  return json;
}

export async function fetchAccountForms() {
  const resJson = await postJson('/form/list');
  if (!resJson.forms) throw Error('Malformed response from backend');
  store.forms = resJson.forms;
}

export async function fetchAccountInfo() {
  const resJson = await postJson('/account/info');
  if (!resJson || !resJson.account) throw Error('Malformed response from backend')
  store.account = resJson.account;
  console.log('store.account', store.account)
  // store.newSubmissionEmailNotify = resJson.account.newSubmissionEmailNotify;
  // store.deleteSubmissionEmailNotify = resJson.account.deleteSubmissionEmailNotify;
}

export async function setAccountPreferences(body) {
  await postJson('/account/set-preferences', body);
}

export async function fetchAccountPresets() {
  const resJson = await postJson('/preset/list');
  if (!resJson.presets) throw Error('Malformed response from backend');
  store.presets = resJson.presets;
}

export async function uploadAccountFile(file) {
  const formData = new FormData();
  formData.set('file', file);
  const resJson = await postForm("/file/upload-account-file", formData);
  if (!resJson.file) throw Error("Malformed response from server");
  return resJson.file;
}

export async function createForm(name, fileId) {
  const resJson = await postJson("/form/create", { name, file: fileId });
  const form = resJson.form;
  if (!form) throw Error('Malformed response from backend');
  store.forms.push(form);
  return form;
}

export async function duplicateForm(name, formId) {
  const resJson = await postJson("/form/copy-form", { name, form: formId });
  const form = resJson.form;
  if (!form) throw Error('Malformed response from backend');
  store.forms.push(form);
  return form;
}

export async function createPreset(name, formIds) {
  const resJson = await postJson("/preset/create", { name, forms: formIds });
  if (!resJson.preset) throw Error('Malformed response from backend');
  store.presets.push(resJson.preset);
}

export async function latestVersion(id) {
  const resJson = await postJson("/form/latest-version", { id });
  if (!resJson.form) throw Error('Malformed response from backend');
  return resJson.form;
}

export async function versionInfo(id) {
  const resJson = await postJson("/form/version-info", { id });
  if (!resJson.form) throw Error('Malformed response from backend');
  return resJson.form;
}

export async function reorderForms(formIds) {
  await postJson("/form/reorder", { forms: formIds });
}

export async function reorderPresets(presetIds) {
  await postJson("/preset/reorder", { presets: presetIds });
}

export async function deleteForm(formId) {
  await postJson("/form/delete", { id: formId });
  store.forms = store.forms.filter(form => form.id != formId);
}

export async function deletePreset(presetId) {
  await postJson("/preset/delete", { id: presetId });
  store.presets = store.presets.filter(preset => preset.id != presetId);
}

export async function deleteSubmission(submissionId) {
  await postJson("/submission/delete", { id: submissionId });
  // Commenting out because delete now triggers full query
  // store.submissions = store.submissions.filter(sub => sub.id != submissionId);
}

export async function updatePreset(presetId, forms) {
  await postJson("/preset/update", { id: presetId, forms: forms });
  const preset = store.presets.find(preset => preset.id == presetId);
  if (!preset) throw Error('Updated a preset that did not exist in store');
  preset.forms = forms;
}

export async function formsForUrl(nameUnique, url) {
  const resJson = await postJson('/submission/forms-for-url', { nameUnique, url });
  if (!resJson.versions) throw Error('Malformed response from server');
  return resJson.versions;
}

export async function patientInfo(privateUrl) {
  const resJson = await postJson('/submission/patient-info', { privateUrl });
  if (!resJson.submission) throw Error('Malformed response from backend');
  return resJson.submission;
}

export async function createSubmission(name, office, versions) {
  const resJson = await postJson('/submission/create', { name: name, ownerNameUnique: office, versions: versions });
  if (!resJson.submission) throw Error('Malformed response from backend');
  return resJson.submission;
}

export async function updateSubmission(privateUrl, valuesMap, currentPageIndex) {
  const values = Object.fromEntries(valuesMap)
  await postJson('/submission/update', { privateUrl, values, currentPage: currentPageIndex });
}

export async function updateSubmissionMetadata(privateUrl, metadata) {
  await postJson('/submission/metadata', { privateUrl, metadata });
}

export async function updateSubmissionRating(privateUrl, rating) {
  await postJson('/submission/rating', { privateUrl, rating });
}

export async function uploadFile(file, privateUrl) {
  const body = new FormData();
  body.append('file', file);
  body.append('privateUrl', privateUrl);
  const resJson = await postForm('/file/upload-patient-file', body);
  if (!resJson.file) throw Error("Malformed response from server");
  return resJson.file;
}


export async function submit(privateUrl) {
  const resJson = await postJson('/submission/submit', { privateUrl });
  if (!resJson.submission) throw Error('Malformed response from backend');
  return resJson.submission;
}

export async function getCheckInUrl(uniqueName, checkInLastName, checkInDateOfBirth) {
  const resJson = await postJson('/checkin/get-url', { uniqueName, checkInLastName, checkInDateOfBirth });
  if (!resJson.privateUrl) throw Error('Malformed response from backend');
  return resJson.privateUrl;
}

export async function loadSubmissionsForCurrentPage() {
  const resJson = await postJson('/submission/list', { query: store.query, pageNum: store.submissionPage, pageSize: store.submissionPageSize });
  if (resJson.submissions === null || resJson.currentPage === null || resJson.totalPages === null || resJson.totalSubmissions === null) {
    throw Error('Malformed Response')
  }
  store.submissionQuery = store.query;
  store.submissions = resJson.submissions;
  store.submissionPage = resJson.currentPage;
  store.totalPages = resJson.totalPages;
}

export async function downloadAccountFile(fileID) {
  const res = await fetch(process.env.VUE_APP_API + '/file/download-account-file', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: fileID })
  });

  if (res.status !== 200) {
    throw Error(res.statusText);
  }

  const blob = await res.blob();
  return blob
}

export async function updateFormLayout(formID, layoutRows) {
  await postJson('/form/update-layout', { id: formID, layoutRows });
}

export async function updateFormAnnotations(formId, filePages) {
  await postJson('/form/update-annotations', { id: formId, filePages });
}

export async function submissionInvite(name, forms, shareEmail, sharePhoneNumber) {
  const resJson = await postJson('/submission/invite', { name, forms, shareEmail, sharePhoneNumber });
  if (!resJson.submission) throw Error('Malformed response from server');
  store.submissions.unshift(resJson.submission);

  if (store.submissions.length > store.submissionPageSize) {
    store.submissions.pop();
  }
}

export async function getPublicJson(path) {
  const res = await fetch(process.env.VUE_APP_API + '/static' + path);
  const text = await res.text();
  return JSON.parse(text);
}

export async function parallel(...calls) {
  return await Promise.all(calls);
}

export async function getJson(url) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include'
  });
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

export async function postJson(url, body) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });

  // Handle 401
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

export async function postForm(url, data) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    body: data
  });
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

export async function updateFormName(id, name) {
  await postJson('/form/update-name', { id, name });
  store.forms = store.forms.map(form => {
    if (form.id == id) {
      form.name = name;
    }
    return form;
  })
}

export async function reviewForm(id, name) {
  await postJson('/form/review-form', { id, name });
}

export async function updateFormThumbnail(id, file) {
  await postJson('/form/update-thumbnail', { id, file });
  store.forms = store.forms.map(form => {
    if (form.id == id) {
      form.thumbnail = file;
    }
    return form;
  })
}

export async function updatePresetName(id, name) {
  await postJson('/preset/update-name', { id, name });
  store.presets = store.presets.map(preset => {
    if (preset.id == id) {
      preset.name = name;
    }
    return preset;
  })
}

export async function fetchFormTemplates() {
  const resJson = await postJson('/form/fetch-templates');
  if (!resJson.forms) throw Error('Malformed response from backend');
  return resJson.forms;
}

export async function copyFormTemplates(formIDs) {
  await postJson('/form/copy-templates', { forms: formIDs });
}