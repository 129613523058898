<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 600px">
      <div class="card-header">
        <h1>Add Provider Text</h1>
        <p>
          Please type text as a provider/staff to appear on a form. This is
          useful for pre-filling information as a provider.
        </p>
      </div>
      <form class="card-body" @submit.prevent="submitText">
        <div class="form-body">
          <label for="text" class="form-label">Text</label>
          <input class="form-control" v-model="text" placeholder="Example Text" ref="text" id="text" />
          <input type="submit" hidden>
        </div>
      </form>
      <div class="card-footer">
        <div class="button-list">
          <button class="button" @click.prevent="reset">Reset</button>
          <button class="button submit" @click.prevent="submitText">
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "TextModal",
  data() {
    return {
      text: "",
    };
  },
  mounted() {
    setTimeout(() => {
      const textRef = this.$refs.text;
      if (textRef) {
        textRef.focus();
      }
    }, 500)
  },
  methods: {
    closeModal(event) {
      if (event.target === this.$el) {
        this.$emit("closeModal");
      }
    },
    submitText() {
      this.$emit("submitText", this.text);
    },
    reset() {
      this.text = "";
    },
  },
  emits: ["submitText", "closeModal"],
};
</script>
  
<style scoped>
.modal {
  background: #131b28c8;
}

.provider-text {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.card {
  border-radius: 0px;
}

.button.submit {
  padding: 15px 25px;
  background: var(--color-patient);
  color: #fff;
}

.button.submit:disabled {
  background: #535353;
}
</style>