<template>
  <div class="annotation" ref="annot">
    <input
      class="input"
      ref="input"
      :disabled="!interactable"
      :filled="isAnnotationFilled()"
      :type="getTypeForAnnotation()"
      :placeholder="getPlaceholderForAnnotation()"
      :error="error"
      v-model="value"
      @input="onAnnotationInput()"
      @blur="onAnnotationBlur()"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
    />
    <img
      src="@/assets/trash.png"
      alt="trash"
      class="icon-sm"
      v-if="showTrash"
      @mousedown.prevent.stop="$emit('deleteAnnotation')"
    />
  </div>
</template>

<script>
const partialDateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{2}$/;
const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z0-9]+$/;
const ssnRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
const phoneRegex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

export default {
  name: "InputAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  data() {
    return {
      value: "",
      error: false,
    };
  },
  watch: {
    annotation: {
      handler: function () {
        this.refreshAnnotation();
      },
      deep: true,
    },
  },
  mounted() {
    this.refreshAnnotation();
  },
  methods: {
    updateValue(value) {
      this.value = value;

      setTimeout(() => {
        this.refreshAnnotation();
      }, 0)
    },
    onAnnotationInput() {
      // Format the value and pass to parent, who will help pass to valuesMap
      this.value = this.formatValueBasedOnAnnotation(this.value);
      this.refreshAnnotation();

      if (this.annotation.wrapEnabled) {
        this.attemptWrapText(); // wrap to next annotation
      }

      this.$emit("onUpdateValue", this.value);
    },
    onAnnotationBlur() {
      if (this.annotation.type == "DATE") {
        if (partialDateRegex.test(this.value)) {
          const digits = this.value.replace(/\D/g, "");
          const month = digits.substring(0, 2);
          const day = digits.substring(2, 4);
          const partialYear = digits.substring(4, 6);
          const year =
            partialYear > "50" ? `19${partialYear}` : `20${partialYear}`;
          this.value = `${month}/${day}/${year}`;
          this.$emit("onUpdateValue", this.value);
        }
      }
    },
    formatValueBasedOnAnnotation(val) {
      const type = this.annotation.type;

      // Format: MM/DD/YYYY
      if (type == "DATE") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length <= 2) {
          val = `${val.substring(0, 2)}`;
        } else if (val.length <= 4) {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
        } else {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}/${val.substring(
            4,
            8
          )}`;
        }
      }

      // Format: 123-12-1234
      if (type == "SSN") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length <= 3) {
          val = `${val.substring(0, 3)}`;
        } else if (val.length <= 5) {
          val = `${val.substring(0, 3)}-${val.substring(3, 5)}`;
        } else {
          val = `${val.substring(0, 3)}-${val.substring(3, 5)}-${val.substring(
            5,
            9
          )}`;
        }
      }

      // Format: (123) 123-1234
      if (type == "PHONE") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length <= 0) {
          val = "";
        } else if (val.length <= 3) {
          val = `(${val.substring(0, 3)}`;
        } else if (val.length <= 6) {
          val = `(${val.substring(0, 3)}) ${val.substring(3, 6)}`;
        } else {
          val = `(${val.substring(0, 3)}) ${val.substring(
            3,
            6
          )}-${val.substring(6, 10)}`;
        }
      }

      // Format: 12345
      if (type == "NUMBER") {
        val = val.replace(/[^0-9]/g, "");
      }

      return val;
    },
    isAnnotationFilled() {
      const type = this.annotation.type;
      const value = this.value;

      if (type == "DATE" && !dateRegex.test(value)) {
        return false;
      }

      if (type == "EMAIL" && !emailRegex.test(value)) {
        return false;
      }

      if (type == "SSN" && !ssnRegex.test(value)) {
        return false;
      }

      if (type == "PHONE" && !phoneRegex.test(value)) {
        return false;
      }

      return value.length > 0;
    },
    checkAnnotationIsFilled() {
      if (this.annotation.optional) {
        return true;
      }

      if (this.isAnnotationFilled()) {
        return true;
      }

      this.error = true;
      return false;
    },
    getTypeForAnnotation() {
      switch (this.annotation.type) {
        case "EMAIL":
          return "email";
        case "NUMBER":
        case "DATE":
        case "PHONE":
        case "SSN":
          return "tel";
        default:
          return "text";
      }
    },
    getPlaceholderForAnnotation() {
      let placeholder = "";

      if (
        !this.interactable &&
        this.annotation.key &&
        !this.annotation.key.startsWith("doNotImport")
      ) {
        return this.annotation.key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          });
      }

      switch (this.annotation.type) {
        case "EMAIL":
          placeholder = "example@gmail.com ";
          break;
        case "DATE":
          placeholder = "mm/dd/yyyy ";
          break;
        case "PHONE":
          placeholder = "(123) 123-1234 ";
          break;
        case "SSN":
          placeholder = "123-12-1234 ";
          break;
        case "NUMBER":
          if (this.interactable) {
            placeholder = " ";
          } else {
            placeholder = "Number... ";
          }
          break;
        default:
          if (this.interactable) {
            placeholder = " ";
          } else {
            placeholder = "Text... ";
          }
          break;
      }

      if (!this.interactable && !this.annotation.optional) {
        placeholder += "(required)";
      }

      return placeholder;
    },
    refreshAnnotation() {
      const inputRef = this.$refs.input;
      if (!inputRef) return;

      // Compute font size based on rect size
      const rect = inputRef.getBoundingClientRect();
      if (rect.height == 0) return;

      // Measure the width of the text
      const style = getComputedStyle(inputRef);
      const canvas = document.createElement("canvas");
      const context = canvas.getContext('2d');
      context.font = `${rect.height}px ${style.fontFamily}`;
      const textWidth = context.measureText(inputRef.value).width;

      // Resize annotation to fit
      const ratio = rect.width / textWidth;
      if (ratio < 1) {
        inputRef.style.fontSize = rect.height * ratio + 'px';
      } else {
        inputRef.style.fontSize = rect.height + 'px';
      }
    },
    attemptWrapText() {
      const inputRef = this.$refs.input;
      if (!inputRef) return;

      if (inputRef.selectionStart < inputRef.value.length) {
        // only wrap at end of line
        return;
      }

      let value = inputRef.value;
      const rect = inputRef.getBoundingClientRect();
      const style = getComputedStyle(inputRef);
      const canvas = document.createElement("canvas");
      const context = canvas.getContext('2d');
      context.font = `${rect.height}px ${style.fontFamily}`;
      const textWidth = context.measureText(value).width;

      // Check if they've reached the end of the line
      if (textWidth > rect.width) {
        const lastSpaceIndex = value.lastIndexOf(' ');
        if (lastSpaceIndex > 0) {
          const lastWord = value.substring(lastSpaceIndex);
          const textWithoutLastWord = value.substring(0, lastSpaceIndex);
          inputRef.value = textWithoutLastWord;
          this.$emit('wrapToNext', lastWord);
          this.$emit('onUpdateValue', inputRef.value)
        }
      }
    }
  },
  emits: ["onUpdateValue", "deleteAnnotation", "wrapToNext"],
};
</script>

<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 100%;
  height: 25px;
  flex-direction: column;
  justify-content: flex-end;
}

.input {
  display: block;
  width: 100%;
  height: 1px;
  flex: 1;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  opacity: 1;
  border-radius: 0px;
  padding: 0px !important;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  /* border-bottom: 1px solid #000; */
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>