<template>
  <div id="full">
    <div class="card">
      <div class="card-header">
        <h1>Appointment Check In</h1>
        <p>
          Welcome! Please sign in to confirm the information we have on-file about you. We may also require you to complete some forms.
        </p>
      </div>
      <div class="card-body">
        <form class="form-page" @submit.prevent="attemptLookupAndRedirect">
          <div class="form-group">
            <label for="name" class="form-label">Patient Last Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              ref="name"
              v-model="lastName"
              placeholder="Last Name"
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              required
            />
          </div>
          <div class="form-group">
            <label for="dateOfBirth" class="form-label"
              >Patient Date of Birth</label
            >
            <input
              id="dateOfBirth"
              type="text"
              name="dateOfBirth"
              class="form-control"
              v-model="dateOfBirth"
              placeholder="mm/dd/yyyy"
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              @input="formatDateOfBirth"
              required
            />
          </div>
          <div class="card-footer">
            <button class="button primary" @click.prevent="attemptLookupAndRedirect">
              <img v-if="pending" src="/loading.svg" alt="loading" class="loading" />
              <span v-else>Continue</span>
            </button>
            <p class="error">{{ errorText }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>
import { getCheckInUrl } from "@/api";

export default {
  name: "CheckInView",
  data() {
    return {
      lastName: "",
      dateOfBirth: "",
      errorText: "",
      pending: false
    };
  },
  methods: {
    async attemptLookupAndRedirect() {
      if (this.pending) {
        return;
      }

      const uniqueName = this.$route.params.uniqueName;
      try {
        this.pending = true;
        const privateUrl = await getCheckInUrl(uniqueName, this.lastName, this.dateOfBirth);
        this.$router.push(`/submission/${privateUrl}?checkin=1`);
      } catch (err) {
        this.errorText = err.message || 'An unexpected error occurred. Please try again.';
      } finally {
        this.pending = false;
      }
    },
    formatDateOfBirth() {
      let numbers = this.dateOfBirth.replace(/\D/g, ""); // Remove non-numeric characters
      if (numbers.length > 4) {
        numbers =
          numbers.substring(0, 2) +
          "/" +
          numbers.substring(2, 4) +
          "/" +
          numbers.substring(4, 8);
      } else if (numbers.length > 2) {
        numbers = numbers.substring(0, 2) + "/" + numbers.substring(2, 4);
      }
      this.dateOfBirth = numbers.substring(0, 10); // Ensure it doesn't exceed the length for mm/dd/yyyy
    },
  },
  components: {},
};
</script>
  
<style scoped>
#full {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-label {
  color: black;
}

.submit-btn {
  margin-top: 20px;
  max-width: 200px;
  max-height: 50px;
  padding: 10px;
  border: none;
  background: var(--color-primary);
  color: #fff;
}

.error {
  color: red;
}
</style>