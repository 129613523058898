<template>
  <div class="component">
    <label class="label">{{ component.label }}<span class="optional-tag" v-if="component.optional">(optional)</span></label>
    <input
      class="input"
      type="text"
      :name="component.label"
      :disabled="!interactable"
      :filled="isComponentFilled()"
      :error="error"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      v-model="value"
      @mousedown.prevent="openOptions()"
      @keydown.prevent=""
    />
    <div class="select-options" v-if="open" ref="options">
      <div class="select-option" v-for="option of getOptions()" :key="option">
        <CheckBox colorFilled="#EEE" colorLine="#000" :value="isCheckboxChecked(option)" @updateValue="setCheckboxChecked($event, option)" />
        <label>{{  option }}</label>
      </div>
    </div>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent">
  </div>
</template>

<script>
import CheckBox from '../CheckBox.vue';

export default {
  name: "MultiSelectComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  data() {
    return {
      value: '',
      error: false,
      open: false,
      selected: []
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.onMouseDown);
  },
  unmounted() {
    window.removeEventListener('mousedown', this.onMouseDown);
  },
  methods: {
    updateValue(value) {
      this.value = value;
    },
    onComponentChange() {
      // Pass to parent, who will help pass to valuesMap
      this.$emit('onUpdateValue', this.value);
    },
    deleteComponent() {
      this.$emit('deleteComponent')
    },
    isComponentFilled() {
      return this.value.length > 0;
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    },
    getOptions() {
      return this.component.options;
    },
    openOptions() {
      if (!this.interactable) return;
      setTimeout(() => {
        // timeout to prevent immediately closing
        this.open = true;
      }, 1);
    },
    onMouseDown(event) {
      if (!this.open) return;

      const options = this.$refs.options;
      if (!options) return;

      // If click outside of element
      const target = event.target;
      if (!options.contains(target)) {
        this.open = false;
      }
    },
    isCheckboxChecked(option) {
      return this.selected.includes(option);
    },
    setCheckboxChecked(isChecked, option) {
      // Remove option from selected
      this.selected = this.selected.filter(opt => opt != option);

      // Push it if needed
      if (isChecked) {
        this.selected.push(option);
      }

      // Recompute value
      this.value = this.selected.join(', ');
      this.onComponentChange();
    }
  },
  components: {CheckBox},
  emits: ['deleteComponent', 'onUpdateValue']
};
</script>

<style scoped>
.component {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 45px;
  opacity: 1;
  border-radius: 0px;
  color: #000;
  cursor: pointer;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #FFF;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.optional-tag {
  margin-left: 5px;
  color: #9d9d9d;
}

.select-options {
  position: absolute;
  top: 25%;
  left: 0;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
}

.select-option {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-option .checkbox {
  flex-shrink: 0;
}

.select-option label {
  margin-left: 10px;
  font-size: 20px;
  color: #222;
}
</style>