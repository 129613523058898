<template>
  <div class="component">
    <label class="label">{{ component.label }}<span class="optional-tag" v-if="component.optional">(optional)</span></label>
    <canvas class="input" ref="canvas" @click="openSignatureModal" :filled="isComponentFilled()" :error="error"></canvas>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent">
  </div>
</template>

<script>

export default {
  name: "InputComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  data() {
    return {
      value: '',
      error: false
    }
  },
  methods: {
    updateValue(value) {
      const lastVal = this.value;
      this.value = value;

      // After this component has been updated, render it
      if (this.value != lastVal) {
        this.refreshComponent();
      }
    },
    deleteComponent() {
      this.$emit('deleteComponent')
    },
    isComponentFilled() {
      return this.value.length > 0;
    },
    openSignatureModal() {
      console.info('Opening signature model');
      this.$emit('openSignatureModal')
    },
    refreshComponent(scale = 3) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      canvas.width = canvas.offsetWidth * scale;
      canvas.height = canvas.offsetHeight * scale;

      const img = new Image();
      img.src = this.value;
      img.onload = () => {
        const ratio = canvas.height / img.height;
        context.drawImage(img, 0, 0, img.width * ratio, img.height * ratio);
      }
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    }
  },
  emits: ['deleteComponent', 'updateValue', 'openSignatureModal']
};
</script>

<style scoped>
.component {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 90px;
  opacity: 1;
  cursor: pointer;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
  border-bottom: 1px solid #00000022;
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
  border-bottom: 1px solid #00000022;
}

.input:focus {
  border-bottom: 1px solid #000;
}
.input:disabled {
  pointer-events: none;
  cursor: default;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #FFF;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.optional-tag {
  margin-left: 5px;
  opacity: 0.5;
}
</style>