<template>
  <SignatureAnnotation
    ref="annotation"
    v-if="annotation.type == 'SIGNATURE'"
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
    @openSignatureModal="openSignatureModal"
  />
  <InputBoxAnnotation
    ref="annotation"
    v-else-if="annotation.type == 'CHECKBOX' || annotation.type == 'CIRCLEBOX'"
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
    @onUpdateValue="onUpdateValue"
  />
  <DropdownAnnotation
    ref="annotation"
    v-else-if="annotation.type == 'DROPDOWN' || annotation.type == 'STATE'"
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
    @onUpdateValue="onUpdateValue"
  />
  <ProviderSignatureAnnotation
    ref="annotation"
    v-else-if="annotation.type == 'PROVIDER_SIGNATURE'"
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
  />
  <ProviderTextAnnotation
    ref="annotation"
    v-else-if="annotation.type == 'PROVIDER_TEXT'"
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
  />
  <DrawBoxAnnotation
    ref="annotation"
    v-else-if="annotation.type == 'DRAW_BOX'"
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
    @onUpdateValue="onUpdateValue"/>
  <InputAnnotation
    ref="annotation"
    v-else
    :annotation="annotation"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteAnnotation="deleteAnnotation"
    @onUpdateValue="onUpdateValue"
    @wrapToNext="$emit('wrapToNext', this, $event)"
  />
</template>

<script>
import InputAnnotation from "./InputAnnotation.vue";
import SignatureAnnotation from "./SignatureAnnotation.vue";
import InputBoxAnnotation from "./InputBoxAnnotation.vue";
import DropdownAnnotation from "./DropdownAnnotation.vue";
import ProviderSignatureAnnotation from "./ProviderSignatureAnnotation.vue";
import ProviderTextAnnotation from "./ProviderTextAnnotation.vue";
import DrawBoxAnnotation from './DrawBoxAnnotation.vue';

export default {
  name: "HtmlComponent",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean,
    valuesMap: Map,
  },
  methods: {
    onUpdateValue(value) {
      // Pass updated value up to the form
      this.$emit("onUpdateValue", value);
    },
    updateValue(value) {
      const annotationEl = this.$refs.annotation;
      if (!annotationEl) return;
      if (annotationEl.updateValue == undefined) return;
      annotationEl.updateValue(value);
    },
    deleteAnnotation() {
      this.$emit("deleteAnnotation");
    },
    isAnnotationFilled() {
      const annotationEl = this.$refs.annotation;
      if (annotationEl && annotationEl.isAnnotationFilled != undefined) {
        return annotationEl.isAnnotationFilled();
      }
      return true;
    },
    checkAnnotationIsFilled() {
      const annotationEl = this.$refs.annotation;
      if (annotationEl && annotationEl.checkAnnotationIsFilled != undefined) {
        return annotationEl.checkAnnotationIsFilled();
      }
      return true;
    },
    setError(value) {
      const annotationEl = this.$refs.annotation;
      if (annotationEl && annotationEl.setError != undefined) {
        annotationEl.setError(value);
      }
    },
    refreshAnnotation() {
      const annotationEl = this.$refs.annotation;
      if (annotationEl && annotationEl.refreshAnnotation != undefined) {
        annotationEl.refreshAnnotation();
      }
    },
    openSignatureModal() {
      this.$emit("openSignatureModal");
    },
  },
  components: {
    InputAnnotation,
    SignatureAnnotation,
    InputBoxAnnotation,
    DropdownAnnotation,
    ProviderSignatureAnnotation,
    ProviderTextAnnotation,
    DrawBoxAnnotation
  },
  emits: [
    "onUpdateValue",
    "deleteAnnotation",
    "openSignatureModal",
    "wrapToNext"
  ],
};
</script>

<style scoped></style>