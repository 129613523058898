<template>
  <div class="annotation" ref="annot">
    <canvas class="input" ref="canvas" @click="openSignatureModal" :error="error" :filled="isAnnotationFilled()"></canvas>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @mousedown.prevent.stop="$emit('deleteAnnotation')">
  </div>
</template>

<script>

export default {
  name: "SignatureAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  data() {
    return {
      value: '',
      error: false
    }
  },
  methods: {
    updateValue(value) {
      const lastVal = this.value;
      this.value = value;

      // After this component has been updated, render it
      if (this.value != lastVal) {
        setTimeout(() => {
          this.refreshAnnotation();
        }, 0)
      }
    },
    openSignatureModal() {
      this.$emit('openSignatureModal');
    },
    refreshAnnotation(scale = 3) {
      const canvas = this.$refs.canvas;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * scale;
      canvas.height = rect.height * scale;

      const img = new Image();
      img.src = this.value;
      img.onload = () => {
        const ratio = canvas.height / img.height;
        context.drawImage(img, 0, 0, img.width * ratio, img.height * ratio);
      }
    },
    isAnnotationFilled() {
      return this.value.length > 0;
    },
    checkAnnotationIsFilled() {
      if (this.annotation.optional) {
        return true;
      }

      if (this.isAnnotationFilled()) {
        return true;
      }

      this.error = true;
      return false;
    }
  },
  emits: ['deleteAnnotation', 'openSignatureModal']
};
</script>

<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: column;
  justify-content: flex-end;
}

.input {
  display: block;
  width: 100%;
  height: 1px;
  flex: 1;
  outline: none;
  background: var(--color-patient-field);
  opacity: 1;
  cursor: pointer;
  border-radius: 0px;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
  /* background: #FFF; */
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>