<template>
  <div id="full">
    <AccountNavbar />
    <transition name="appear" mode="out-in">
      <div class="form-editor-container" v-if="loaded">
        <FormEditorPdf :form="form" :emrJson="emrJson" @formUpdated="formUpdated" @openModal="openModal"
          v-if="form.type == 'FILE'" />
        <FormEditorHtml :form="form" :emrJson="emrJson" @formUpdated="formUpdated" v-else />
      </div>
    </transition>
    <transition name="modal" mode="out-in">
      <SignatureModal v-if="modal.id == 'signature'" @closeModal="closeModal" @submitSignature="submitSignature" />
      <TextModal v-else-if="modal.id == 'text'" @closeModal="closeModal" @submitText="submitText"/>
    </transition>
  </div>
</template>

<style scoped></style>

<script>
import AccountNavbar from "../components/AccountNavbar.vue";
import FormEditorPdf from '../components/form-editor-pdf/FormEditorPdf.vue';
import FormEditorHtml from '../components/form-editor-html/FormEditorHtml.vue';
import SignatureModal from '../modals/form-editor/SignatureModal.vue';
import TextModal from "../modals/form-editor/TextModal.vue";
import { latestVersion, getPublicJson } from "@/api";

export default {
  name: "FormEditorView",
  data() {
    return {
      form: null,
      loaded: false,
      saved: false,
      emrJson: null,
      modal: { id: '', open: false, data: {} }
    };
  },
  async created() {
    const id = this.$route.params.id;
    try {
      this.loaded = false;
      this.form = await latestVersion(id);
      this.emrJson = await getPublicJson('/emrs/practicefusion.json')
      console.info('Fetched form info from backend', this.form);
    } catch (err) {
      console.error('Failed to fetch form info', err);
    } finally {
      this.loaded = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      const answer = confirm('Are you sure you want to leave without saving changes?');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    formUpdated() {
      this.saved = true;
      this.$router.push('/forms');
      console.info('Form was updated');
    },
    submitText(text) {
      // Verify modal is open and data has an annotation
      if (!this.modal.open) return;
      if (!this.modal.data) return;

      const annot = this.modal.data;
      annot.data = text;
      this.closeModal();
    },
    submitSignature(base64) {
      // Verify modal is open and data has an annotation
      if (!this.modal.open) return;
      if (!this.modal.data) return;

      const annot = this.modal.data;
      annot.data = base64;
      this.closeModal();
    },
    openModal(id, data) {
      this.modal = { id: id, open: true, data: data }
    },
    closeModal() {
      this.modal = { id: '', open: false, data: {} }
    }
  },
  components: { AccountNavbar, FormEditorPdf, FormEditorHtml, SignatureModal, TextModal },
};
</script>

<style scoped>
.form-editor-container {
  width: 100%;
  height: 100%;
}
</style>