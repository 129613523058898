<template>
  <div class="form-filler" ref="filler">
    <!-- Forms and content -->
    <transition name="fade" mode="out-in">
      <div class="success-modal-container" v-if="loaded && isSubmitted">
        <div class="card">
          <h1>Forms Submitted</h1>
          <p>
            Thank you for taking the time to complete your forms! Your
            filled forms have been received by the office.
          </p>
          <hr style="margin-top: 25px" />
          <ExperienceRating @updateRating="updateRating" />
          <button class="button submit" style="margin-top: 50px" @click="closePage">
            Close Browser
          </button>
        </div>
      </div>
      <div class="form-container" :key="forms[currentFormIndex].id" v-else-if="loaded">
        <FillableHtmlForm v-if="forms[currentFormIndex].type == 'HTML'" ref="form" :form="forms[currentFormIndex]"
          :valuesMap="valuesMap" :keysOccurringFirstTime="getKeysThatOccurFirstTimeOnIndex(currentFormIndex)
            " @onUpdateValue="onUpdateValue" @openSignatureModal="openSignatureModal"
          @openRepeatedFieldModal="openRepeatedFieldModal" />
        <FillablePdfForm v-if="forms[currentFormIndex].type == 'FILE'" ref="form" :form="forms[currentFormIndex]"
          :valuesMap="valuesMap" :keysOccurringFirstTime="getKeysThatOccurFirstTimeOnIndex(currentFormIndex)
            " @onUpdateValue="onUpdateValue" @openSignatureModal="openSignatureModal"
          @openRepeatedFieldModal="openRepeatedFieldModal" />
        <div class="button-row">
          <img src="@/assets/arrow.png" alt="Previous" class="prev-arrow" @click.prevent="goToPrevPage"
            v-if="!isOnFirstForm()" />
          <button class="button submit" @click.prevent="goToNextPage">
            <img v-if="pending" src="/loading.svg" alt="Loading" class="loading" />
            <span v-else-if="isOnLastForm()">Submit</span>
            <span v-else>Next Form</span>
          </button>
        </div>
        <p>{{ currentFormIndex + 1 }} / {{ formVersionIds.length }}</p>
      </div>
    </transition>
    <!-- Modals -->
    <transition name="fade" mode="out-in">
      <WelcomeModal @submitForm="welcomeModalFilled" v-if="modal.id == 'welcome'" />
      <WelcomeContractModal @submitForm="welcomeContractModalFilled" v-else-if="modal.id == 'contract'" />
      <WelcomeCheckInModal @closeModal="welcomeCheckInModalFilled" v-else-if="modal.id == 'check-in'" />
      <SignatureModal @submitSignature="signatureModalFilled" @closeModal="closeModal"
        :lastSignatureType="modal.data.signatureType" :lastSignature="getLastSignature()"
        v-else-if="modal.id == 'signature'" />
      <RepeatedFieldModal @closeModal="closeModal" @visitOriginalField="visitOriginalField"
        :formIndex="modal.data.formIndex" :fieldKey="modal.data.key" v-else-if="modal.id == 'repeated-field'" />
    </transition>
    <!-- Loading icon -->
    <img v-if="!loaded" src="/loading-big.svg" alt="Loading Icon" class="loading-big" />
  </div>
</template>

<script>
import {
  formsForUrl,
  patientInfo,
  createSubmission,
  updateSubmission,
  updateSubmissionMetadata,
  updateSubmissionRating,
  uploadFile,
  versionInfo,
  submit,
} from "@/api";
import { detect } from "detect-browser";
import FillableHtmlForm from "../components/form-filler/FillableHtmlForm.vue";
import FillablePdfForm from "../components/form-filler/FillablePdfForm.vue";
import WelcomeModal from "../modals/form-filler/WelcomeModal.vue";
import WelcomeContractModal from "../modals/form-filler/WelcomeContractModal.vue";
import WelcomeCheckInModal from '../modals/form-filler/WelcomeCheckInModal.vue';
import SignatureModal from "@/modals/form-filler/SignatureModal.vue";
import RepeatedFieldModal from "@/modals/form-filler/RepeatedFieldModal.vue";
import ExperienceRating from "@/components/form-filler/ExperienceRating.vue"


export default {
  name: "FormFillerView",
  data() {
    return {
      loaded: false,
      pending: false,
      formVersionIds: [],
      forms: [],
      firstFormIndexForEachKey: new Map(),
      currentFormIndex: 0,
      valuesMap: new Map(),
      isSubmitted: false,
      scrollTop: 0,
      lastSignatureFrom: new Map(),
      modal: { id: "", open: false, data: {} },
    };
  },
  async created() {
    const query = this.$route.query;
    let shouldOpenWelcome = true;

    try {
      this.loaded = false;
      if (this.isExistingSubmission()) {
        await this.tryFetchSubmissionDetails();
        console.info("Fetched submission details from backend");

        // Open welcome modal if we don't have their info
        if (
          this.valuesMap.has("patientFirstName") &&
          this.valuesMap.has("patientLastName") &&
          this.valuesMap.has("patientDateOfBirth")
        ) {
          shouldOpenWelcome = false;
        }
      } else {
        await this.tryFetchFormsForUrl();
        console.info("Fetched forms from url");
      }

      // Fetch forms
      await this.tryFetchFormVersions();

      // Compute page index for each field key
      this.computeFirstFormIndexForEachKey();
    } catch (err) {
      console.error(err);
      alert("Failed to open paperwork: " + err.message);
    } finally {
      this.loaded = true;

      // Deliberately set the welcome modal to not open
      if (query.contract !== undefined && query.contract == 1) {
        setTimeout(() => {
          this.openModal("contract", {});
        }, 0);
      } else if (query.checkin !== undefined && query.checkin == 1) {
        setTimeout(() => {
          this.openModal("check-in", {});
        }, 0);
      } else if (shouldOpenWelcome && query.skip === undefined) {
        setTimeout(() => {
          this.openModal("welcome", {});
        }, 0);
      }
    }
  },
  mounted() {
    document.documentElement.style.overscrollBehavior = 'none';
    document.body.style.overscrollBehavior = 'none';
  },
  methods: {
    onUpdateValue(key, value) {
      // Store key's new value in valuesMap
      this.valuesMap.set(key, value);
    },
    async goToPrevPage() {
      try {
        // Set pending to true
        this.pending = true;

        // Always send update
        await this.waitForNextTick(); // required to get access to privateUrl
        await this.tryUpdateSubmission(this.currentFormIndex - 1);
        console.info("Updated submission with values");

        this.currentFormIndex--;
        console.info("Opened prev form");
        setTimeout(() => {
          window.scrollTo({ top: 0 });
        }, 200);
      } catch (err) {
        console.error(err);
        alert("Failed save page: " + err.message);
      } finally {
        this.pending = false;
      }
    },
    async goToNextPage() {
      // Verify current form is filled, and if not: scroll to first unfilled element
      const form = this.$refs.form;
      if (!form != null && form.checkFormIsFilled != undefined) {
        if (!form.checkFormIsFilled()) {
          const firstUnfilled = form.getFirstUnfilledElement();
          if (firstUnfilled != null) {
            const firstUnfilledEl = firstUnfilled.$el;
            firstUnfilledEl.scrollIntoView({ behavior: "smooth" });
          } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
          return;
        }
      }

      try {
        this.pending = true;

        // When first form, create new submission
        if (!this.isExistingSubmission()) {
          await this.tryCreateSubmission();
          console.info("Created new submission using url");
        }

        // Always send update
        await this.waitForNextTick(); // required to get access to privateUrl

        // Try to update metadata, but never fail the submission
        try {
          await this.tryUpdateSubmissionMetadata();
        } catch (err) {
          console.error("Failed to update metadata. Thats fine, proceed");
        }

        await this.tryUpdateSubmission(this.currentFormIndex + 1);
        console.info("Updated submission with values");

        // When last form, submit after
        if (this.isOnLastForm()) {
          await this.trySubmit();
          console.info("Submission was marked as submitted");
        } else {
          this.currentFormIndex++;
          this.precomputeInferredFields();
          console.info("Opened next form");
          setTimeout(() => {
            window.scrollTo({ top: 0 });
          }, 200);
        }
      } catch (err) {
        console.error(err);
        alert("Failed save page: " + err.message);
      } finally {
        this.pending = false;
      }
    },
    async trySubmit() {
      const privateUrl = this.$route.params.privateUrl;
      await submit(privateUrl);
      this.isSubmitted = true;
    },
    async updateRating(rating) {
      try {
        const privateUrl = this.$route.params.privateUrl;
        await updateSubmissionRating(privateUrl, rating)
        console.info('Updated rating', rating);
      } catch (err) {
        console.error('Failed to submit rating', err);
      }
    },
    async tryCreateSubmission() {
      const firstName = this.valuesMap.get("patientFirstName");
      const lastName = this.valuesMap.get("patientLastName");
      if (!firstName || !lastName) {
        alert("Error: you must fill your first and last name!");
        return;
      }

      const officeName = this.$route.params.office;
      const formVersionIds = this.formVersionIds;
      const submission = await createSubmission(
        `${firstName} ${lastName}`,
        officeName,
        formVersionIds
      );
      this.$router.replace(`/submission/${submission.privateUrl}`);
    },
    async tryUpdateSubmission(currentPageIndex) {
      const privateUrl = this.$route.params.privateUrl;
      let fileMap = new Map();

      // Send post request
      for (let [key, value] of this.valuesMap.entries()) {
        if (value instanceof File) {
          console.info("Detected a file in valuesMap. Going to upload it...");
          const file = await uploadFile(value, privateUrl);
          fileMap.set(key, file.id);
        }
      }

      // todo: check if you can alter a key while iterating through it
      for (let [key, value] of fileMap.entries()) {
        this.valuesMap.set(key, value);
        console.info(`Updated values map ${key} to ${value}`);
      }

      await updateSubmission(privateUrl, this.valuesMap, currentPageIndex);
    },
    async tryUpdateSubmissionMetadata() {
      const privateUrl = this.$route.params.privateUrl;

      // Detect which browser they are using
      const browser = detect();

      // Post metadata
      const metadata = {
        browser: browser.name,
        version: browser.version,
        operatingSystem: browser.os,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      };

      await updateSubmissionMetadata(privateUrl, metadata);
    },
    async tryFetchSubmissionDetails() {
      const privateUrl = this.$route.params.privateUrl;
      const submission = await patientInfo(privateUrl);
      const forms = submission.forms;
      this.formVersionIds = forms.map((form) => form.version);
      this.currentFormIndex = submission.currentPageIndex;
      this.isSubmitted = submission.dateSubmitted != null;

      this.valuesMap = new Map();
      if (submission.values != null) {
        for (let [key, value] of Object.entries(submission.values)) {
          this.valuesMap.set(key, value);
        }
      }
    },
    async tryFetchFormsForUrl() {
      const office = this.$route.params.office;
      const url = this.$route.params.url;
      this.formVersionIds = await formsForUrl(office, url);
    },
    async tryFetchFormVersions() {
      const forms = [];

      for (let formVersionId of this.formVersionIds) {
        const form = await versionInfo(formVersionId);
        forms.push(form);
      }

      this.forms = forms;
    },
    async waitForNextTick() {
      return new Promise((resolve) => {
        setTimeout(resolve, 1);
      });
    },
    computeFirstFormIndexForEachKey() {
      const firstFormIndexForEachKey = new Map();

      // Loop over each form version in this submission
      for (let formIndex = 0; formIndex < this.forms.length; formIndex++) {
        const form = this.forms[formIndex];
        try {
          if (form.type == "HTML") {
            for (let row of form.layoutRows) {
              for (let component of row.components) {
                const key = component.key;
                if (!firstFormIndexForEachKey.has(key)) {
                  firstFormIndexForEachKey.set(key, formIndex);
                }
              }
            }
          }
          if (form.type == "FILE") {
            for (let page of form.filePages) {
              for (let annotation of page.pageAnnotations) {
                const key = annotation.key;
                if (!firstFormIndexForEachKey.has(key)) {
                  firstFormIndexForEachKey.set(key, formIndex);
                }
              }
            }
          }
        } catch (err) {
          console.error("Failed to process form: " + form.id, err);
        }
      }

      console.info(
        "Computed first form index for each key: ",
        firstFormIndexForEachKey
      );
      this.firstFormIndexForEachKey = firstFormIndexForEachKey;
    },
    getKeysThatOccurFirstTimeOnIndex(index) {
      const keys = [];

      for (let [key, formIndex] of this.firstFormIndexForEachKey) {
        if (formIndex == index) {
          keys.push(key);
        }
      }

      return keys;
    },
    isExistingSubmission() {
      return this.$route.params.privateUrl != null;
    },
    isOnFirstForm() {
      return this.currentFormIndex == 0;
    },
    isOnLastForm() {
      return this.currentFormIndex >= this.formVersionIds.length - 1;
    },
    closePage() {
      window.open("", "_self", "");
      window.close();
    },
    welcomeContractModalFilled(first, last, business) {
      this.valuesMap.set("patientFirstName", first);
      this.valuesMap.set("patientLastName", last);
      this.valuesMap.set("patientFullName", `${first} ${last}`);
      this.valuesMap.set("otherBusinessName", business);
      this.valuesMap.set("todaysDate", this.getTodaysDate());

      this.closeModal();

      // Update values
      const formEl = this.$refs.form;
      if (formEl) {
        formEl.updateValues();
      }
    },
    welcomeModalFilled(first, last, dob) {
      this.valuesMap.set("patientFirstName", first);
      this.valuesMap.set("patientLastName", last);
      this.valuesMap.set("patientDateOfBirth", dob);
      this.valuesMap.set("todaysDate", this.getTodaysDate());
      this.valuesMap.set("todaysDateAndTime", this.getTodaysDateAndTime());
      this.valuesMap.set("patientFullName", `${first} ${last}`);
      this.valuesMap.set("patientAge", this.calculateAgeAsString(dob));
      this.closeModal();
    },
    welcomeCheckInModalFilled() {
      const first = this.valuesMap.get('patientFirstName');
      const last = this.valuesMap.get('patientLastName');
      const dob = this.valuesMap.get('patientDateOfBirth');
      this.valuesMap.set("todaysDate", this.getTodaysDate());
      this.valuesMap.set("todaysDateAndTime", this.getTodaysDateAndTime());
      if (first && last) {
        this.valuesMap.set("patientFullName", `${first} ${last}`);
      }
      if (dob) {
        this.valuesMap.set("patientAge", this.calculateAgeAsString(dob));
      }
      this.closeModal();
    },
    async signatureModalFilled(base64) {
      const { key, signatureType } = this.modal.data;
      if (base64.length > 0) {
        const base64Cropped = await this.base64ToCroppedBase64(base64);
        this.valuesMap.set(key, base64Cropped); // Store cropped version (for bigger signature)
      } else {
        this.valuesMap.set(key, '');
      }
      this.lastSignatureFrom.set(signatureType, base64); // Store original
      this.closeModal();
    },
    async base64ToCroppedBase64(base64) {
      // Load the image from base64 string
      const loadImage = (base64) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (error) => reject(error);
          img.src = base64;
        });
      };

      // Await the loading of the image
      const img = await loadImage(base64);

      // Once the image is loaded, proceed to draw it on a canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Process the canvas to find active pixels and crop
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;

      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const alpha = data[(y * canvas.width + x) * 4 + 3];
          if (alpha > 0) { // Active pixel
            minX = Math.min(minX, x);
            maxX = Math.max(maxX, x);
            minY = Math.min(minY, y);
            maxY = Math.max(maxY, y);
          }
        }
      }

      if (minX > maxX || minY > maxY) {
        // If there are no active pixels, return an empty string
        return '';
      }

      // Calculate the dimensions of the bounding box
      const width = maxX - minX + 1;
      const height = maxY - minY + 1;

      // Create a new canvas to draw the cropped area
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = width;
      croppedCanvas.height = height;
      const croppedCtx = croppedCanvas.getContext('2d');

      // Draw the cropped area onto the new canvas
      croppedCtx.drawImage(canvas, minX, minY, width, height, 0, 0, width, height);

      // Return the base64 representation of the cropped canvas
      return croppedCanvas.toDataURL();
    },
    async visitOriginalField() {
      const { key, formIndex } = this.modal.data;

      this.closeModal();

      // Wait 500ms
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      })

      if (formIndex == this.currentFormIndex) {
        const formEl = this.$refs.form;
        if (formEl && formEl.getFirstElementOfKey != undefined) {
          const firstElementOfKey = formEl.getFirstElementOfKey(key);
          if (firstElementOfKey) {
            firstElementOfKey.scrollIntoView({ behavior: "smooth" });
            const input = firstElementOfKey.querySelector('.input');
            if (input) {
              input.focus();
              input.select();
            } else {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          }
        }
        return;
      }

      // Set pending to true
      try {
        this.pending = true;
        await this.waitForNextTick(); // required to get access to privateUrl
        await this.tryUpdateSubmission(this.currentFormIndex);
        console.info("Updated submission with values");
      } catch (err) {
        console.error('Failed to save form', err);
        alert('Failed to save current form. Please try again.');
      } finally {
        this.pending = false;
      }

      this.currentFormIndex = formIndex;

      setTimeout(() => {
        const formEl = this.$refs.form;
        if (formEl && formEl.getFirstElementOfKey != undefined) {
          const firstElementOfKey = formEl.getFirstElementOfKey(key);
          if (firstElementOfKey) {
            firstElementOfKey.scrollIntoView({ behavior: "smooth" });
            const input = firstElementOfKey.querySelector('.input');
            if (input) {
              input.focus();
              input.select();
            } else {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          }
        }
      }, 600);
    },
    openSignatureModal(signatureType, key) {
      if (!signatureType || signatureType == "any") {
        // Default to patient signature
        signatureType = "signature-patient";
      }

      this.openModal("signature", { key, signatureType });
    },
    openRepeatedFieldModal(key) {
      const formIndex = this.firstFormIndexForEachKey.get(key)
      if (formIndex == null) {
        alert('Could not find which form this field is from. Please send a text to (707) 506-6725. Thank you so much!')
        return;
      }
      this.openModal("repeated-field", { key, formIndex })
    },
    closeModal() {
      this.modal = { id: "", open: false, data: {} };

      // toggle scroll
      document.documentElement.style.overflow = '';
      document.documentElement.style.height = '';
    },
    openModal(id, data) {
      this.modal = { id: id, open: true, data: data };

      // disable scroll
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.height = '100svh';
    },
    getLastSignature() {
      let signatureType = this.modal.data && this.modal.data.signatureType;
      if (!signatureType) {
        signatureType = "signature-patient";
      }

      return this.lastSignatureFrom.get(signatureType);
    },
    calculateAgeAsString(dateString) {
      const birthDate = new Date(dateString);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - birthDate.getFullYear();
      const hasHadBirthdayThisYear =
        currentDate.getMonth() > birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() >= birthDate.getDate());
      if (!hasHadBirthdayThisYear) {
        age--;
      }
      return `${age}`;
    },
    getTodaysDate() {
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
    getTodaysDateAndTime() {
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      const hour = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${month}/${day}/${year} ${hour}:${minutes}`;
    },
    precomputeInferredFields() {
      const vm = this.valuesMap;

      // Full address is not filled, but can be
      if (
        !vm.has("addressFullAddress") &&
        vm.has("addressAddressLine1") &&
        vm.has("addressCity") &&
        vm.has("addressState") &&
        vm.has("addressZipCode")
      ) {
        const line1 = vm.get("addressAddressLine1");
        const line2 = vm.get("addressAddressLine2");
        const city = vm.get("addressCity");
        const state = vm.get("addressState");
        const zipCode = vm.get("addressZipCode");

        // Format individual parts as a full address
        let fullAddress;
        if (line2 != null) {
          fullAddress = `${line1} ${line2} ${city}, ${state} ${zipCode}`;
        } else {
          fullAddress = `${line1} ${city}, ${state} ${zipCode}`;
        }

        vm.set("addressFullAddress", fullAddress);
      }
    },
  },
  components: {
    FillableHtmlForm,
    FillablePdfForm,
    WelcomeModal,
    SignatureModal,
    WelcomeContractModal,
    WelcomeCheckInModal,
    RepeatedFieldModal,
    ExperienceRating
  },
};
</script>

<style scoped>
.form-filler {
  width: 100%;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.form-container[blurred="true"] {
  filter: blur(5px);
}

.success-modal-container {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button-row {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.button.submit {
  width: auto;
  padding: 15px 25px;
  background: var(--color-patient);
  color: #fff;
}

.button.submit:disabled {
  background: #535353;
}

.prev-arrow {
  height: 20px;
  margin-left: -30px;
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .form-container {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.loading-big {
  position: fixed;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
}
</style>

<style>
.form-filler .input:disabled {
  color: #000 !important;
  border-bottom: 2px dashed #00000022 !important;
}

.form-filler .input:disabled::placeholder {
  opacity: 0;
}

.form-filler .annotation:has(.input:disabled),
.form-filler .component:has(.input:disabled) {
  cursor: pointer !important;
}
</style>