<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card">
      <div class="card-header">
        <h1>Remove User</h1>
        <p>Are you sure you want to remove <u>{{user.email}}</u>? They will no longer be able to sign in.</p>
      </div>
      <div class="card-footer">
        <button class="button danger" @click.prevent="attemptDelete">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else>Delete</span>
        </button>
        <p class="error" v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteUser } from "@/api";

export default {
  name: "DeleteUserModal",
  props: {
    user: Object,
  },
  data() {
    return {
      error: "",
      pending: false,
    };
  },
  methods: {
    async attemptDelete() {
      try {
        this.pending = true;
        await deleteUser(this.user.id);
        this.$emit("closeModal");
      } catch (err) {
        console.error("Failed to delete user", err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
  },
  emits: ["closeModal"],
};
</script>

<style scoped>
.card-header {
  margin-bottom: 0px;
}
</style>