<template>
  <div class="form-editor">
    <ComponentSidebar @onDragStart="onDragStart" @onDragEnd="onDragEnd" />
    <div class="page-container">
      <div class="page" @click="unselectComponent" ref="page">
        <!-- Page rows -->
        <draggable class="page-rows" :list="rows" group="rows">
          <div class="page-row" v-for="(row, idx) of rows" :key="row.key">
            <div class="reorder" @click.shift.prevent="duplicateRow(row, idx)"></div>
            <!-- Row columns -->
            <draggable
              class="row-columns"
              :list="row.components"
              :group="getGroupForRow(row)"
              @end="onDragEnd"
            >
              <!-- USE THE INDEX -->
              <HtmlComponent
                v-for="component of row.components"
                :data-conditional="component.condition != null"
                :key="component.key"
                :component="component"
                :interactable="false"
                :selected="component == selectedComponent"
                :showTrash="component == selectedComponent"
                @click="selectComponent(component)"
                @deleteComponent="deleteComponent(component)"
              />
            </draggable>
          </div>
        </draggable>
        <!-- Drag and drop area -->
        <draggable
          :list="dragAndDrop"
          group="components"
          class="drag-and-drop"
          @change="afterAddToDragAndDrop"
        >
        </draggable>
      </div>
      <button class="button primary save-button" @click.prevent="saveChanges()">
        <img v-if="pending" src="/loading.svg" alt="loading" class="loading" />
        <span v-else>Save Changes</span>
      </button>
      <a href="#" v-if="form.isUnderReview && !isFormChanged()" style="margin-top: 10px" @click="startFormReview">Complete Form Review</a>
    </div>
    <PropertiesSidebar :emrJson="emrJson" :component="selectedComponent" :allComponents="getAllComponents()" @updateComponent="updateComponent" />
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import ComponentSidebar from "./ComponentSidebar.vue";
import PropertiesSidebar from "./PropertiesSidebar.vue";
import HtmlComponent from "../form-components-html/HtmlComponent.vue";
import { updateFormLayout, uploadAccountFile, updateFormThumbnail, reviewForm } from "@/api";
import html2canvas from 'html2canvas'

export default {
  name: "FormEditorHtml",
  props: {
    form: Object,
    emrJson: Object
  },
  data() {
    return {
      rows: [],
      originalRows: [],
      dragAndDrop: [],
      draggingComponent: null,
      selectedComponent: null,
      pending: false,
    };
  },
  mounted() {
    try {
      this.rows = structuredClone(this.form.layoutRows || []);
      this.originalRows = structuredClone(this.rows);
    } catch (err) {
      console.error("Failed to load form", err);
    }
  },
  methods: {
    async saveChanges() {
      // Do not post a new version unless the page has changed
      if (!this.isFormChanged()) {
        this.$emit('formUpdated');
        return;
      }

      try {
        this.pending = true;

        // Update the page layout
        await updateFormLayout(this.form.id, this.rows);

        // Upload the thumbnail and set the form's value
        const thmbBlob = await this.getFormAsImage();
        const thmbFile = await uploadAccountFile(thmbBlob);
        await updateFormThumbnail(this.form.id, thmbFile.id);
        this.$emit("formUpdated");
      } catch (err) {
        alert("Failed to save: " + err.message);
      } finally {
        this.pending = false;
      }
    },
    async getFormAsImage() {
      // Deselect component
      this.selectedComponent = null;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1)
      })

      const pageEl = this.$refs.page;
      pageEl.style.boxShadow = 'none'; // disable box shadow to prevent rendering issue
      const canvas = await html2canvas(pageEl);
      pageEl.style.boxShadow = '';

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        })
      })
    },
    onDragStart(component) {
      this.draggingComponent = component;
      console.info("Now dragging", component);
    },
    onDragEnd() {
      this.draggingComponent = null;
      this.rows = this.rows.filter((row) => {
        return row.components.length > 0;
      });
      console.info("Done dragging");
    },
    afterAddToDragAndDrop(event) {
      const component = event.added.element;
      const newRow = {
        key: `row${this.generateRandomNonce()}`,
        singleChild: this.doesComponentHaveToBeAlone(component),
        components: [component],
      };

      this.dragAndDrop = [];
      this.rows.push(newRow);
      console.info("Added a new row", newRow);

      if (component.type == 'IMAGE') {
        this.attemptUpdateImageComponent(component);
      }
    },
    getGroupForRow(row) {
      // Row cannot accept more component
      if (row.singleChild) {
        return null;
      }

      // Not dragging from sidebar, and row can accept more
      if (!this.draggingComponent) {
        return "components";
      }

      // Dragging from sidebar, new component must be alone
      if (this.doesComponentHaveToBeAlone(this.draggingComponent)) {
        return null;
      }

      // Dragging from sidebar, new component may have neighbors
      return "components";
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
    selectComponent(component) {
      this.selectedComponent = component;
    },
    unselectComponent(event) {
      const deselectOn = ["page", "page-row", "row-columns", "drag-and-drop"];
      if (deselectOn.includes(event.target.className)) {
        this.selectedComponent = null;
      }
    },
    updateComponent(component) {
      Object.assign(this.selectedComponent, component);
    },
    deleteComponent(component) {
      this.rows = this.rows.map(row => {
        row.components = row.components.filter(c => c != component);
        return row;
      }).filter(row => row.components.length > 0)
      
      setTimeout(() => {
        this.selectedComponent = null;
      })
    },
    doesComponentHaveToBeAlone(component) {
      if (component.type == 'HEADER') {
        return true;
      }
      if (component.type == 'PARAGRAPH') {
        return true;
      }
      if (component.type == 'IMAGE') {
        return true;
      }
      return false;
    },
    getAllComponents() {
      const components = [];

      for (let row of this.rows) {
        for (let component of row.components) {
          components.push(component);
        }
      }

      return components;
    },
    duplicateRow(row, idx) {
      const clone = structuredClone(row);
      clone.key = `row${this.generateRandomNonce()}`;

      for (let component of clone.components) {
        if (component.type == 'FILE') {
          component.key = `uploadDocument${this.generateRandomNonce()}`
        } else {
          component.key = `doNotImport${this.generateRandomNonce()}`
        }

        component.label += ' (copied)'
      }

      const rows = structuredClone(this.rows);
      rows.splice(idx + 1, 0, clone);
      this.rows = rows;
    },
    deepEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    isFormChanged() {
      return !this.deepEqual(this.rows, this.originalRows)
    },
    async startFormReview() {
      if (confirm('Please only proceed if you are authorized as a form reviewer')) {
        const name = prompt('Enter your name:');
        if (name) {
          await reviewForm(this.form.id, name);
          this.$emit('formUpdated')
        }
      }
    },
    attemptUpdateImageComponent(component) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.click();

      input.onchange = () => {
        const file = input.files[0];
        const fr = new FileReader();
        fr.onload = () => {
          const img = new Image();
          img.onload = () => {
            // Rescale the image
            const width = 256;
            const height = Math.round(256 * img.height / img.width);
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas to a base64 string
            const base64String = canvas.toDataURL('image/png');
            component.data = base64String;
            component.width = width;
            component.height = height;
            this.selectComponent(component);
          }
          img.src = fr.result;
        }
        fr.readAsDataURL(file);
      }
    }
  },
  components: {
    ComponentSidebar,
    PropertiesSidebar,
    draggable: VueDraggableNext,
    HtmlComponent,
  },
  emits: ["formUpdated"],
};
</script>

<style scoped>
.form-editor {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.page-container {
  flex: 1;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 25px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.page-container::-webkit-scrollbar {
  display: none;
}

.page {
  width: 100%;
  max-width: 1200px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 8px #00000022;
  padding: 75px;
  padding-top: 25px;
}

.page-rows {
  margin-left: -50px;
}

.page-row {
  /* border: 1px solid red; */
  display: flex;
  align-items: stretch;
  background: #fff;
  padding: 18px 0px;
}

.row-columns {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-left: -25px;
}

.row-columns > .component {
  margin-left: 25px;
}

.drag-and-drop {
  width: 100%;
  min-height: 150px;
  padding: 20px 0px;
}

.reorder {
  display: block;
  flex-basis: 50px;
  cursor: grab;
  z-index: 50;
  background-image: url("@/assets/reorder.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  opacity: 0;
  transition: ease-in-out 0.1s;
}

.page-row:hover .reorder {
  opacity: 1;
}

.save-button {
  margin-top: 25px;
  width: 200px;
}

.component[selected="true"] {
  outline: 2px dashed #008782;
  background: #00873211;
}

.component[data-conditional="true"] {
  opacity: 0.3;
}
</style>