<template>
  <div id="scroll">
    <AccountNavbar />
    <div class="content">
      <div class="content-header">
        <h1>Admin Portal</h1>
      </div>
      <transition name="appear">
        <div class="content-body" v-if="loaded">
          <AccountListItem v-for="account of accounts" :key="account.id" :account="account"
            @click="openModal('details', account)" />
        </div>
      </transition>
    </div>
    <transition name="modal" mode="out-in">
      <AccountDetailsModal v-if="modal.id == 'details'" :account="modal.data" @closeModal="closeModal" />
    </transition>
  </div>
</template>

<script>
import { postJson, fetchAccountInfo, parallel } from "@/api";
import AccountNavbar from "../components/AccountNavbar.vue";
import AccountListItem from '../components/admin-portal/AccountListItem.vue';
import AccountDetailsModal from '../modals/admin-portal/AccountDetailsModal.vue';

export default {
  name: "AdminPortalView",
  data() {
    return {
      accounts: [],
      modal: {
        id: '',
        data: {},
        open: false
      },
      loaded: false,
    };
  },
  async created() {
    try {
      this.loaded = false;
      await parallel(fetchAccountInfo(), this.fetchListOfAccounts())
    } catch (err) {
      console.error("Failed to fetch list of accounts", err);
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    async fetchListOfAccounts() {
      const json = await postJson('/admin/list-accounts');
      this.accounts = json.accounts;
    },
    openModal(id, data) {
      this.modal = {
        open: true,
        id: id,
        data: data,
      };
    },
    closeModal() {
      this.modal = {
        open: false,
        id: "",
        data: null,
      };
    },
  },
  components: {
    AccountNavbar,
    AccountListItem,
    AccountDetailsModal
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1000px;
  min-height: 700px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 0px 8px #00000011;
  border-radius: 10px;
  margin: auto;
}

.content-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.button-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.button-list .button {
  margin-left: 15px;
}
</style>