<template>
  <div className="checkbox-container" :data-checked="valueCloned" @click="toggleValue" :style="{'--colorFilled': colorFilled}">
    <label className="checkbox-icon" :style="{'--colorLine': colorLine}"></label>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    size: String,
    colorFilled: String,
    colorLine: String,
    value: Boolean
  },
  data() {
    return {
      id: this.generateRandomId(),
      valueCloned: this.value
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.valueCloned = newVal;
      }
    }
  },
  methods: {
    toggleValue() {
      this.valueCloned = !this.valueCloned;
      this.$emit('updateValue', this.valueCloned);
    },
    generateRandomId() {
      let id = "checkbox";
      for (let i = 0; i < 10; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
    getValue() {
      return this.valueCloned;
    },
  },
  emits: ['updateValue']
}
</script>

<style scoped>

.checkbox-container {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: 2px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer !important;
  flex-shrink: 0;
  background-color: #F5F5F5;
}

.checkbox-container:hover {
  background-color: #EEE;
}

.checkbox-container[data-checked="true"] {
  background-color: var(--colorFilled);
}

.checkbox-container[data-checked="true"] .checkbox-icon {
  position: absolute;
  margin: 0;
  right: 14px;
  bottom: 4px;
  width: 8px;
  height: 16px;
  transform-origin: bottom right;
  border-right: 2.5px solid var(--colorLine);
  border-bottom: 2.5px solid var(--colorLine);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

</style>