<template>
  <TextComponent
    ref="component"
    v-if="component.type == 'HEADER' || component.type == 'PARAGRAPH'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
  />
  <DropdownComponent
    ref="component"
    v-else-if="component.type == 'DROPDOWN' || component.type == 'STATE'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
    @onUpdateValue="onUpdateValue"
  />
  <MultiSelectComponent 
    ref="component"
    v-else-if="component.type == 'MULTISELECT'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
    @onUpdateValue="onUpdateValue"
  />
  <SignatureComponent
    ref="component"
    v-else-if="component.type == 'SIGNATURE'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
    @onUpdateValue="onUpdateValue"
    @openSignatureModal="openSignatureModal"
  />
  <FileComponent
    ref="component"
    v-else-if="component.type == 'FILE'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @onUpdateValue="onUpdateValue"
    @deleteComponent="deleteComponent"
  />
  <TextAreaComponent
    ref="component"
    v-else-if="component.type == 'TEXTAREA'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @onUpdateValue="onUpdateValue"
    @deleteComponent="deleteComponent"
  />
  <CheckboxComponent 
    ref="component"
    v-else-if="component.type == 'CHECKBOX'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @onUpdateValue="onUpdateValue"
    @deleteComponent="deleteComponent"
  />
  <SpacerComponent
    ref="component"
    v-else-if="component.type == 'SPACER'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
  />
  <ImageComponent
    ref="component"
    v-else-if="component.type == 'IMAGE'"
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
  />
  <InputComponent
    ref="component"
    v-else
    :component="component"
    :interactable="interactable"
    :showTrash="showTrash"
    @deleteComponent="deleteComponent"
    @onUpdateValue="onUpdateValue"
  />
</template>

<script>
import DropdownComponent from "./DropdownComponent.vue";
import InputComponent from "./InputComponent.vue";
import SignatureComponent from "./SignatureComponent.vue";
import TextComponent from "./TextComponent.vue";
import FileComponent from "./FileComponent.vue";
import TextAreaComponent from './TextAreaComponent.vue';
import MultiSelectComponent from './MultiSelectComponent.vue';
import CheckboxComponent from './CheckboxComponent.vue';
import SpacerComponent from './SpacerComponent.vue';
import ImageComponent from './ImageComponent.vue'

export default {
  name: "HtmlComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  methods: {
    onUpdateValue(value) {
      // Pass updated value up to the form
      this.$emit('onUpdateValue', value);
    },
    updateValue(value) {
      const componentEl = this.$refs.component;
      if (!componentEl) return;
      if (componentEl.updateValue == undefined) return;
      componentEl.updateValue(value);
    },
    deleteComponent() {
      this.$emit("deleteComponent");
    },
    checkComponentIsFilled() {
      const componentEl = this.$refs.component;
      if (componentEl && componentEl.checkComponentIsFilled != undefined) {
        return componentEl.checkComponentIsFilled();
      }
      return true;
    },
    openSignatureModal() {
      this.$emit("openSignatureModal");
    },
    refreshComponent() {
      const componentEl = this.$refs.component;
      if (componentEl && componentEl.refreshComponent != undefined) {
        componentEl.refreshComponent();
      }
    },
  },
  components: {
    InputComponent,
    TextComponent,
    DropdownComponent,
    MultiSelectComponent,
    SignatureComponent,
    FileComponent,
    TextAreaComponent,
    CheckboxComponent,
    SpacerComponent,
    ImageComponent
  },
  emits: ["onUpdateValue", "deleteComponent", "openSignatureModal"],
};
</script>

<style scoped></style>