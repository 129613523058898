<template>
  <div class="multi-action-popup" :style="{ left: `${left}px`, top: `${top}px` }" @mousedown="onDragStart">
    <div class="drag-bar"></div>
    <div class="popup-body">
      <h3>Perform Multi-Action</h3>
      <div class="rows">
        <div class="row">
          <select class="form-control" v-model="type">
            <option value="" disabled selected>Select Type</option>
            <option v-for="tp of types" :key="tp" :value="tp">{{ tp }}</option>
          </select>
          <button class="button primary" @click="updateAnnotationTypes">Update</button>
        </div>
        <div class="row" v-if="hasInputBox()">
          <input type="text" class="form-control" v-model="group" placeholder="Select Group">
          <button class="button primary" @click="updateAnnotationGroup">Update</button>
        </div>
        <div class="row">
          <select class="form-control" v-model="required">
            <option value="" disabled selected>Select Required</option>
            <option value="required">Required</option>
            <option value="optional">Optional</option>
          </select>
          <button class="button primary" @click="updateAnnotationRequired">Update</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiActionPopup',
  props: {
    annotations: Array
  },
  data() {
    return {
      left: window.innerWidth / 2 - 200,
      top: 10,
      initialTop: 0,
      initialLeft: 0,
      dragging: false,
      // Annotations
      annotationClones: [],
      // Update
      type: '',
      types: ['TEXT', 'NUMBER', 'DATE', 'CHECKBOX', 'CIRCLEBOX', 'DROPDOWN', 'EMAIL', 'PHONE', 'SSN', 'SIGNATURE', 'STATE', 'MULTISELECT', 'DRAW_BOX', 'PROVIDER_SIGNATURE', 'PROVIDER_TEXT'],
      group: '',
      required: ''
    }
  },
  watch: {
    annotations: {
      handler(newVal) {
        if (!newVal) {
          return;
        }
        this.annotationClones = structuredClone(newVal)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.onDragStop);
  },
  unmounted() {
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.onDragStop);
  },
  methods: {
    onDragStart(e) {
      if (!e.target.classList.contains('drag-bar')) {
        return;
      }

      this.dragging = true;
      this.initialLeft = e.clientX - this.left;
      this.initialTop = e.clientY - this.top;
      e.preventDefault();
    },
    onDragStop() {
      this.dragging = false;
    },
    onDrag(e) {
      if (this.dragging) {
        this.left = e.clientX - this.initialLeft;
        this.top = e.clientY - this.initialTop;
      }
    },
    updateAnnotationTypes() {
      if (this.type == '') return;

      for (let annot of this.annotationClones) {
        annot.type = this.type;
      }

      this.$emit('updateMultiAnnotations', this.annotationClones);
      alert('Set annotation types to: ' + this.type);
    },
    updateAnnotationGroup() {
      if (this.group == '') return;

      let numUpdated = 0;
      for (let annot of this.annotationClones) {
        if (annot.type == 'CHECKBOX' || annot.type == 'CIRCLEBOX') {
          annot.group = {id: this.group, rule: 'FILL_ONE'}
          numUpdated++;
        }
      }

      this.$emit('updateMultiAnnotations', this.annotationClones);
      alert(`Set annotation group to: ${this.group} (rule: FILL_ONE) for ${numUpdated} annotations`);
    },
    updateAnnotationRequired() {
      if (this.required == '') return;

      for (let annot of this.annotationClones) {
        if (this.required == 'required') {
          annot.optional = false;
        }

        if (this.required == 'optional') {
          annot.optional = true;
        }
      }

      this.$emit('updateMultiAnnotations', this.annotationClones);
      alert(`Set annotations to: ${this.required}`);
    },
    hasInputBox() {
      for (let annot of this.annotationClones) {
        if (annot.type == 'CHECKBOX' || annot.type == 'CIRCLEBOX') {
          return true;
        }
      }

      return false;
    }
  },
  emits: ['updateMultiAnnotations']
}
</script>

<style scoped>
.multi-action-popup {
  position: fixed;
  background: #FFF;
  width: 400px;
  box-shadow: 0px 0px 8px #00000044;
  z-index: 1000;
  border-radius: 10px;
  overflow: hidden;
}

.drag-bar {
  width: 100%;
  height: 25px;
  background: #F2F2F2;
  border-bottom: 1px solid #CCC;
  cursor: move;
}

.popup-body {
  padding: 25px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button {
  flex: 0;
}

.rows {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>