<template>
  <div class="annotation" ref="annot">
    <canvas class="input" ref="canvas" :error="error" :filled="isAnnotationFilled()" @mousedown="onMouseDown" @touchstart.prevent="onMouseDown"></canvas>
    <button class="reset-button" @mousedown.prevent="reset">Reset</button>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash"
      @mousedown.prevent.stop="$emit('deleteAnnotation')">
  </div>
</template>

<script>

const LINE_THICKNESS = 4;

export default {
  name: "DrawBoxAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  data() {
    return {
      value: '',
      error: false,
      mouseDown: false,
      lastPos: null
    }
  },
  mounted() {
    this.registerEvents();
    this.refreshAnnotation();
  },
  methods: {
    updateValue(value) {
      this.value = value;
      this.$emit('onUpdateValue', this.value);
    },
    registerEvents() {
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("touchmove", this.onMouseMove);
      window.addEventListener("touchend", this.onMouseUp);
      window.addEventListener('resize', this.refreshAnnotation);
    },
    unregisterEvents() {
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("touchmove", this.onMouseMove);
      window.removeEventListener("touchend", this.onMouseUp);
      window.removeEventListener('resize', this.refreshAnnotation);
    },
    refreshAnnotation(scale = 3) {
      const canvas = this.$refs.canvas;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      const bb = canvas.getBoundingClientRect();
      canvas.width = bb.width * scale;
      canvas.height = bb.height * scale;

      const img = new Image();
      img.src = this.value;
      img.onload = () => {
        const ratio = canvas.height / img.height;
        context.drawImage(img, 0, 0, img.width * ratio, img.height * ratio);
      }
    },
    onMouseDown(event) {
      if (!this.interactable) {
        return;
      }

      this.mouseDown = true;

      const pos = this.getMousePosOnCanvas(event);
      this.drawPoint(pos);
      this.lastPos = pos;
    },
    onMouseUp() {
      if (!this.mouseDown) return;
      this.mouseDown = false;
      this.lastPos = null;

      // Update value
      const canvas = this.$refs.canvas;
      if (canvas) {
        const data = canvas.toDataURL("image/png");
        this.updateValue(data);
      }
    },
    onMouseMove(event) {
      if (this.mouseDown && this.lastPos != null) {
        const pos = this.getMousePosOnCanvas(event);
        this.drawLine(this.lastPos, pos);
        this.lastPos = pos;
      }
    },
    getMousePosOnCanvas(event) {
      const posHolder = event.touches ? event.touches[0] : event;
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const relX = (posHolder.pageX - rect.left - window.scrollX) / rect.width;
      const relY = (posHolder.pageY - rect.top - window.scrollY) / rect.height;
      return {
        x: Math.round(relX * canvas.width),
        y: Math.round(relY * canvas.height),
      };
    },
    drawPoint(pos) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.fillStyle = "#000";
      context.beginPath();
      context.arc(pos.x, pos.y, LINE_THICKNESS, 0, Math.PI * 2);
      context.fill();
    },
    drawLine(posFrom, posTo) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      const dx = posTo.x - posFrom.x;
      const dy = posTo.y - posFrom.y;
      const dt = Math.sqrt(dx * dx + dy * dy);

      const dir = Math.atan2(dy, dx);
      const dirX = Math.cos(dir);
      const dirY = Math.sin(dir);

      let cx = posFrom.x;
      let cy = posFrom.y;

      context.fillStyle = "#000";
      for (let d = 0; d <= dt; d += LINE_THICKNESS) {
        context.beginPath();
        context.arc(cx, cy, LINE_THICKNESS, 0, Math.PI * 2);
        context.fill();

        cx += dirX * LINE_THICKNESS;
        cy += dirY * LINE_THICKNESS;
      }
    },
    reset() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.value = "";
    },
    isAnnotationFilled() {
      return this.value.length > 0;
    },
    checkAnnotationIsFilled() {
      if (this.annotation.optional) {
        return true;
      }

      if (this.isAnnotationFilled()) {
        return true;
      }

      this.error = true;
      return false;
    }
  },
  emits: ['deleteAnnotation', 'onUpdateValue']
};
</script>

<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 100%;
  height: 100px;
}

.input {
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  background: var(--color-patient-field);
  opacity: 1;
  cursor: pointer;
  border-radius: 0px;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
  /* background: #FFF; */
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.reset-button {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #00000011;
  border: none;
  outline: none;
  letter-spacing: 1px;
  cursor: pointer;
}
</style>