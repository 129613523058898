<template>
  <div id="scroll">
    <AccountNavbar />
    <div class="tip" v-if="showTip">
      <img class="tip-icon" src="@/assets/integration.png" alt="Integration">
      <p class="tip-message">Want to integrate with Practice Fusion? <a href="https://calendly.com/dashquill/15-minute-meeting" target="_blank">Schedule a demo with a DashQuill team member.</a></p>
      <img class="close-tip" src="@/assets/close.png" alt="Close Tip" @click="hideTipForever()">
    </div>
    <div class="content">
      <div class="content-header">
        <h1>Patient Submissions</h1>
        <div class="button-list">
          <button class="button primary small" @click="openModal('intake')">
            Intake Patient
          </button>
        </div>
      </div>
      <div class="submission-header" v-if="store.submissions.length > 0">
        <div class="submission-header-left">
          <PageNumList
            :isLoading="!loaded"
            @startLoading="loaded = false"
            @doneLoading="loaded = true"
          />
        </div>
        <div class="submission-header-right">
          <SearchSubmissions />
        </div>
      </div>
      <transition name="appear">
        <div class="content-body" v-if="loaded">
          <SubmissionListItem
            v-for="submission of store.submissions"
            :key="submission._id"
            :submission="submission"
            @click="openModal('details', submission)"
            @deleteSubmission="openModal('delete', submission)"
          />
          <p v-if="store.submissions.length == 0">
            You have no patient submissions yet. Intake a patient now or post
            your forms online to get started.
          </p>
        </div>
      </transition>
    </div>
    <transition name="modal" mode="out-in">
      <IntakePatientModal
        v-if="modal.id == 'intake'"
        @closeModal="closeModal"
      />
      <SubmissionDetailsModal
        v-else-if="modal.id == 'details'"
        :submission="modal.data"
        @closeModal="closeModal"
      />
      <DeleteSubmissionModal
        v-else-if="modal.id == 'delete'"
        :submission="modal.data"
        @closeModal="closeModal"
      />
      <WelcomeModal v-else-if="modal.id == 'welcome'" @closeModal="closeModal" />
    </transition>
  </div>
</template>

<script>
import AccountNavbar from "../components/AccountNavbar.vue";
import SubmissionListItem from "../components/account-submissions/SubmissionListItem.vue";
import IntakePatientModal from "../modals/account-submissions/IntakePatientModal.vue";
import SubmissionDetailsModal from "../modals/account-submissions/SubmissionDetailsModal.vue";
import DeleteSubmissionModal from "../modals/account-submissions/DeleteSubmissionModal.vue";
import WelcomeModal from "@/modals/account-submissions/WelcomeModal.vue";
import PageNumList from "@/components/account-submissions/PageNumList.vue";
import SearchSubmissions from "../components/account-submissions/SearchSubmissions.vue";
import { loadSubmissionsForCurrentPage, store } from "@/api";

export default {
  name: "AccountSubmissionsView",
  data() {
    return {
      store,
      modal: {
        id: "",
        open: false,
        data: null,
      },
      loaded: false,
      showTip: false
    };
  },
  async created() {
    const query = this.$route.query;
    if (query.welcome != null) {
      this.openModal('welcome');
    }

    // Show tip if it's not hidden
    const showTipStatus = localStorage.getItem('dashquill-show-tip-status');
    this.showTip = showTipStatus != 'hidden';

    try {
      this.loaded = false;
      await loadSubmissionsForCurrentPage();
    } catch (err) {
      console.error("Failed to fetch account forms or presets", err);
      alert('Error: ' + err.message);
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    openModal(id, data) {
      this.modal = {
        open: true,
        id: id,
        data: data,
      };
    },
    closeModal() {
      this.modal = {
        open: false,
        id: "",
        data: null,
      };
    },
    hideTipForever() {
      localStorage.setItem('dashquill-show-tip-status', 'hidden');
      this.showTip = false;
    }
  },
  components: {
    AccountNavbar,
    SubmissionListItem,
    IntakePatientModal,
    SubmissionDetailsModal,
    DeleteSubmissionModal,
    PageNumList,
    SearchSubmissions,
    WelcomeModal
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1000px;
  min-height: 700px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 0px 8px #00000011;
  border-radius: 10px;
  margin: auto;
}

.content-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.button-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.button-list .button {
  margin-left: 20px;
}

.submission-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  justify-content: center;
}

.submission-header-left {
  flex-basis: 70%;
  max-width: 70%;
}

.submission-header-right {
  flex-basis: 30%;
  max-width: 30%;
}

.tip {
  max-width: 1000px;
  margin: auto;
  padding: 15px;
  background: #fff;
  color: #000;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 8px #00000022;
}

.tip-icon {
  height: 30px;
  margin-right: 12px;
}

.tip-message {
  flex: 1;
}
.close-tip {
  height: 16px;
  cursor: pointer;
}
</style>