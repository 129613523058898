<template>
  <div id="scroll">
    <AccountNavbar />
    <div class="content">
      <div class="content-header">
        <h1>Account Settings</h1>
      </div>

      <div class="setting-row">
        <label>New Submission Email</label>
        <label class="switch">
          <input type="checkbox" v-model="this.newSubmissionEmailNotify" @change="sendNotificationStore()" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="setting-row">
        <label>Submission Deleted Email</label>
        <label class="switch">
          <input type="checkbox" v-model="this.deleteSubmissionEmailNotify" @change="sendNotificationStore()" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="setting-row">
        <label>Open chart after import</label>
        <label class="switch">
          <input type="checkbox" v-model="this.skipImportRefresh" @change="sendNotificationStore()" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="setting-row">
        <label>Secondary Logins (Users)</label>
        <a href="#users" @click.prevent="openModal('user-list')">{{1 + store.users.length}} users</a>
      </div>

      <!-- <div class="setting-row">
        <label><s>Legacy Import</s></label>
        <label class="switch">
          <input type="checkbox" v-model="this.legacyImport" @change="sendNotificationStore()" />
          <span class="slider round"></span>
        </label>
      </div> -->
    </div>
    <transition name="modal" mode="out-in">
      <UserListModal v-if="modal.id == 'user-list'" :account="store.account" :users="store.users" @closeModal="closeModal" @openModal="openModal" />
      <CreateUserModal v-else-if="modal.id == 'create-user'" @closeModal="closeModal" />
      <DeleteUserModal v-else-if="modal.id == 'delete-user'" :user="modal.data" @closeModal="closeModal" />
    </transition>
  </div>
</template>

<script>
import UserListModal from "@/modals/account-settings/UserListModal.vue";
import CreateUserModal from "@/modals/account-settings/CreateUserModal.vue"
import DeleteUserModal from "@/modals/account-settings/DeleteUserModal.vue";
import AccountNavbar from "../components/AccountNavbar.vue";
import { store, fetchAccountInfo, setAccountPreferences, fetchUserList } from "@/api";

export default {
  name: "AccountSettingsView",
  data() {
    return {
      newSubmissionEmailNotify: false,
      deleteSubmissionEmailNotify: false,
      legacyImport: false,
      skipImportRefresh: false,
      modal: {
        id: "",
        open: false,
        data: null,
      },
      store
    };
  },
  async created() {
    this.loadAccountInfo();
    fetchUserList(); // Load users to store
  },
  methods: {
    async loadAccountInfo() {
      await fetchAccountInfo(); // Load account to store
      this.newSubmissionEmailNotify = store.account.newSubmissionEmailNotify;
      this.deleteSubmissionEmailNotify =
        store.account.deleteSubmissionEmailNotify;
      this.legacyImport = store.account.legacyImport;
      this.skipImportRefresh = store.account.skipImportRefresh;
    },
    async sendNotificationStore() {
      await setAccountPreferences({
        newSubmissionEmailNotify: this.newSubmissionEmailNotify,
        deleteSubmissionEmailNotify: this.deleteSubmissionEmailNotify,
        legacyImport: this.legacyImport,
        skipImportRefresh: this.skipImportRefresh
      });
    },
    openModal(id, data) {
      this.modal = {
        open: true,
        id: id,
        data: data,
      };
    },
    closeModal() {
      this.modal = {
        open: false,
        id: "",
        data: null,
      };
    },
  },
  components: { AccountNavbar, UserListModal, CreateUserModal, DeleteUserModal },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1000px;
  min-height: 700px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 0px 8px #00000011;
  border-radius: 10px;
  margin: auto;
}

.content-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.setting-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  min-height: 35px;
  align-items: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>