<template>
  <div class="form-editor">
    <AnnotationSidebar @onDragStart="onDragStart" @onDragEnd="onDragEnd" />
    <div class="page-container">
      <transition name="appear" mode="out-in">
        <div class="page-list">
          <div class="page" v-for="page of pages" :key="page.pageNum" @drop="onDropAnnot(page, $event)"
            @dragstart.prevent="" @dragover.prevent="" @mousemove="onMouseMove($event)"
            @mouseup.prevent="onMouseUp($event)" @mousedown.prevent="onMouseDown(page, $event)" @mouseover="onMouseOver"
            @mouseleave="onMouseLeave" @contextmenu.prevent="">
            <img class="page-background" :src="page.pageSrc" :alt="`Page ${page.pageNum}`" @load="onPageLoaded" />
            <HtmlAnnotation ref="annotations" v-for="annot of page.annotations" :key="annot" :annotation="annot"
              :interactable="false" :showTrash="annot == selectedAnnot"
              :selected="annot == selectedAnnot || multiSelectedAnnots.includes(annot)" :style="{
                background: annot.background ? `#FFF` : '',
                left: `${annot.x * 100}%`,
                bottom: `${annot.y * 100}%`,
                width: `${annot.width * 100}%`,
                height: `${annot.height * 100}%`,
              }" @deleteAnnotation="deleteAnnotation(annot)" />
            <div class="zones" v-if="draggingAnnot && page.zones.length > 0">
              <div class="zone" v-for="zone of page.zones" :key="zone" :style="{
                left: `${zone.x * 100}%`,
                bottom: `${zone.y * 100}%`,
                width: `${zone.width * 100}%`,
                height: `${zone.height * 100}%`,
                display: shouldShowZone(page, zone) ? '' : 'none'
              }"></div>
            </div>

          </div>
          <button class="button primary save-button" @click.prevent="saveChanges()">
            <img v-if="pending" src="/loading.svg" alt="loading" class="loading" />
            <span v-else>Save Changes</span>
          </button>
          <a href="#" v-if="form.isUnderReview && !isFormChanged()" style="margin-top: 10px" @click="startFormReview">Complete Form Review</a>
        </div>
      </transition>
    </div>
    <PropertiesSidebar :annotation="selectedAnnot" :emrJson="emrJson" :allAnnotations="getAllAnnotations()"
      @updateAnnotation="updateAnnotation" @updateGroupRule="updateGroupRule" />
    <MultiActionPopup v-if="multiSelectedAnnots.length > 0" :annotations="multiSelectedAnnots"
      @updateMultiAnnotations="updateMultiAnnotations" />
  </div>
</template>

<script>
import { updateFormAnnotations, reviewForm } from "@/api";
import AnnotationSidebar from "./AnnotationSidebar.vue";
import PropertiesSidebar from "./PropertiesSidebar.vue";
import HtmlAnnotation from "../form-annotations-pdf/HtmlAnnotation.vue";
import MultiActionPopup from './MultiActionPopup.vue'

const EDGE_SIZE = 5;

export default {
  name: "FormEditorPdf",
  props: {
    form: Object,
    emrJson: Object,
  },
  data() {
    return {
      pages: [],
      pending: false,
      draggingAnnot: null,
      draggingMeta: null,
      selectedAnnot: null,
      movingAnnot: null,
      movingMeta: null,
      resizingAnnot: null,
      resizingMeta: null,
      cursor: "default",
      selectedPage: null,
      selectedPageEl: null,
      // Power user fields
      lastClonedAnnot: null,
      originalAnnotations: [],
      multiSelectedAnnots: []
    };
  },
  async mounted() {
    const publicImagesPath = process.env.VUE_APP_PUBLIC_IMAGES;

    let pageNum = 1;
    for (let filePagePath of this.form.filePagePaths) {
      const formFilePages = this.form.filePages || [];
      const pageInForm = formFilePages.find((pg) => pg.page == pageNum);
      const annotations = pageInForm ? pageInForm.pageAnnotations : [];

      const page = {
        pageNum: pageNum,
        pageSrc: publicImagesPath + filePagePath,
        annotations: annotations,
        zones: [],
      };
      this.pages.push(page);
      pageNum++;
      this.processPageZones(page);
      // await this.detectZones(page);
    }

    try {
      this.originalAnnotations = structuredClone(this.getAllAnnotations());
    } catch (err) {
      console.error('Failed to clone original annotations for saving. Will save no matter what')
    }

    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("keydown", this.onKeyDown);
  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    async saveChanges() {

      // Do not post a new form version if no changes
      if (!this.isFormChanged()) {
        this.$emit('formUpdated');
        return;
      }

      try {
        this.pending = true;

        const ROW_THRESH = 0.01;
        const filePages = this.pages.map((pg) => {
          return {
            page: pg.pageNum,
            pageAnnotations: pg.annotations
              .sort((a, b) => {
                // Sort correctly so tab is nice
                if (Math.abs(a.y - b.y) < ROW_THRESH) {
                  return a.x - b.x;
                }
                return b.y - a.y;
              })
              .map((annot) => {
                // Bound to page
                if (annot.width > 1) annot.width = 1;
                if (annot.height > 1) annot.height = 1;
                if (annot.x < 0) annot.x = 0;
                if (annot.y < 0) annot.y = 0;
                if (annot.x + annot.width > 1) annot.x = 1 - annot.width;
                if (annot.y + annot.height > 1) annot.y = 1 - annot.height;
                return annot;
              }),
          };
        });

        await updateFormAnnotations(this.form.id, filePages);
        this.$emit("formUpdated");
      } catch (err) {
        alert("Failed to save: " + err.message);
      } finally {
        this.pending = false;
      }
    },
    onMouseOver() {
      this.isMouseOver = true;
    },
    onMouseLeave() {
      this.isMouseOver = false;
    },
    onMoveStart(annot, event) {
      const pos = this.getRelativePosOnImage(event);
      const offX = annot.x - pos.x;
      const offY = annot.y - pos.y;
      this.movingAnnot = annot;
      this.movingMeta = { x: offX, y: offY };
      this.selectedAnnot = annot; // select it too
    },
    onMouseMove(event) {
      document.body.style.cursor = "";

      if (this.selectedAnnot) {
        const annot = this.selectedAnnot;
        const thresh = EDGE_SIZE / this.selectedPageEl.offsetWidth; // 10px
        const pos = this.getRelativePosOnImage(event);
        const edge = this.getEdgeUnderRelativePos(annot, pos, thresh);
        switch (edge) {
          case "TOP":
          case "BOTTOM":
            document.body.style.cursor = "ns-resize";
            break;
          case "LEFT":
          case "RIGHT":
            document.body.style.cursor = "ew-resize";
            break;
          case "INSIDE":
            document.body.style.cursor = "grab";
            break;
          case "TOPRIGHT":
          case "BOTTOMLEFT":
            document.body.style.cursor = "nesw-resize";
            break;
          case "TOPLEFT":
          case "BOTTOMRIGHT":
            document.body.style.cursor = "nwse-resize";
            break;
        }
      }

      if (this.movingAnnot) {
        const annot = this.movingAnnot;
        const meta = this.movingMeta;
        const pos = this.getRelativePosOnImage(event);
        annot.x = pos.x + meta.x;
        annot.y = pos.y + meta.y;
        document.body.style.cursor = "grabbing";

        // Disallow moving outside of page
        if (annot.x < 0) {
          annot.x = 0;
        }
        if (annot.y < 0) {
          annot.y = 0;
        }
        if (annot.x + annot.width > 1) {
          annot.x = 1 - annot.width;
        }
        if (annot.y + annot.height > 1) {
          annot.y = 1 - annot.height;
        }
      }

      if (this.resizingAnnot) {
        const annot = this.resizingAnnot;
        const meta = this.resizingMeta;
        const pos = this.getRelativePosOnImage(event);
        let temp;

        switch (meta.edge) {
          case "RIGHT":
            annot.width = Math.max(0, pos.x - annot.x);
            document.body.style.cursor = "ew-resize";
            break;
          case "LEFT":
            temp = Math.min(pos.x, annot.x + annot.width);
            annot.width = annot.x + annot.width - temp;
            annot.x = temp;
            document.body.style.cursor = "ew-resize";
            break;
          case "TOP":
            annot.height = Math.max(0, pos.y - annot.y);
            document.body.style.cursor = "ns-resize";
            break;
          case "BOTTOM":
            temp = Math.min(pos.y, annot.y + annot.height);
            annot.height = annot.y + annot.height - temp;
            annot.y = temp;
            document.body.style.cursor = "ns-resize";
            break;
          case "TOPRIGHT":
            annot.width = Math.max(0, pos.x - annot.x);
            annot.height = Math.max(0, pos.y - annot.y);
            document.body.style.cursor = "nesw-resize";
            break;
          case "TOPLEFT":
            temp = Math.min(pos.x, annot.x + annot.width);
            annot.width = annot.x + annot.width - temp;
            annot.x = temp;
            annot.height = Math.max(0, pos.y - annot.y);
            document.body.style.cursor = "nwse-resize";
            break;
          case "BOTTOMRIGHT":
            temp = Math.min(pos.y, annot.y + annot.height);
            annot.width = Math.max(0, pos.x - annot.x);
            annot.height = annot.y + annot.height - temp;
            annot.y = temp;
            document.body.style.cursor = "nwse-resize";
            break;
          case "BOTTOMLEFT":
            temp = Math.min(pos.y, annot.y + annot.height);
            annot.height = annot.y + annot.height - temp;
            annot.y = temp;
            temp = Math.min(pos.x, annot.x + annot.width);
            annot.width = annot.x + annot.width - temp;
            annot.x = temp;
            document.body.style.cursor = "nesw-resize";
            break;
        }
      }
    },
    onMouseUp() {
      this.movingAnnot = null;
      this.resizingAnnot = null;
    },
    onDragStart(annotation, meta) {
      this.draggingAnnot = annotation;
      this.draggingMeta = meta;
    },
    onDragEnd() {
      this.draggingAnnot = null;
      this.draggingMeta = null;
    },
    onDropAnnot(page, event) {
      if (!this.draggingAnnot) {
        return;
      }

      // Get some sizing for the page
      const pageEl = this.getPageFromEvent(event);
      const pageWidth = pageEl.offsetWidth;
      const pageHeight = pageEl.offsetHeight;

      // Get the annotation and meta
      const annot = this.draggingAnnot;
      const meta = this.draggingMeta;

      // Resize annotation correctly
      const pos = this.getRelativePosOnImage(event);
      annot.x = pos.x - meta.x / pageWidth;
      annot.y = pos.y - meta.y / pageHeight;
      annot.width = meta.w / pageWidth;
      annot.height = meta.h / pageHeight;

      // Snap to zone, if there is one
      const zone = page.zones.find(z => {
        if (!this.isAnnotationOverZone(this.draggingAnnot, z)) {
          return false;
        }

        if (!this.shouldShowZone(page, z)) {
          return false;
        }

        return true;
      });
      if (zone) {
        annot.x = zone.x;
        annot.y = zone.y;
        annot.width = zone.width;
      }

      // Push annotation to page
      page.annotations.push(this.draggingAnnot);
      console.info("Added new annotation", this.draggingAnnot, pos);

      // If this is a signature annotation, open signature modal
      if (this.draggingAnnot.type == "PROVIDER_SIGNATURE") {
        this.openModal("signature", this.draggingAnnot);
      }

      if (this.draggingAnnot.type == "PROVIDER_TEXT") {
        this.openModal("text", this.draggingAnnot);
      }

      this.draggingAnnot = null;
    },
    getRelativePosOnImage(event) {
      const page = this.getPageFromEvent(event);
      const rect = page.getBoundingClientRect();
      const x = (event.pageX - rect.left) / rect.width;
      const y = 1 - (event.pageY - rect.top) / rect.height;
      return { x, y };
    },
    deleteAnnotation(annot) {
      console.log("Deleting annotation", annot);
      this.pages = this.pages.map((pg) => {
        pg.annotations = pg.annotations.filter((ant) => ant != annot);
        return pg;
      });
    },
    onMouseDown(page, event) {
      // Get click information
      const pageEl = this.getPageFromEvent(event);
      const pos = this.getRelativePosOnImage(event);

      if (!event.shiftKey) {
        // No shift, wipe the list
        this.multiSelectedAnnots = [];
      }

      // Figure out which annotation we clicked on
      for (let annot of page.annotations) {
        // Ignore annotation if we're nowhere near it
        const thresh = EDGE_SIZE / pageEl.offsetWidth;
        const edge = this.getEdgeUnderRelativePos(annot, pos, thresh);
        if (edge == "OUTSIDE") continue;

        // Forget about last cloned annotation every time we click
        this.lastClonedAnnot = null;

        if (event.shiftKey) {
          // Multiselect, push annot to list          
          this.multiSelectedAnnots = this.multiSelectedAnnots.filter(a => a != annot)
          this.multiSelectedAnnots.push(annot);

          // If we were already selecting a different, annot push that as well
          if (this.selectedAnnot && annot != this.selectedAnnot) {
            this.multiSelectedAnnots.push(this.selectedAnnot);
          }

          // If we have more than 1 selected, stop here and clear single select
          if (this.multiSelectedAnnots.length > 1) {
            this.selectedAnnot = null;
            return;
          }
        }

        if (event.button == 2) {
          // Clone and select annotation
          const clone = structuredClone(annot);
          clone.key = `doNotImport${this.generateRandomNonce()}`;
          clone.unique = true;
          page.annotations.unshift(clone);
          this.lastClonedAnnot = annot;
          this.selectedAnnot = clone;
          this.selectedPage = page;
          this.selectedPageEl = pageEl;

          // Mark as moving
          const offX = clone.x - pos.x;
          const offY = clone.y - pos.y;
          this.movingAnnot = clone;
          this.movingMeta = { x: offX, y: offY };
          return;
        }

        if (annot == this.selectedAnnot) {
          // Handle transformation
          if (edge == "INSIDE") {
            const offX = annot.x - pos.x;
            const offY = annot.y - pos.y;
            this.movingAnnot = annot;
            this.movingMeta = { x: offX, y: offY };
          } else {
            event.preventDefault();
            this.resizingAnnot = annot;
            this.resizingMeta = { edge };
          }
        } else {
          // Select annotation
          this.selectedAnnot = annot;
          this.selectedPage = page;
          this.selectedPageEl = pageEl;

          // Move annotation to front
          const annotIndex = page.annotations.findIndex((ant) => ant == annot);
          page.annotations[annotIndex] = page.annotations[0];
          page.annotations[0] = annot;
        }
        return;
      }

      // Handle unselect
      const deselectOn = ["page", "page-background", "page-container"];
      if (deselectOn.includes(event.target.className)) {
        this.selectedAnnot = null;
        this.selectedPage = null;
        this.selectedPageEl = null;
        return;
      }
    },
    getEdgeUnderRelativePos(annot, pos, thresh) {
      const left = annot.x;
      const bottom = annot.y;
      const right = annot.x + annot.width;
      const top = annot.y + annot.height;

      if (
        pos.x < left - thresh ||
        pos.x > right + thresh ||
        pos.y > top + thresh ||
        pos.y < bottom - thresh
      ) {
        return "OUTSIDE";
      }

      if (pos.x <= left && pos.y >= top) return "TOPLEFT";
      if (pos.x <= left && pos.y <= bottom) return "BOTTOMLEFT";
      if (pos.x >= right && pos.y >= top) return "TOPRIGHT";
      if (pos.x >= right && pos.y <= bottom)
        return "BOTTOMRIGHT";
      if (pos.x <= left) return "LEFT";
      if (pos.x >= right) return "RIGHT";
      if (pos.y >= top) return "TOP";
      if (pos.y <= bottom) return "BOTTOM";
      return "INSIDE";
    },
    getPageFromEvent(event) {
      let el = event.target;
      while (el && el.className != "page") {
        el = el.parentElement;
      }

      return el;
    },
    updateAnnotation(annot) {
      Object.assign(this.selectedAnnot, annot);
    },
    updateMultiAnnotations(annots) {
      const allAnnotations = this.getAllAnnotations();
      for (let annot of annots) {
        const an = allAnnotations.find(a => a.key == annot.key);
        Object.assign(an, annot);
      }
    },
    onWindowResize() {
      const annotationElements = this.$refs.annotations;
      if (!annotationElements) return;
      for (let annotationEl of annotationElements) {
        annotationEl.refreshAnnotation();
      }
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
    onPageLoaded() {
      const annotations = this.$refs.annotations;
      if (!annotations) return;

      for (let annot of annotations) {
        annot.refreshAnnotation();
      }
    },
    onKeyDown(event) {
      const code = event.code;

      if (!this.isMouseOver) {
        return;
      }

      // Clone annotation, offset by last move
      if (code == "KeyD") {
        if (this.lastClonedAnnot && this.selectedAnnot && this.selectedPage) {
          event.preventDefault();
          const clone = structuredClone(this.selectedAnnot);
          clone.key = `doNotImport${this.generateRandomNonce()}`;
          clone.unique = true;
          this.selectedPage.annotations.unshift(clone);

          // Move by offset
          const offX = clone.x - this.lastClonedAnnot.x;
          const offY = clone.y - this.lastClonedAnnot.y;
          clone.x += offX;
          clone.y += offY;

          this.lastClonedAnnot = this.selectedAnnot;
          this.selectedAnnot = clone;
        }
      }

      if (code == "ArrowDown" && this.selectedAnnot) {
        event.preventDefault();
        this.selectedAnnot.y -= 0.0005;
      }

      if (code == "ArrowRight" && this.selectedAnnot) {
        event.preventDefault();
        this.selectedAnnot.x += 0.0005;
      }

      if (code == "ArrowLeft" && this.selectedAnnot) {
        event.preventDefault();
        this.selectedAnnot.x -= 0.0005;
      }

      if (code == "ArrowUp" && this.selectedAnnot) {
        event.preventDefault();
        this.selectedAnnot.y += 0.0005;
      }

      if (code == "Backspace" || code == "Delete") {
        if (this.selectedAnnot && this.selectedPage) {
          this.selectedPage.annotations = this.selectedPage.annotations.filter(
            (annot) => {
              return annot != this.selectedAnnot;
            }
          );
          this.selectedAnnot = null;
          this.selectedPage = null;
          this.selectedPageEl = null;
          event.preventDefault();
        }
      }
    },
    openModal(id, data) {
      this.$emit("openModal", id, data);
    },
    processPageZones(page) {
      const worker = new Worker('/workers/pdf-zones.worker.js');

      // When worker returns zones, read it
      worker.onmessage = (e) => {
        const data = e.data;
        console.log(data);
        page.zones = data.zones;
      }

      // When worker fails, log it
      worker.onerror = (err) => {
        console.error(err);
      }

      // Fetch page image and post it over to worker
      this.getImageFromSrc(page.pageSrc).then(image => {
        const pixels = this.getPixelsFromImage(image);
        worker.postMessage({ pixels: pixels, width: image.width, height: image.height });
      });
    },
    getPixelsFromImage(image) {
      // Render image on canvas
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      // Return canvas pixel data
      const canvasData = context.getImageData(0, 0, image.width, image.height);
      return canvasData.data;
    },
    async getImageFromSrc(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous'; // Required for cross-origin requests
        image.src = src;

        image.onload = () => {
          resolve(image);
        }

        image.onerror = () => {
          reject();
        }
      })
    },
    shouldShowZone(page, zone) {
      // Do not show zones that have annotations overlapping them
      const annot = page.annotations.find(annot => this.isAnnotationOverZone(annot, zone));
      if (annot != null) {
        return false;
      }

      return true;
    },
    isAnnotationOverZone(annot, zone) {
      if (zone.x + zone.width < annot.x) return false;
      if (zone.x > annot.x + annot.width) return false;
      if (zone.y + zone.height < annot.y) return false;
      if (zone.y > annot.y + annot.height) return false;
      return true;
    },
    getAllAnnotations() {
      const annotations = [];

      for (let page of this.pages) {
        for (let annotation of page.annotations) {
          annotations.push(annotation);
        }
      }

      return annotations;
    },
    updateGroupRule(id, rule) {
      for (let annot of this.getAllAnnotations()) {
        if (annot.group == null) continue;
        if (annot.group.id !== id) continue;
        annot.group.rule = rule;
      }
    },
    deepEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    isFormChanged() {
      const annotations = this.getAllAnnotations();
      return !this.deepEqual(annotations, this.originalAnnotations);
    },
    async startFormReview() {
      if (confirm('Please only proceed if you are authorized as a form reviewer')) {
        const name = prompt('Enter your name:');
        if (name) {
          await reviewForm(this.form.id, name);
          this.$emit('formUpdated')
        }
      }
    }
  },
  components: { AnnotationSidebar, PropertiesSidebar, HtmlAnnotation, MultiActionPopup },
  emits: ["formUpdated", "openModal"],
};
</script>

<style scoped>
.form-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sidebar {
  height: 100%;
  flex-basis: 350px;
  overflow-y: auto;
  padding: 25px;
  padding-top: 100px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.page-container {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  padding: 25px 0px;
  padding-top: 100px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.page-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.page-container::-webkit-scrollbar {
  display: none;
}

.list {
  width: 100%;
  height: 1900px;
  background: #fff;
}

.page {
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 8px #00000022;
  margin-bottom: 20px;
}

.page-background {
  width: 100%;
  display: block;
  pointer-events: none;
  user-select: none;
}

.loading-big {
  display: block;
  margin: auto;
  margin-top: 100px;
  width: 200px;
}

.field-item {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  cursor: grab;
}

.annotation {
  position: absolute;
  z-index: 99;
}

.save-button {
  margin-top: 5px;
  width: 200px;
}

.annotation[selected="true"] {
  outline: 1.5px dashed #008782;
  background: #00873211;
  z-index: 100;
}

.zones {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.zone {
  position: absolute;
  background: linear-gradient(#25883b10, #25883b25);
  pointer-events: none;
}
</style>