<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 550px">
      <div class="card-header">
        <h1 contenteditable="true" ref="name" @blur="onChangeName" @keypress.enter.prevent="$event.target.blur()">
          {{ form.name }}
        </h1>
        <p>
          <a :href="getURL()" target="_blank">{{ getURL() }}</a>
        </p>
      </div>
      <form class="card-body">
        <div class="form-preview" :style="{backgroundImage: `url(${getPreviewURL()})`}"></div>
      </form>
      <div class="card-footer">
        <button class="button primary" @click="openForm()">
          Open Form Editor
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { updateFormName } from "@/api";

export default {
  name: "CreateFormModal",
  props: {
    form: Object,
  },
  methods: {
    closeModal(event) {
      const nameElement = this.$refs.name;
      if (nameElement && nameElement == document.activeElement) {
        return;
      }

      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
    async onChangeName() {
      const nameElement = this.$refs.name;
      if (!nameElement) return;
      const name = nameElement.textContent;

      try {
        console.info('Updating form name to: ' + name);
        await updateFormName(this.form.id, name);
      } catch (err) {
        console.error("Failed to update form name");
      }
    },
    getURL() {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const account = this.form.ownerNameUnique;
      const url = this.form.url;
      return `${protocol}//${host}/${account}/${url}`;
    },
    getPreviewURL() {
      const accountFilesPath = process.env.VUE_APP_ACCOUNT_FILES;
      const publicImagesPath = process.env.VUE_APP_PUBLIC_IMAGES;
      const filePagePaths = this.form.filePagePaths;

      if (this.form.thumbnail) {
        return `${accountFilesPath}/${this.form.ownerId}/${this.form.thumbnail}.png`
      }

      if (!filePagePaths || filePagePaths.length < 1) {
        return 'example-form.png'
      }

      return publicImagesPath + filePagePaths[0];
    },
    openForm() {
      this.$router.push(`/forms/${this.form.id}`);
    },
  },
  emits: ["closeModal", "updateFormName"],
};
</script>

<style scoped>
.form-preview {
  display: block;
  width: 100%;
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  aspect-ratio: 1.5;
  border: 10px solid #e1e6ea;
  border-bottom: 1px solid #00000022;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

a {
  word-wrap: break-word;
}
</style>