<template>
  <div class="modal" @mousedown="closeModal" @touchmove.prevent="">
    <div class="card" style="max-width: 600px">
      <div class="card-header">
        <h1>{{ getModalTitle() }}</h1>
        <p>Please use your mouse or finger (if on a mobile device) to sign.</p>
      </div>
      <form class="card-body" @submit.prevent="">
        <canvas
          class="canvas"
          ref="canvas"
          @touchstart.prevent="onMouseDown"
          @mousedown.prevent="onMouseDown"
        ></canvas>
      </form>
      <div class="card-footer">
        <div class="button-list">
          <button class="button" @click.prevent="reset">Reset</button>
          <button class="button submit" @click.prevent="submitSignature">
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const LINE_THICKNESS = 4;
const CANVAS_WIDTH = 500;
const CANVAS_HEIGHT = 250;

export default {
  name: "SignatureModal",
  props: {
    lastSignature: String,
    lastSignatureType: String,
  },
  data() {
    return {
      pending: false,
      mouseDown: false,
      lastPos: null,
      hasDrawn: false,
    };
  },
  mounted() {
    this.setupCanvas();
    this.registerEvents();
    this.adjustModalPositionAndScale(); // Initial adjustment

    // Render last signature if needed
    if (this.lastSignature) {
      const image = new Image();
      image.src = this.lastSignature;
      image.onload = () => {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0);
        this.hasDrawn = true;
      };
    }
  },
  unmounted() {
    this.unregisterEvents();
  },
  methods: {
    adjustModalPositionAndScale() {
      // Ensure the modal is positioned absolutely to be freely positioned
      this.$el.style.position = 'absolute';
      this.$el.style.width = `100vw`
      this.$el.style.height = '100vh'

      // Get the dimensions of the viewport
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Get the current scroll position
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      // Initially, let's not scale the modal and attempt to fit it by position only
      let scale = 1;

      // Calculate the position for the modal to be centered in the current view
      let modalWidth = this.$el.offsetWidth * scale; // Consider scaling
      let modalHeight = this.$el.offsetHeight * scale; // Consider scaling

      // If the modal is larger than the viewport, adjust scale to fit
      if (modalWidth > viewportWidth || modalHeight > viewportHeight) {
        scale = Math.max(viewportWidth / modalWidth, viewportHeight / modalHeight); // Add some padding
        modalWidth *= scale;
        modalHeight *= scale;
        this.$el.style.transform = `scale(${scale})`;
        this.$el.style.transformOrigin = 'top left';
      } else {
        this.$el.style.transform = '';
        this.$el.style.transformOrigin = '';
      }

      // Calculate centered position, considering the scale
      let left = scrollX + (viewportWidth - modalWidth) / 2;
      let top = scrollY + (viewportHeight - modalHeight) / 2;

      // Apply the calculated position
      this.$el.style.left = `${left}px`;
      this.$el.style.top = `${top}px`;
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }

      this.$emit("closeModal");
    },
    setupCanvas() {
      const canvas = this.$refs.canvas;
      canvas.width = CANVAS_WIDTH;
      canvas.height = CANVAS_HEIGHT;
      canvas.style.aspectRatio = CANVAS_WIDTH / CANVAS_HEIGHT;
    },
    registerEvents() {
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("touchmove", this.onMouseMove);
      window.addEventListener("touchend", this.onMouseUp);
      // window.addEventListener("resize", this.onWindowResize);
      window.addEventListener("resize", this.adjustModalPositionAndScale);
      window.addEventListener("scroll", this.adjustModalPositionAndScale, true);
    },
    unregisterEvents() {
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("touchmove", this.onMouseMove);
      window.removeEventListener("touchend", this.onMouseUp);
      // window.removeEventListener("resize", this.onWindowResize);
      window.removeEventListener("resize", this.adjustModalPositionAndScale);
      window.removeEventListener(
        "scroll",
        this.adjustModalPositionAndScale,
        true
      );
    },
    onMouseDown(event) {
      this.mouseDown = true;
      this.hasDrawn = true;

      const pos = this.getMousePosOnCanvas(event);
      this.drawPoint(pos);
      this.lastPos = pos;
    },
    onMouseUp() {
      this.mouseDown = false;
      this.lastPos = null;
    },
    onMouseMove(event) {
      if (this.mouseDown && this.lastPos != null) {
        const pos = this.getMousePosOnCanvas(event);
        this.drawLine(this.lastPos, pos);
        this.lastPos = pos;
      }
    },
    getMousePosOnCanvas(event) {
      const posHolder = event.touches ? event.touches[0] : event;
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const relX = (posHolder.pageX - rect.left - window.scrollX) / rect.width;
      const relY = (posHolder.pageY - rect.top - window.scrollY) / rect.height;
      return {
        x: Math.round(relX * CANVAS_WIDTH),
        y: Math.round(relY * CANVAS_HEIGHT),
      };
    },
    drawPoint(pos) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.fillStyle = "#000";
      context.beginPath();
      context.arc(pos.x, pos.y, LINE_THICKNESS, 0, Math.PI * 2);
      context.fill();
    },
    drawLine(posFrom, posTo) {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      const dx = posTo.x - posFrom.x;
      const dy = posTo.y - posFrom.y;
      const dt = Math.sqrt(dx * dx + dy * dy);

      const dir = Math.atan2(dy, dx);
      const dirX = Math.cos(dir);
      const dirY = Math.sin(dir);

      let cx = posFrom.x;
      let cy = posFrom.y;

      context.fillStyle = "#000";
      for (let d = 0; d <= dt; d += LINE_THICKNESS) {
        context.beginPath();
        context.arc(cx, cy, LINE_THICKNESS, 0, Math.PI * 2);
        context.fill();

        cx += dirX * LINE_THICKNESS;
        cy += dirY * LINE_THICKNESS;
      }
    },
    submitSignature() {
      if (!this.hasDrawn) {
        this.$emit("submitSignature", "");
        return;
      }

      const canvas = this.$refs.canvas;
      const base64Image = canvas.toDataURL("image/png");
      this.$emit("submitSignature", base64Image);
    },
    reset() {
      this.hasDrawn = false;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    },
    getModalTitle() {
      switch (this.lastSignatureType) {
        case "signature-patient":
          return "Patient Signature";
        case "signature-guardian":
          return "Parent or Guardian Signature";
        case "signature-witness":
          return "Witness Signature";
        case "initials-patient":
          return "Patient Initials";
        case "initials-guardian":
          return "Parent or Guardian Initials";
        default:
          return "Sign Here";
      }
    },
  },
  emits: ["submitSignature", "closeModal"],
};
</script>

<style scoped>
.modal {
  background: #131b28c8;
  overflow: visible; /* Ensure transformed modal is visible */
}

.card {
  border-radius: 0px;
}

.button.submit {
  padding: 15px 25px;
  background: var(--color-patient);
  color: #fff;
}

.button.submit:disabled {
  background: #535353;
}

.canvas {
  width: 100%;
  background: var(--color-patient-field);
  border: 1px solid #00000022;
  cursor: pointer;
}
</style>