<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 450px;">
      <div class="card-header">
        <h1>Create New Preset</h1>
        <p>
          Select forms that you want to group together. This group can be added
          to your website.
        </p>
      </div>
      <form class="card-body" @submit.prevent="createPreset">
        <div class="form-group">
          <label for="name" class="form-label">Preset Name</label>
          <input
            id="name"
            type="text"
            ref="name"
            name="name"
            class="form-control"
            v-model="name"
            placeholder="New Patient Paperwork"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
        </div>
        <div class="form-group">
          <label class="form-label" style="margin-bottom: 10px"
            >Selected Forms</label
          >
          <FormCheckList ref="forms" />
        </div>
        <input type="submit" style="display: none" />
      </form>
      <div class="card-footer">
        <button class="button primary" @click.prevent="createPreset">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else>Create Preset</span>
        </button>
        <p class="error" v-if="error">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import FormCheckList from "../../components/FormCheckList.vue";
import { createPreset } from "@/api";

export default {
  name: "CreatePresetModal",
  mounted() {
    setTimeout(() => {
      const nameRef = this.$refs.name;
      if (nameRef) nameRef.focus();
    }, 500);
  },
  data() {
    return {
      name: "",
      pending: false,
      error: "",
    };
  },
  methods: {
    async createPreset() {
      this.error = "";

      if (!this.name) {
        this.error = "Please provide a preset name";
        return;
      }

      try {
        this.pending = true;
        const formsChecklist = this.$refs.forms;
        const formIds = formsChecklist.getSelectedFormIds();
        await createPreset(this.name, formIds);
        this.$emit('closeModal');
      } catch (err) {
        console.error('Failed to create preset', err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
  },
  emits: ["closeModal"],
  components: { FormCheckList },
};
</script>