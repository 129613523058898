<template>
  <div id="scroll">
    <AccountNavbar />
    <div class="content">
      <div class="content-header">
        <h1>Account Forms</h1>
        <div class="button-list">
          <button class="button primary small" @click="openModal('create')">
            Create Form
          </button>
          <button class="button primary small" @click="openModal('upload')">
            <img src="@/assets/upload.png" alt="Upload Form" class="button-icon">
          </button>
        </div>
      </div>
      <transition name="appear">
        <div class="content-body" v-if="loaded">
          <draggable :list="store.forms" @end="onDragEnd">
            <FormListItem v-for="form of store.forms" :key="form.id" :form="form" @click="openModal('details', form)"
              @deleteForm="openModal('delete', form)" @duplicateForm="openModal('duplicate', form)" />
          </draggable>
          <p v-if="store.forms.length == 0">
            You have no forms in your account. Upload or create one to get
            started.
          </p>
        </div>
      </transition>
    </div>
    <transition name="modal" mode="out-in">
      <CreateFormModal v-if="modal.id == 'create'" @closeModal="closeModal" />
      <UploadFormModal v-else-if="modal.id == 'upload'" @closeModal="closeModal" />
      <FormDetailsModal v-else-if="modal.id == 'details'" :form="modal.data" @closeModal="closeModal" />
      <DuplicateFormModal v-else-if="modal.id == 'duplicate'" :form="modal.data" @closeModal="closeModal" />
      <DeleteFormModal v-else-if="modal.id == 'delete'" :form="modal.data" @closeModal="closeModal" />
    </transition>
  </div>
</template>

<script>
import AccountNavbar from "../components/AccountNavbar.vue";
import FormListItem from "../components/account-forms/FormListItem.vue";
import { VueDraggableNext } from "vue-draggable-next";
import CreateFormModal from "../modals/account-forms/CreateFormModal.vue";
import UploadFormModal from "../modals/account-forms/UploadFormModal.vue";
import DuplicateFormModal from "@/modals/account-forms/DuplicateFormModal.vue";
import FormDetailsModal from "../modals/account-forms/FormDetailsModal.vue";
import DeleteFormModal from "../modals/account-forms/DeleteFormModal.vue";
import { store, fetchAccountForms, reorderForms } from "@/api";

export default {
  name: "AccountFormsView",
  data() {
    return {
      store,
      modal: {
        id: "",
        open: false,
        data: null,
      },
      loaded: false,
    };
  },
  async created() {
    try {
      this.loaded = false;
      await fetchAccountForms();
    } catch (err) {
      console.error("Failed to fetch account forms", err);
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    async onDragEnd() {
      try {
        const formIds = store.forms.map((form) => form.id);
        await reorderForms(formIds);
      } catch (err) {
        console.error("Failed to reorder forms", err);
      }
    },
    openModal(id, data) {
      this.modal = {
        open: true,
        id: id,
        data: data,
      };
    },
    closeModal() {
      this.modal = {
        open: false,
        id: "",
        data: null,
      };
    },
  },
  components: {
    AccountNavbar,
    FormListItem,
    draggable: VueDraggableNext,
    CreateFormModal,
    UploadFormModal,
    FormDetailsModal,
    DeleteFormModal,
    DuplicateFormModal
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1000px;
  min-height: 700px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 0px 8px #00000011;
  border-radius: 10px;
  margin: auto;
}

.content-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.button-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.button-list .button {
  margin-left: 15px;
}
</style>