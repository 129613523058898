<template>
  <div class="component">
    <label class="label"
      >{{ component.label
      }}<span class="optional-tag" v-if="component.optional"
        >(optional)</span
      ></label
    >
    <input
      class="input"
      :type="getTypeForComponent()"
      :placeholder="getPlaceholderForComponent()"
      :name="component.label"
      :disabled="!interactable"
      :filled="isComponentFilled()"
      :error="error"
      v-model="value"
      @input="onComponentInput()"
      @blur="onComponentBlur()"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
    />
    <img
      src="@/assets/trash.png"
      alt="trash"
      class="icon-sm"
      v-if="showTrash"
      @click="deleteComponent"
    />
  </div>
</template>

<script>
const partialDateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{2}$/;
const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z0-9]+$/;
const ssnRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
const phoneRegex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

export default {
  name: "InputComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  data() {
    return {
      value: "",
      error: false,
    };
  },
  methods: {
    updateValue(value) {
      this.value = value;
    },
    onComponentInput() {
      // Format the value and pass to parent, who will help pass to valuesMap
      this.value = this.formatValueBasedOnComponent(this.value);
      this.$emit("onUpdateValue", this.value);
    },
    onComponentBlur() {
      if (this.component.type == "DATE") {
        if (partialDateRegex.test(this.value)) {
          const digits = this.value.replace(/\D/g, "");
          const month = digits.substring(0, 2);
          const day = digits.substring(2, 4);
          const partialYear = digits.substring(4, 6);
          const year =
            partialYear > "50" ? `19${partialYear}` : `20${partialYear}`;
          this.value = `${month}/${day}/${year}`;
          this.$emit("onUpdateValue", this.value);
        }
      }
    },
    formatValueBasedOnComponent(val) {
      const type = this.component.type;

      // Format: MM/DD/YYYY
      if (type == "DATE") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length <= 2) {
          val = `${val.substring(0, 2)}`;
        } else if (val.length <= 4) {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}`;
        } else {
          val = `${val.substring(0, 2)}/${val.substring(2, 4)}/${val.substring(
            4,
            8
          )}`;
        }
      }

      // Format: 123-12-1234
      if (type == "SSN") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length <= 3) {
          val = `${val.substring(0, 3)}`;
        } else if (val.length <= 5) {
          val = `${val.substring(0, 3)}-${val.substring(3, 5)}`;
        } else {
          val = `${val.substring(0, 3)}-${val.substring(3, 5)}-${val.substring(
            5,
            9
          )}`;
        }
      }

      // Format: (123) 123-1234
      if (type == "PHONE") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length <= 0) {
          val = "";
        } else if (val.length <= 3) {
          val = `(${val.substring(0, 3)}`;
        } else if (val.length <= 6) {
          val = `(${val.substring(0, 3)}) ${val.substring(3, 6)}`;
        } else {
          val = `(${val.substring(0, 3)}) ${val.substring(
            3,
            6
          )}-${val.substring(6, 10)}`;
        }
      }

      // Format: 12345
      if (type == "NUMBER") {
        val = val.replace(/[^0-9]/g, "");
      }

      return val;
    },
    deleteComponent() {
      this.$emit("deleteComponent");
    },
    isComponentFilled() {
      const type = this.component.type;
      const value = this.value;

      if (type == "DATE" && !dateRegex.test(value)) {
        return false;
      }

      if (type == "EMAIL" && !emailRegex.test(value)) {
        return false;
      }

      if (type == "SSN" && !ssnRegex.test(value)) {
        return false;
      }

      if (type == "PHONE" && !phoneRegex.test(value)) {
        return false;
      }

      return value.length > 0;
    },
    getTypeForComponent() {
      switch (this.component.type) {
        case "EMAIL":
          return "email";
        case "FILE":
          return "file";
        case "NUMBER":
        case "DATE":
        case "PHONE":
        case "SSN":
          return "tel";
        default:
          return "text";
      }
    },
    getPlaceholderForComponent() {
      switch (this.component.type) {
        case "EMAIL":
          return "example@gmail.com";
        case "FILE":
          return "Upload";
        case "DATE":
          return "mm/dd/yyyy";
        case "PHONE":
          return "(123) 123-1234";
        case "SSN":
          return "123-12-1234";
        default:
          return " ";
      }
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    },
  },
  emits: ["onUpdateValue", "deleteComponent"],
};
</script>

<style scoped>
.component {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 45px;
  opacity: 1;
  border-radius: 0px;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #fff;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.optional-tag {
  margin-left: 5px;
  opacity: 0.5;
}
</style>