<template>
  <div class="form-list-item" @contextmenu.prevent="openDropdown" ref="item" :data-under-review="form.isUnderReview">
    <img v-if="form.type == 'FILE'" src="@/assets/form-pdf.png" alt="Form" class="icon">
    <img v-else src="@/assets/form.png" alt="Form" class="icon">
    <h3>{{form.name}} <span class="marker" v-if="!form.isAnnotated">Empty</span></h3>
    <img src="@/assets/trash.png" alt="Delete Form" class="icon-sm" @mousedown.prevent="$emit('deleteForm')">
    <!-- Dropdown options -->
    <transition name="appear" mode="out-in">
      <div class="dropdown" ref="dropdown" v-if="dropdownOpen" :style="{left: dropdownPosLeft, top: dropdownPosTop}">
        <div class="dropdown-option" @mousedown.prevent="openFormInNewTab">Open in new tab</div>
        <div class="dropdown-option" @mousedown.prevent="openFormEditor">Open form editor</div>
        <div class="dropdown-option" @mousedown.prevent="duplicateForm">Duplicate form</div>
        <div class="dropdown-option danger" @mousedown.prevent="deleteForm">Delete form</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FormListItem',
  props: {
    form: Object
  },  
  data() {
    return {
      dropdownOpen: false,
      dropdownPosLeft: '0px',
      dropdownPosTop: '0px'
    }
  },
  unmounted() {
    if (this.dropdownOpen) {
      window.removeEventListener('mousedown', this.onMouseDown);
    }
  },
  methods: {
    onMouseDown(event) {
      const dropdown = this.$refs.dropdown;
      const target = event.target;
      if (dropdown && !dropdown.contains(target)) {
        this.dropdownOpen = false;
        window.removeEventListener('mousedown', this.onMouseDown);
      }
    },
    openDropdown(event) {
      const el = this.$refs.item;
      const bb = el.getBoundingClientRect();
      const x = event.pageX - bb.left;
      const y = event.pageY - bb.top;

      this.dropdownOpen = true;
      this.dropdownPosLeft = x + 'px';
      this.dropdownPosTop = y + 'px';

      window.addEventListener('mousedown', this.onMouseDown);
    },
    openFormInNewTab() {
      this.dropdownOpen = false;
      const protocol = window.location.protocol;
      const host = window.location.host;
      const account = this.form.ownerNameUnique;
      const formUrl = this.form.url;
      const url = `${protocol}//${host}/${account}/${formUrl}`;
      window.open(url, '_blank');
    },
    duplicateForm() {
      this.dropdownOpen = false;
      this.$emit('duplicateForm');
    },
    deleteForm() {
      this.dropdownOpen = false;
      this.$emit('deleteForm');
    },
    openFormEditor() {
      this.dropdownOpen = false;
      this.$router.push(`/forms/${this.form.id}`);
    }
  },
  emits: ['deleteForm', 'duplicateForm']
}
</script>

<style scoped>
.form-list-item {
  position: relative;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFF;
  box-shadow: 0px 0px 4px #00000022;
  cursor: grab;
  border-radius: 10px;
}

.form-list-item[data-under-review="true"] {
  filter: saturate(0);
}

.form-list-item:not(:last-child) {
  margin-bottom: 20px;
}

.icon {
  display: block;
  height: 32px;
}

.icon-sm {
  display: block;
  height: 32px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

h3 {
  margin-left: 20px;
  font-weight: normal;
  flex: 1;
}

.marker {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
  text-transform: uppercase;
}

.dropdown {
  position: absolute;
  background: #FFF;
  box-shadow: 0px 1px 4px #00000022;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.dropdown-option {
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-option.danger {
  color: #a90000;
}

.dropdown-option:hover {
  background: #F5F5F5;
}
</style>