<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card">
      <div class="card-header">
        <h1>Delete Form</h1>
        <p>Are you sure you want to delete <u>{{form.name}}</u>? This action cannot be undone.</p>
      </div>
      <div class="card-footer">
        <button class="button danger" @click.prevent="attemptDelete">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else>Delete</span>
        </button>
        <p class="error" v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteForm } from "@/api";

export default {
  name: "DeleteFormModal",
  props: {
    form: Object,
  },
  data() {
    return {
      error: "",
      pending: false,
    };
  },
  methods: {
    async attemptDelete() {
      try {
        this.pending = true;
        await deleteForm(this.form.id);
        this.$emit("closeModal");
      } catch (err) {
        console.error("Failed to delete form", err);
      } finally {
        this.pending = false;
      }
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
  },
  emits: ["closeModal"],
};
</script>

<style scoped>
.card-header {
  margin-bottom: 0px;
}
</style>