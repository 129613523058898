<template>
  <div id="full">
    <transition name="slide" mode="out-in">
      <!-- Request 2FA Card -->
      <div class="card" v-if="activeCard == 'request-two-factor'">
        <div class="card-header">
          <h1>Welcome back!</h1>
          <p>
            Please sign into your DashQuill account using your office email.
          </p>
        </div>
        <form class="card-body" @submit.prevent="openLoginCard">
          <div class="form-group">
            <label for="email" class="form-label">Email Address</label>
            <input
              id="email"
              type="text"
              name="email"
              class="form-control"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <label for="password" class="form-label">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              class="form-control"
              v-model="password"
            />
          </div>
          <input type="submit" style="display: none">
        </form>
        <div class="card-footer">
          <button class="button primary" @click.prevent="openLoginCard">
            <img v-if="pending" src="/loading.svg" alt="Loading" class="loading">
            <span v-else>Login</span>
          </button>
          <p v-if="error" class="error">{{ error }}</p>
        </div>
      </div>
      <!-- Login Card -->
      <div class="card" style="max-width: 500px;" v-else-if="activeCard == 'login'">
        <div class="card-header">
          <h1>Check your email</h1>
          <p>
            We have sent a two factor authentication code to <u>{{ email }}</u
            >. It will expire in 5 minutes.
          </p>
        </div>
        <form class="card-body" @submit.prevent="attemptLogin">
          <div class="form-group">
            <label for="twoFactorCode" class="form-label"
              >Account unlock code</label
            >
            <AuthCodeInput :length="6" @updateValue="updateAuthValue" />
          </div>
          <input type="submit" style="display: none">
        </form>
        <div class="card-footer">
          <button class="button primary" @click="attemptLogin">
            <img v-if="pending" src="/loading.svg" alt="Loading" class="loading">
            <span v-else>Login</span>
          </button>
          <p v-if="error" class="error">{{ error }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AuthCodeInput from "../components/AuthCodeInput.vue";
import { requestTwoFactor, login, checkIfLoggedIn } from "@/api";

export default {
  name: "LoginView",
  data() {
    return {
      activeCard: "request-two-factor",
      email: "",
      password: "",
      twoFactorCode: "",
      error: "",
      pending: false
    };
  },
  async created() {
    const isLoggedIn = await checkIfLoggedIn();
    if (isLoggedIn) {
      this.$router.push('/submissions');
    }
  },
  methods: {
    async attemptLogin() {
      this.error = "";

      if (!this.twoFactorCode) {
        this.error = "Please enter a two factor code";
        return;
      }

      try {
        this.pending = true;
        await login(this.email, this.password, this.twoFactorCode);
        this.$router.push('/forms');
      } catch (err) {
        console.error('Failed to login to account', err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    async openLoginCard() {
      this.error = "";
      
      if (!this.email) {
        this.error = "Please provide an email.";
        return;
      }

      if (!this.password) {
        this.error = "Please provide a password.";
        return;
      }

      try {
        this.pending = true;
        await requestTwoFactor(this.email, this.password);
        this.activeCard = 'login'
      } catch (err) {
        console.error('Failed to request two factor code', err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    updateAuthValue(value) {
      this.twoFactorCode = value;

      if (value.length == 6) {
        this.attemptLogin();
      }
    },
  },
  components: { AuthCodeInput },
};
</script>

<style scoped>
#full {
  background-image: url("@/assets/wave.svg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  max-width: 450px;
}

.two-factor-code {
  letter-spacing: 42px;
}
</style>