<template>
  <input class="form-control" v-model="query" @input="debounceSearch" placeholder="Search..." />
</template>

<script>
let timeout;
import {store, loadSubmissionsForCurrentPage} from  "@/api";
export default {
  name: "SearchSubmissions",
  props: {},
  data() {
    return {
      query: "",
      timeout,
    };
  },
  mounted() {
    this.query = store.query;
  },
  methods: {
    debounceSearch() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.search();
      }, 500); // waits 300ms after user stops typing to call search
    },
    async search() {
      store.query = this.query;
      store.pageNum = 1; // Reset the page num (this is if the user is on page five of some other query and decides to search while sitting on that page)
      store.submissionPage = 1; // A part of resetting the page num, this is to set the page they are currently viewing to the first page
      await loadSubmissionsForCurrentPage();
    },
  },
};
</script>

<style scoped> 
.search {
  border: none;
  border-bottom: 2px solid black;
  width: 100%;
}
</style>
