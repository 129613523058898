<template>
  <div class="form">
    <form class="page-rows" v-if="form">
      <div class="page-row" v-for="row of form.layoutRows" :key="row.key"
        :data-empty="getComponentsForRow(row).length == 0">
        <div class="row-columns">
          <HtmlComponent v-for="component of getComponentsForRow(row)" :key="component" :component="component"
            :interactable="isFirstInstanceOfKey(component)" ref="components"
            @onUpdateValue="onUpdateValue(component.key, $event)" @openSignatureModal="openSignatureModal(component)"
            @click="onClickComponent(component)" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HtmlComponent from "../form-components-html/HtmlComponent.vue";

export default {
  name: "FillableHtmlForm",
  props: {
    form: Object,
    valuesMap: Map,
    keysOccurringFirstTime: Array
  },
  data() {
    return {
      firstUnfilledElement: null
    }
  },
  watch: {
    valuesMap: {
      handler() {
        this.updateValues();
      },
      deep: true
    },
    form: {
      handler(newForm) {
        // Default all checkboxes to false
        if (newForm != null) {
          for (let row of newForm.layoutRows) {
            for (let component of row.components) {
              if (component.type !== 'CHECKBOX') {
                continue;
              }

              const key = component.key;
              if (this.valuesMap.has(key)) {
                continue;
              }

              this.$emit('onUpdateValue', key, 'No');
            }
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.updateValues();

    window.addEventListener("resize", this.onWindowResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onUpdateValue(key, value) {
      this.$emit('onUpdateValue', key, value);
    },
    updateValues() {
      // Get component elements on page
      const componentElements = this.$refs.components;
      if (!componentElements) return;

      // Update all component values
      for (let componentElement of componentElements) {
        if (componentElement.updateValue == undefined) continue;

        const component = componentElement.component;
        if (component == null) continue;

        const key = component.key;
        if (key == null) continue;

        const value = this.valuesMap.get(key);
        if (value == null) continue;

        componentElement.updateValue(value);
      }
    },
    onClickComponent(component) {
      if (!this.isFirstInstanceOfKey(component)) {
        this.$emit("openRepeatedFieldModal", component.key);
      }
    },
    checkFormIsFilled() {
      this.firstUnfilledElement = null;

      let success = true;
      const componentElements = this.$refs.components || [];
      for (let componentEl of componentElements) {
        const result = componentEl.checkComponentIsFilled();
        if (!result) {
          if (this.firstUnfilledElement == null) {
            this.firstUnfilledElement = componentEl;
          }

          success = false;
        }
      }
      return success;
    },
    getFirstUnfilledElement() {
      return this.firstUnfilledElement;
    },
    getFirstElementOfKey(key) {
      const componentElements = this.$refs.components;
      for (let componentElement of componentElements) {
        const component = componentElement.component;
        if (component && component.key == key) {
          return componentElement.$el;
        }
      }

      return null;
    },
    openSignatureModal(component) {
      this.$emit("openSignatureModal", component.signatureType, component.key);
    },
    onWindowResize() {
      const componentElements = this.$refs.components;
      if (!componentElements) return;
      for (let componentEl of componentElements) {
        componentEl.refreshComponent();
      }
    },
    isFirstInstanceOfKey(component) {
      const key = component.key;

      // First instance appears on another page
      if (!this.keysOccurringFirstTime.includes(key)) {
        return false;
      }

      // Check all instances on this form
      for (let row of this.form.layoutRows) {
        for (let comp of row.components) {
          // Found the first instance!
          if (comp.key == key) {
            // Is it me?
            return comp == component;
          }
        }
      }

      return false;
    },
    getComponentsForRow(row) {
      return row.components.filter(component => {
        if (component.condition == null) return true;
        const { key, value } = component.condition;
        if (!this.valuesMap.has(key)) return false;
        if (this.valuesMap.get(key) !== value) return false;
        return true;
      })
    }
  },
  components: { HtmlComponent },
  emits: ["onUpdateValue", "openSignatureModal", "openRepeatedFieldModal"],
};
</script>

<style scoped>
.form {
  width: 100%;
  max-width: 1200px;
  min-height: 500px;
  padding: 75px;
  padding-top: 25px;
  padding-bottom: 150px;
  background: #fff;
  box-shadow: 0px 0px 8px #00000022;
}

.page-row {
  display: flex;
  align-items: stretch;
  padding: 18px 0px;
  background: #fff;
}

.page-row[data-empty="true"] {
  display: none;
}

.row-columns {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-left: -25px;
}

.row-columns>.component {
  margin-left: 25px;
}

@media screen and (max-width: 1000px) {
  .form {
    padding: 25px;
  }

  .row-columns {
    display: flex;
    flex-direction: column;
  }

  .row-columns {
    margin-left: 0px;
  }

  .row-columns>.component {
    margin-left: 0px;
    margin-bottom: 25px;
  }

  .page-row {
    padding: 0px;
  }
}
</style>