<template>
  <div class="annotation" ref="annot">
    <img v-if="annotation.data" :src="annotation.data" alt="Provider Signature" class="input" :disabled="true">
    <img v-else src="@/assets/signature.png" alt="Default Provider Signature" class="input" :disabled="true">
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @mousedown.prevent.stop="$emit('deleteAnnotation')">
  </div>
</template>

<script>

export default {
  name: "SignatureAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  emits: ['deleteAnnotation']
};
</script>

<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.input {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 20px;
  opacity: 1;
  border-radius: 0px;
  padding: 0px !important;
  pointer-events: none;
  user-select: none;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>