<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card">
      <div class="card-header">
        <h1>{{ submission.name }}</h1>
        <p>{{ getDescription() }}</p>
      </div>
      <form class="card-body">
        <div class="submission-form" v-for="(form, i) of submission.forms" :key="form.id">
          <span class="form-name">{{ form.name }}</span>
          <span class="form-status">
            <button class="copy-button" v-if="form.type == 'HTML'" @click.prevent="getAndCopyClipboard(form)"
              :key="form.id">
              <img src="@/assets/copy-link.png">
            </button>
            <span class="complete" v-if="i < submission.furthestPageIndex">Complete</span>
            <span class="incomplete" v-else>Incomplete</span>
          </span>
        </div>
      </form>
      <div class="card-footer">
        <button class="button primary" @click="tryDownloadSubmission">
          <img v-if="pending" class="loading" src="/loading.svg" alt="Loading" />
          <span v-else>Download as PDF</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadSubmission, getSubmissionValues } from "@/api";

export default {
  name: "PresetDetailsModal",
  props: {
    submission: Object,
  },
  data() {
    return {
      pending: false,
    };
  },
  created() {
    console.log("submission", this.submission);
  },
  methods: {
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
    async getAndCopyClipboard(form) {
      const { id, version } = form;
      // Query the backend to get the key value map
      const json = await getSubmissionValues(this.submission.id, id, version);
      const text = json.values;

      // Copy the text
      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(text);
          alert(`Copied submission values to clipboard`);
        } else {
          alert('Clipboard API is not enabled on this browser');
        }
      } catch (err) {
        console.error("Error in copying text: ", err);
      }
    },
    async tryDownloadSubmission() {
      this.pending = true;
      try {
        const blob = await downloadSubmission(this.submission.id);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
      } catch (err) {
        alert("ERROR: " + err.message);
      } finally {
        this.pending = false;
      }
    },
    formatDate() {
      const date = new Date(this.submission.dateCreated);
      return date.toLocaleDateString();
    },
    getDescription() {
      const date = new Date(this.submission.dateCreated);
      const dateString = date.toLocaleDateString();
      if (this.submission.type == "CREATED_BY_OFFICE") {
        if (this.submission.shareEmail) {
          return `Emailed to ${this.submission.shareEmail} on ${dateString}`;
        } else {
          return `Texted to ${this.submission.sharePhoneNumber} on ${dateString}`;
        }
      } else {
        return `Created through public link on ${dateString}`;
      }
    },
  },
  emits: ["closeModal"],
};
</script>

<style scoped>
.button-list {
  display: flex;
}

.button-list button:not(:last-child) {
  margin-right: 20px;
}

.submission-form {
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.secondary {
  background-color: darkgray;
  padding: 5px;
  margin-right: 5px;
  color: white;
}

.form-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-status .complete {
  color: green;
}

.form-status .incomplete {
  color: red;
}

.copy-button {
  padding: 2px;
  border: none;
  display: flex;
  align-items: center;
  background: none;
  margin-right: 5px;
}

.copy-button img {
  height: 20px;
  display: block;
  cursor: pointer;
}
</style>