<template>
  <div class="component">
    <div class="input"><span v-if="!interactable">Spacer</span></div>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent" />
  </div>
</template>

<script>

export default {
  name: "SpacerComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  methods: {
    deleteComponent() {
      this.$emit("deleteComponent");
    },
    checkComponentIsFilled() {
      return true;
    },
  },
  emits: ["onUpdateValue", "deleteComponent"],
};
</script>

<style scoped>
.component {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  font-style: italic;
  opacity: 0.5;
}

.label {
  vertical-align: top;
  display: inline;
  width: 100%;
  line-height: 28px;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #fff;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

</style>