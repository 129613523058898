<template>
  <div class="annotation-sidebar" ref="sidebar">
    <div class="annotation-list">
      <div class="bubble" v-for="annot of annotations" :key="annot" draggable="true" @dragstart="onDragStart(annot, $event)" @dragend="onDragEnd()">
        <label class="label">{{annot.label}}</label>
        <HtmlAnnotation
          :annotation="annot"
          :interactable="false"
          :showTrash="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HtmlAnnotation from "../form-annotations-pdf/HtmlAnnotation.vue";

export default {
  name: "AnnotationSidebar",
  data() {
    return {
      annotations: [
        {
          type: "TEXT",
          label: "Text Box",
          optional: true
        },
        {
          type: "DATE",
          label: "Date Field",
          optional: true
        },
        {
          type: "EMAIL",
          label: "Email",
          optional: true
        },
        {
          type: "PHONE",
          label: "Phone Field",
          optional: true
        },
        {
          type: "CHECKBOX",
          label: "Checkbox",
          optional: true
        },
        {
          type: "CIRCLEBOX",
          label: "Circlebox",
          optional: true
        },
        {
          type: "SIGNATURE",
          label: "Patient Signature",
          signatureType: 'signature-patient'
        },
        {
          type: "NUMBER",
          label: "Number Box",
          optional: true
        },
        {
          type: "SSN",
          label: "Social Security Number",
          optional: true
        },
        {
          type: "DROPDOWN",
          label: "Dropdown",
          optional: true
        },
        {
          type: 'STATE',
          label: 'State',
          optional: true
        },
        {
          type: "DRAW_BOX",
          label: "Free Draw Box"
        },
        {
          type: "PROVIDER_SIGNATURE",
          label: "Provider Signature",
        },
        {
          type: "PROVIDER_TEXT",
          label: "Provider Text"
        }
      ],
    };
  },
  methods: {
    onDragStart(annotation, event) {
      const input = event.target.querySelector('.input');
      if (!input) {
        console.warn('Missing input on annotation', event.target);
        return;
      }
      const rect = input.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = rect.height - (event.clientY - rect.top);
      const meta = {x, y, w: rect.width, h: rect.height};

      this.$emit("onDragStart", this.cloneAnnotation(annotation), meta);
    },
    onDragEnd() {
      this.$emit("onDragEnd");
    },
    cloneAnnotation(annot) {
      const cloned = structuredClone(annot);
      cloned.key = `doNotImport${this.generateRandomNonce()}`;
      cloned.optional = true;
      cloned.unique = true;

      if (annot.type == 'SIGNATURE') {
        cloned.optional = false;
      }

      // Remove label (not part of struct)
      if (cloned.label) {
        delete cloned.label;
      }

      return cloned;
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    }
  },
  components: { HtmlAnnotation },
  emits: ["onDragStart", "onDragEnd"],
};
</script>

<style scoped>
.annotation-sidebar {
  flex-basis: 350px;
  height: 100%;
  padding: 25px;
  padding-top: 100px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.annotation-sidebar::-webkit-scrollbar {
  display: none;
}

.bubble {
  padding: 20px;
  background: #fff;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  cursor: grab;
}

.bubble * {
  cursor: grab;
}

.bubble .label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
</style>