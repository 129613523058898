<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 450px">
      <div class="card-header">
        <h1>Add New User</h1>
        <p>Please make sure to write down the password in a secure place. Once the user is created, you will not be able to see the password.</p>
      </div>
      <form class="card-body" @submit.prevent="attemptCreate">
        <div class="form-group">
          <label for="email" class="form-label">User Email</label>
          <input
            id="email"
            ref="email"
            type="text"
            name="email"
            class="form-control"
            v-model="email"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
            placeholder="member@office.com"
          />
        </div>
        <div class="form-group">
          <label for="name" class="form-label">User Password</label>
          <input
            id="name"
            type="text"
            name="password"
            class="form-control"
            v-model="password"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
        </div>
        <input type="submit" value="" style="display: none">
      </form>
      <div class="card-footer">
        <button class="button primary" @click.prevent="attemptCreate">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else>Create User</span>
        </button>
        <p class="error" v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { createUser } from '@/api';


export default {
  name: "UserListModal",
  props: {
    users: Array
  },
  data() {
    return {
      pending: false,
      error: '',
      email: '',
      password: ''
    }
  },
  mounted() {
    setTimeout(() => {
      const emailRef = this.$refs.email;
      if (emailRef) emailRef.focus();
    }, 500);
  },
  methods: {
    async attemptCreate() {
      if (this.pending) {
        return;
      }

      this.error = "";
      this.pending = true;

      try {
        await createUser(this.email, this.password);
        this.$emit('closeModal')
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
  },
  emits: ["closeModal"],
};
</script>