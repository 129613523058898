<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 550px">
      <div class="card-header">
        <h1
          contenteditable="true"
          ref="name"
          @blur="onChangeName"
          @keypress.enter.prevent="$event.target.blur()"
        >
          {{ preset.name }}
        </h1>
        <p>
          <a :href="getURL()" target="_blank" @click="onClickUrl">{{ getURL() }}</a>
        </p>
      </div>
      <form class="card-body">
        <div class="form-group">
          <label class="form-label" style="margin-bottom: 10px"
            >Forms in group</label
          >
          <FormCheckList ref="forms" @updated="onListUpdated" />
        </div>
      </form>
      <div class="card-footer">
        <button class="button primary" @click="attemptUpdatePreset">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else-if="saveTimeout != null">Saved</span>
          <span v-else>Save Changes</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormCheckList from "../../components/FormCheckList.vue";
import { updatePreset, updatePresetName } from "@/api";

export default {
  name: "PresetDetailsModal",
  props: {
    preset: Object,
  },
  data() {
    return {
      pending: false,
      error: "",
      hasUpdate: false
    };
  },
  mounted() {
    const formsChecklist = this.$refs.forms;
    formsChecklist.setSelectedFormIds(this.preset.forms);
  },
  methods: {
    closeModal(event) {
      const nameElement = this.$refs.name;
      if (nameElement && nameElement == document.activeElement) {
        return;
      }

      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
    getURL() {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const account = this.preset.ownerNameUnique;
      const url = this.preset.url;
      return `${protocol}//${host}/${account}/${url}`;
    },
    async onChangeName() {
      const nameElement = this.$refs.name;
      if (!nameElement) return;
      const name = nameElement.textContent;

      try {
        console.info('Updating preset name to: ' + name);
        await updatePresetName(this.preset.id, name);
      } catch (err) {
        console.error("Failed to preset form name");
      }
    },
    async attemptUpdatePreset() {
      this.error = "";

      try {
        this.pending = true;
        const formsChecklist = this.$refs.forms;
        const formIds = formsChecklist.getSelectedFormIds();
        await updatePreset(this.preset.id, formIds);
        this.$emit('closeModal');
      } catch (err) {
        console.error("Failed to update preset forms", err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    onListUpdated() {
      this.hasUpdate = true;
    },
    onClickUrl(event) {
      // If there's been an update
      if (this.hasUpdate) {
        // Prevent
        event.preventDefault();

        // Then save
        this.attemptUpdatePreset().then(() => {
          // Then click
          this.hasUpdate = false;
          event.target.click();
        });
        return;
      }
    }
  },
  emits: ["closeModal"],
  components: { FormCheckList },
};
</script>