<template>
  <div class="modal">
    <div class="card" style="max-width: 500px">
      <div class="card-header">
        <h1>Secure Check-In</h1>
        <p>Welcome back to our office. Please review and confirm the current information we have on file about you.</p>
        <br>
        <p>For any empty sections or forms, please fill them out.</p>
      </div>
      <div class="card-footer">
        <button class="button submit" @click.prevent="closeModal">
          <span>Review Information</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>  
export default {
  name: "WelcomeContractModal",
  data() {
    return {
      first: "",
      last: "",
      error: "",
      pending: false,
    };
  },
  mounted() {
    document.documentElement.style.width = '100vw'
    document.documentElement.style.position = 'fixed';
    document.documentElement.style.top = '0px';

    window.addEventListener('resize', this.resizeModalPage);
    this.resizeModalPage();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeModalPage);

    document.documentElement.style.width = ''
    document.documentElement.style.position = '';
    document.documentElement.style.top = '';
  },
  methods: {
    resizeModalPage() {
      const el = this.$el;
      el.style.height = `${window.innerHeight}px`
    },
    closeModal() {
      this.$emit('closeModal');
    }
  },
  emits: ["closeModal"],
};
</script>

<style scoped>
.modal {
  background: #131b28c8;
}

.card {
  border-radius: 0px;
}

.button.submit {
  padding: 15px 25px;
  background: var(--color-patient);
  color: #fff;
}

.card-footer {
  margin-top: 0;
}

.button.submit:disabled {
  background: #535353;
}

.form-row {
  margin-top: 0px;
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 45px;
  opacity: 1;
}

.input::placeholder {
  opacity: 0.5;
}
</style>