<template>
  <div class="component">
    <label class="label">{{ component.label }}<span class="optional-tag" v-if="component.optional">(optional)</span></label>
    <select class="input" :name="component.label" :disabled="!interactable" v-model="value" :filled="isComponentFilled()" :error="error" @change="onComponentChange">
      <option v-for="option of getOptions()" :key="option">{{option}}</option>
    </select>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent">
  </div>
</template>

<script>
const ALL_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export default {
  name: "DropdownComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  data() {
    return {
      value: '',
      error: false
    }
  },
  methods: {
    updateValue(value) {
      this.value = value;
    },
    onComponentChange() {
      // Pass to parent, who will help pass to valuesMap
      this.$emit('onUpdateValue', this.value);
    },
    deleteComponent() {
      this.$emit('deleteComponent')
    },
    isComponentFilled() {
      return this.value.length > 0;
    },
    getOptions() {
      if (this.component.type == 'STATE') {
        return ALL_STATES;
      }

      return this.component.options;
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    }
  },
  emits: ['onUpdateValue', 'deleteComponent']
};
</script>

<style scoped>
.component {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  height: 45px;
  opacity: 1;
  border-radius: 0px;
  color: #000;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #FFF;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.optional-tag {
  margin-left: 5px;
  color: #9d9d9d;
}
</style>