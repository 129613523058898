<template>
  <div class="annotation" ref="annot">
    <select class="input" ref="input" :disabled="!interactable" :filled="isAnnotationFilled()" :error="error" v-model="value"
      @change="onAnnotationChange()" :style="{
        fontSize: `${fontSize}px`
      }">
      <option v-for="option of getOptions()" :key="option" :value="option">{{ option }}</option>
    </select>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash"
      @mousedown.prevent.stop="$emit('deleteAnnotation')">
  </div>
</template>

<script>
const ALL_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export default {
  name: "InputAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  data() {
    return {
      value: '',
      error: false,
      fontSize: 20
    }
  },
  mounted() {
    this.refreshAnnotation();
  },
  watch: {
    annotation: {
      handler: function () {
        this.refreshAnnotation();
      },
      deep: true
    }
  },
  methods: {
    updateValue(value) {
      this.value = value;
      setTimeout(() => {
        this.refreshAnnotation();
      }, 0)
    },
    onAnnotationChange() {
      this.$emit("onUpdateValue", this.value);
      this.refreshAnnotation();
    },
    isAnnotationFilled() {
      return this.value.length > 0;
    },
    checkAnnotationIsFilled() {
      if (this.annotation.optional) {
        return true;
      }

      if (this.isAnnotationFilled()) {
        return true;
      }

      this.error = true;
      return false;
    },
    refreshAnnotation() {
      const inputRef = this.$refs.input;
      if (!inputRef) return;

      // Compute font size based on rect size
      const rect = inputRef.getBoundingClientRect();
      if (rect.height == 0) return;

      // Measure the width of the text
      const style = getComputedStyle(inputRef);
      const canvas = document.createElement("canvas");
      const context = canvas.getContext('2d');
      context.font = `${rect.height}px ${style.fontFamily}`;
      const textWidth = context.measureText(inputRef.value).width;

      // Resize annotation to fit
      const ratio = rect.width / textWidth;
      if (ratio < 1) {
        inputRef.style.fontSize = rect.height * ratio + 'px';
      } else {
        inputRef.style.fontSize = rect.height + 'px';
      }
    },
    getOptions() {
      if (this.annotation.type == 'STATE') {
        return ALL_STATES;
      }

      return this.annotation.options;
    },
  },
  emits: ['onUpdateValue', 'deleteAnnotation']
};
</script>

<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 100%;
  height: 25px;
  flex-direction: column;
  justify-content: flex-end;
}

.input {
  display: block;
  width: 100%;
  height: 1px;
  flex: 1;
  border: none;
  outline: none;
  background: var(--color-patient-field);
  opacity: 1;
  border-radius: 0px;
  padding: 0px !important;
  color: #000 !important;
  /* Disable little arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
  /* background: #FFF; */
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>