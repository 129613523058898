<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 450px">
      <div class="card-header">
        <h1>Upload Existing Form</h1>
        <p>
          Digitize your existing PDF form by adding interactive fields to it.
        </p>
      </div>
      <form class="card-body" @submit.prevent="attemptUpload">
        <div class="form-group">
          <label for="name" class="form-label">Form Name</label>
          <input
            id="name"
            type="text"
            name="name"
            ref="name"
            class="form-control"
            v-model="name"
            placeholder="Health History Form"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
        </div>
        <div class="form-group">
          <label for="file" class="form-label">PDF File</label>
          <input
            id="file"
            type="file"
            name="file"
            class="form-control"
            ref="file"
            accept="application/pdf"
            @change="onSelectFile"
          />
        </div>
      </form>
      <div class="card-footer">
        <button class="button primary" @click.prevent="attemptUpload">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else>Upload</span>
        </button>
        <p class="error" v-if="error">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadAccountFile, createForm } from "@/api";

export default {
  name: "CreateFormModal",
  data() {
    return {
      error: "",
      pending: false,
      name: ""
    };
  },
  mounted() {
    setTimeout(() => {
      const nameRef = this.$refs.name;
      if (nameRef) nameRef.focus();
    }, 500);
  },
  methods: {
    async attemptUpload() {
      this.error = "";
      if (!this.name) {
        this.error = "Please provide a form name";
        return;
      }

      const fileInput = this.$refs.file;
      const file = fileInput.files[0];
      if (!file) {
        this.error = "Please upload a PDF file";
        return;
      }

      try {
        this.pending = true;
        const accountFile = await uploadAccountFile(file);
        const newForm = await createForm(this.name, accountFile.id);
        this.$router.push(`/forms/${newForm.id}`);
      } catch (err) {
        console.error("Failed to upload new form", err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
    onSelectFile(event) {
      const target = event.target;
      const file = target.files[0];
      if (!file) return;

      // Update name
      let name = file.name;
      name = name.substring(0, name.lastIndexOf('.'));
      if (this.name == '') {
        this.name = name;
      }
    }
  },
  emits: ["closeModal"],
};
</script>