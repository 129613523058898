<template>
  <div class="component">
    <div className="input" :filled="isComponentFilled()" :required="!component.optional" :error="error" @click="toggleValue" ref="input">
      <label className="input-icon"></label>
    </div>
    <label class="label">{{ component.label }}</label>
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent" />
  </div>
</template>

<script>

export default {
  name: "CheckboxComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  data() {
    return {
      value: "",
      error: false,
    };
  },
  methods: {
    toggleValue() {
      if (!this.interactable) {
        return;
      }

      if (this.value == 'Yes') {
        this.value = 'No'
      } else {
        this.value = 'Yes'
      }

      this.$emit('onUpdateValue', this.value);
    },
    updateValue(value) {
      this.value = value;
    },
    deleteComponent() {
      this.$emit("deleteComponent");
    },
    isComponentFilled() {
      const value = this.value;
      return value == 'Yes';
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    },
  },
  emits: ["onUpdateValue", "deleteComponent"],
};
</script>

<style scoped>
.component {
  position: relative;
  width: 100%;
}

.input {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer !important;
  flex-shrink: 0;
  background: var(--color-patient-field);
  border: 1px solid #00000015;
  border-bottom: 1px solid #00000022;
  margin-right: 8px;
  margin-top: 1px;
}

.input:hover {
  background-color: #EEE;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background-color: var(--color-patient-field-filled);
}

.input[filled="true"] .input-icon {
  position: absolute;
  margin: 0;
  right: 14px;
  bottom: 4px;
  width: 8px;
  height: 16px;
  transform-origin: bottom right;
  border-right: 2.5px solid #000;
  border-bottom: 2.5px solid #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.input[filled="false"][required="true"] .input-icon {
  position: absolute;
  margin: 0;
  right: 14px;
  bottom: 4px;
  width: 8px;
  height: 16px;
  transform-origin: bottom right;
  border-right: 2.5px solid #AAA;
  border-bottom: 2.5px solid #AAA;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.label {
  vertical-align: top;
  display: inline;
  width: 100%;
  line-height: 28px;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #fff;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

</style>