<template>
  <div class="properties-sidebar">
    <div class="properties-sidebar-list">
      <div class="list-content" v-if="componentClone != null">
        <div class="section section-component">
          <h3>Component</h3>
          <!-- EMR field -->
          <EhrFieldSearchTab :emrJson="emrJson" :selectedKey="componentClone.key" @updateEhrField="updateEhrField" scope="HTML" />
          <!-- Part Options -->
          <div class="form-group" v-if="componentClone.key && componentClone.key.startsWith('partHistory')">
            <label for="image-height" class="form-label" style="word-break: break-all;">Part Label ({{ componentClone.key }})</label>
            <input type="text" class="form-control" placeholder="Has condition XYZ" :value="getPartLabel()" @input="updatePartLabel">
          </div>
          <!-- Type -->
          <div class="form-group" v-if="canChangeType()">
            <label class="form-label">Type</label>
            <select type="text" class="form-control" v-model="componentClone.type" @change="updateComponent">
              <option v-for="tp of getComponentTypes()" :key="tp" :value="tp">{{ tp }}</option>
            </select>
          </div>
          <!-- Label -->
          <div class="form-group" v-if="componentClone.type != 'PARAGRAPH'">
            <label class="form-label">Label</label>
            <input type="text" class="form-control" v-model="componentClone.label" @input="updateLabel">
          </div>
          <!-- Label Long -->
          <div class="form-group" v-if="componentClone.type == 'PARAGRAPH'">
            <label class="form-label">Text</label>
            <textarea class="form-control" v-model="componentClone.label" @input="updateLabel"></textarea>
          </div>
        </div>
        <div class="section section-properties">
          <h3>Properties</h3>
          <!-- Checkboxes -->
          <div class="form-group form-group-checkboxes">
            <div class="horizontal-checkbox-line">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="!componentClone.optional"
                @updateValue="updateIsRequired" />
              <label>Field is required</label>
            </div>
            <div class="horizontal-checkbox-line">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="componentClone.condition != null"
                @updateValue="updateIsConditional" />
              <label>Field is conditional</label>
            </div>
            <div class="horizontal-checkbox-line" v-if="componentClone.type == 'PARAGRAPH'">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="componentClone.whitespace"
                @updateValue="updateAllowWhitespace" />
              <label>Allow whitespace</label>
            </div>
          </div>
          <!-- Image options -->
          <div class="form-group" v-if="componentClone.type == 'IMAGE'">
            <label for="image-width" class="form-label">Image Width</label>
            <input type="number" class="form-control" v-model="componentClone.width" @input="updateImageSize(true)">
          </div>
          <div class="form-group" v-if="componentClone.type == 'IMAGE'">
            <label for="image-height" class="form-label">Image Height</label>
            <input type="number" class="form-control" v-model="componentClone.height" @input="updateImageSize(false)">
          </div>
          <!-- Header options -->
          <div class="form-group" v-if="componentClone.type == 'HEADER'">
            <label class="form-label">Header Size</label>
            <select type="text" class="form-control" v-model="componentClone.headerSize" @change="updateComponent">
              <option value="h1">Heading 1</option>
              <option value="h2">Heading 2</option>
              <option value="h3">Heading 3</option>
            </select>
          </div>
          <!-- Signature options -->
          <div class="form-group" v-if="componentClone.type == 'SIGNATURE'">
            <label class="form-label">Signature Type</label>
            <select type="text" class="form-control" v-model="componentClone.signatureType"
              @change="updateSignatureType()">
              <option value="signature-patient">Patient Signature</option>
              <option value="initials-patient">Patient Initials</option>
              <option value="signature-guardian">Guardian Signature</option>
              <option value="initials-guardian">Guardian Initials</option>
              <option value="signature-witness">Witness Signature</option>
              <option value="initials-witness">Witness Initials</option>
            </select>
          </div>
          <!-- Options List -->
          <OptionsListTab v-if="hasOptionsDropdown()" :options="componentClone.options" :locked="!isDoNotImport()"
            @updateOptions="updateOptions" />
          <!-- Conditional tab -->
          <ConditionalTab v-if="componentClone.condition" :component="component" :allComponents="allComponents"
            @updateCondition="updateCondition" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EhrFieldSearchTab from '../form-editor-shared/EhrFieldSearchTab.vue'
import OptionsListTab from '../form-editor-shared/OptionsListTab.vue'
import CheckBox from '@/components/CheckBox.vue'
import ConditionalTab from '../form-editor-shared/ConditionalTab.vue'

export default {
  name: 'PropertiesSidebar',
  props: {
    emrJson: Object,
    component: Object,
    allComponents: Array
  },
  data() {
    return {
      componentClone: null,
    }
  },
  watch: {
    component: {
      handler(newVal) {
        if (!newVal) {
          this.componentClone = null;
        }
        this.componentClone = structuredClone(this.component)
      },
      immediate: true
    }
  },
  methods: {
    updateEhrField(field) {
      // Update the component key
      if (field.unique) {
        this.componentClone.key = field.key + this.generateRandomNonce();
      } else {
        this.componentClone.key = field.key;
      }

      // Update the component name based on field
      if (field.key != 'doNotImport' && !field.key.startsWith('part')) {
        this.componentClone.label = field.label;
      }

      // Update the component type
      if (field.type != null) {
        this.componentClone.type = field.type;
      }

      // Required flag
      if (field.optional != null) {
        this.componentClone.optional = field.optional;
      } else {
        this.componentClone.optional = false;
      }

      // Update options
      if (field.options != null) {
        this.componentClone.options = field.options;
      }

      // Emit to parent
      this.updateComponent();
    },
    updateOptions(options) {
      this.componentClone.options = options;
      this.updateComponent();
    },
    updateIsRequired(value) {
      this.componentClone.optional = !value;
      this.updateComponent();
    },
    updateIsConditional(value) {
      if (value) {
        this.componentClone.condition = {
          key: '',
          value: ''
        }
      } else {
        this.componentClone.condition = undefined;
      }

      this.updateComponent();
    },
    updateAllowWhitespace(value) {
      this.componentClone.whitespace = value;
      this.updateComponent();
    },
    updateCondition(condition) {
      this.componentClone.condition = condition;
      this.updateComponent();
    },
    updateSignatureType() {
      switch (this.componentClone.signatureType) {
        case 'signature-patient':
          this.componentClone.label = 'Patient Signature';
          break;
        case 'signature-guardian':
          this.componentClone.label = 'Parent or Guardian Signature';
          break;
        case 'signature-witness':
          this.componentClone.label = 'Witness Signature';
          break;
        case 'initials-patient':
          this.componentClone.label = 'Patient Initials';
          break;
        case 'initials-guardian':
          this.componentClone.label = 'Parent or Guardian Initials';
          break;
        case 'initials-witness':
          this.componentClone.label = 'Witness Initials';
          break;
      }

      this.updateComponent();
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
    isDoNotImport() {
      return this.componentClone.key && this.componentClone.key.startsWith('doNotImport');
    },
    canChangeType() {
      return this.componentClone.key && (this.componentClone.key.startsWith('doNotImport') || this.componentClone.key.startsWith('partHistory'));
    },
    hasOptionsDropdown() {
      const type = this.componentClone.type;
      return type == 'DROPDOWN' || type == 'MULTISELECT';
    },
    updateLabel() {
      this.componentClone.label = this.componentClone.label.trimStart();
      this.updateComponent();
    },
    updateComponent() {
      this.$emit('updateComponent', this.componentClone);
    },
    getComponentTypes() {
      switch (this.componentClone.type) {
        case 'HEADER':
        case 'PARAGRAPH':
        case 'IMAGE':
          return ['HEADER', 'PARAGRAPH', 'IMAGE'];
        default:
          return ['TEXT', 'TEXTAREA', 'NUMBER', 'DATE', 'CHECKBOX', 'DROPDOWN', 'MULTISELECT', 'EMAIL', 'PHONE', 'SSN', 'SIGNATURE', 'FILE', 'SPACER', 'STATE']
      }
    },
    updateImageSize(horizontal) {
      if (!this.componentClone.data) return;

      const img = new Image();
      img.src = this.componentClone.data;
      img.onload = () => {
        if (horizontal) {
          const targetHeight = Math.round(this.componentClone.width * img.height / img.width);
          this.componentClone.height = targetHeight;
        } else {
          const targetWidth = Math.round(this.componentClone.height * img.width / img.height);
          this.componentClone.width = targetWidth;
        }

        this.updateComponent();
      }
    },
    updatePartLabel(event) {
      const key = this.componentClone.key;
      const value = event.target.value;
      if (!key) return;

      let keyWithoutSuffix = key;
      if (keyWithoutSuffix.indexOf('_') > 0) {
        keyWithoutSuffix = key.substring(0, keyWithoutSuffix.indexOf('_'));
      }

      this.componentClone.key = `${keyWithoutSuffix}_${encodeURIComponent(value)}`;
      this.updateComponent();
    },
    getPartLabel() {
      const key = this.componentClone.key;
      if (!key) return;

      if (key.indexOf('_') > 0) {
        return decodeURIComponent(key.substring(key.indexOf('_') + 1));
      }

      return '';
    }
  },
  components: { EhrFieldSearchTab, OptionsListTab, ConditionalTab, CheckBox },
  emits: ['updateComponent']
}
</script>

<style scoped>
.properties-sidebar {
  flex-basis: 350px;
  height: 100%;
  padding: 25px;
  padding-top: 100px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.properties-sidebar::-webkit-scrollbar {
  display: none;
}

.properties-sidebar-list {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 8px #00000022;
  border-radius: 10px;
}

.section {
  padding: 25px;
  padding-bottom: 50px;
}

.section h3 {
  margin-bottom: 50px;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  line-height: 40px;
  font-size: 20px;
  letter-spacing: 2px;
}

.form-group-checkboxes {
  margin-top: 0px;
}

.horizontal-checkbox-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horizontal-checkbox-line:not(:first-child) {
  margin-top: 15px;
}

.horizontal-checkbox-line .checkbox {
  flex-shrink: 0;
}

.horizontal-checkbox-line label {
  margin-left: 10px;
  font-size: 16px;
  color: var(--color-secondary);
}

.form-control {
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
}

textarea.form-control {
  resize: vertical;
  font-size: 16px;
}
</style>