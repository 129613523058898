<template>
  <div class="experience-rating">
    <p>Have would you rate our online form filler?</p>
    <div class="stars">
      <div
        class="star"
        v-for="star in 5"
        :key="star"
        @mouseover="onMouseOver(star)"
        @mouseout="onMouseOut()"
        @mousedown="onSelectStar(star)"
      >
        <img
          src="@/assets/star.png"
          class="star-image"
          :alt="`Star ${star}`"
          :style="getSaturationStyle(star)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExperienceRating",
  data() {
    return {
      hoverIndex: -1,
      selectedIndex: -1,
    };
  },
  methods: {
    onMouseOver(star) {
      this.hoverIndex = star;
    },
    onMouseOut() {
      this.hoverIndex = -1;
    },
    onSelectStar(star) {
      this.selectedIndex = star;
      this.$emit('updateRating', star);
    },
    getSaturationStyle(star) {
      const style = { filter: "saturate(0)" };

      if (star <= this.hoverIndex) {
        style.filter = "saturate(1)";
      }

      if (star <= this.selectedIndex) {
        style.filter = "saturate(1)";
        style.opacity = 1;
      }

      return style;
    },
  },
  emits: ['updateRating']
};
</script>

<style scoped>
.experience-rating {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stars {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.star {
  padding: 10px;
  cursor: pointer;
}

.star-image {
  width: 25px;
  opacity: 0.5;
  transition: filter ease-in-out 0.1s;
}

input {
  width: 70%;
  background: transparent;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #000;
  outline: none;
}
</style>