<template>
  <div class="modal" @mousedown="closeModal" @touchmove.prevent="">
    <div class="card" style="max-width: 500px">
      <div class="card-header">
        <h1>Update Original Value</h1>
        <p>Watch out! <u>{{getFieldName()}}</u> is a repeated field, and first appears on <u>Form #{{ formIndex + 1 }}</u>.</p>
        <br>
        <p>If the current value appears incorrect, there is a chance it needs to be changed on previous forms as well.</p>
      </div>
      <div class="card-footer">
        <button class="button submit" @click.prevent="$emit('visitOriginalField')">
          <span>Update Value on Form {{ formIndex + 1 }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>  
export default {
  name: "WelcomeContractModal",
  props: {
    formIndex: Number,
    fieldKey: String
  },
  mounted() {
    this.adjustModalPositionAndScale();
    window.addEventListener('resize', this.adjustModalPositionAndScale)
    window.addEventListener('scroll', this.adjustModalPositionAndScale)
  },
  unmounted() {
    window.removeEventListener('resize', this.adjustModalPositionAndScale)
    window.removeEventListener('scroll', this.adjustModalPositionAndScale)
  },
  methods: {
    adjustModalPositionAndScale() {
      // Ensure the modal is positioned absolutely to be freely positioned
      this.$el.style.position = 'absolute';
      this.$el.style.width = `100vw`
      this.$el.style.height = '100vh'

      // Get the dimensions of the viewport
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Get the current scroll position
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      // Initially, let's not scale the modal and attempt to fit it by position only
      let scale = 1;

      // Calculate the position for the modal to be centered in the current view
      let modalWidth = this.$el.offsetWidth * scale; // Consider scaling
      let modalHeight = this.$el.offsetHeight * scale; // Consider scaling

      // If the modal is larger than the viewport, adjust scale to fit
      if (modalWidth > viewportWidth || modalHeight > viewportHeight) {
        scale = Math.max(viewportWidth / modalWidth, viewportHeight / modalHeight); // Add some padding
        modalWidth *= scale;
        modalHeight *= scale;
        this.$el.style.transform = `scale(${scale})`;
        this.$el.style.transformOrigin = 'top left';
      } else {
        this.$el.style.transform = '';
        this.$el.style.transformOrigin = '';
      }

      // Calculate centered position, considering the scale
      let left = scrollX + (viewportWidth - modalWidth) / 2;
      let top = scrollY + (viewportHeight - modalHeight) / 2;

      // Apply the calculated position
      this.$el.style.left = `${left}px`;
      this.$el.style.top = `${top}px`;
    },
    getFieldName() {
      if (!this.fieldKey) return "";

      // Insert a space before each uppercase letter and trim any leading space
      let result = this.fieldKey.replace(/([A-Z])/g, ' $1').trim();

      // Capitalize the first letter of the string
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    closeModal(event) {
      if (event.target.className != 'modal') {
        return;
      }

      this.$emit('closeModal');
    },
  },
  emits: ["closeModal", "visitOriginalField"],
};
</script>

<style scoped>
.modal {
  background: #131b28c8;
}

.card {
  border-radius: 0px;
}

.card-footer {
  margin-top: 0px;
}

.button.submit {
  padding: 15px 25px;
  background: var(--color-patient);
  color: #fff;
}
</style>