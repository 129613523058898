<template>
  <div class="component-sidebar">
    <draggable class="component-list" :list="components" :group="{ name: 'components', pull: 'clone', put: false }"
      :sort="false" :clone="cloneComponent" @start="onDragStart" @end="onDragEnd">
      <HtmlComponent :component="component" :interactable="false" v-for="component of components" :key="component.type" />
    </draggable>
  </div>
</template>

<script>
import HtmlComponent from "../form-components-html/HtmlComponent.vue";
import { VueDraggableNext } from "vue-draggable-next";

const ALL_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export default {
  name: "ComponentSidebar",
  data() {
    return {
      components: [
        {
          type: "HEADER",
          label: "Header",
          headerSize: 'h1'
        },
        {
          type: "PARAGRAPH",
          label: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
        {
          type: "TEXT",
          label: "Text Box",
        },
        {
          type: "TEXTAREA",
          label: "Long Response"
        },
        {
          type: 'DATE',
          label: 'Date Field',
        },
        {
          type: "EMAIL",
          label: "Email Field",
        },
        {
          type: "PHONE",
          label: "Phone Field",
        },
        {
          type: "FILE",
          label: "Image Upload Field"
        },
        {
          type: "NUMBER",
          label: "Number Box",
        },
        {
          type: "DROPDOWN",
          label: "Dropdown",
          options: ['Option 1', 'Option 2', 'Option 3']
        },
        {
          type: "MULTISELECT",
          label: "Multi-Select",
          options: ['Option 1', 'Option 2', 'Option 3']
        },
        {
          type: "SSN",
          label: "Social Security Number",
        },
        {
          type: "SIGNATURE",
          label: "Patient Signature",
          signatureType: 'signature-patient'
        },
        {
          type: 'CHECKBOX',
          label: 'Checkbox With Label',
          optional: true
        },
        {
          type: 'SPACER',
          label: 'Spacer'
        },
        {
          type: "STATE",
          label: "U.S. State",
          options: ALL_STATES
        },
        {
          type: 'IMAGE',
          label: 'Image',
          width: 300
        }
      ],
    };
  },
  methods: {
    onDragStart(event) {
      const component = this.components[event.oldIndex];
      this.$emit('onDragStart', component);
    },
    onDragEnd() {
      this.$emit('onDragEnd')
    },
    cloneComponent(component) {
      const cloned = structuredClone(component);
      cloned.key = `doNotImport${this.generateRandomNonce()}`
      cloned.optional = false;
      cloned.unique = true;

      if (cloned.type == 'FILE') {
        cloned.key = `uploadDocument${this.generateRandomNonce()}`
      }

      return cloned;
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
  },
  components: { HtmlComponent, draggable: VueDraggableNext },
  emits: ['onDragStart', 'onDragEnd']
};
</script>

<style scoped>
.component-sidebar {
  flex-basis: 350px;
  height: 100%;
  padding: 25px;
  padding-top: 100px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.component-sidebar::-webkit-scrollbar {
  display: none;
}

.component-list>.component {
  padding: 20px;
  background: #FFF;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  cursor: grab;
}

.row-columns>.component {
  margin-left: 25px;
}
</style>