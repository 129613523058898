<template>
  <div class="submission-list-item">
    <img src="@/assets/patient.png" alt="Patient Submission" class="icon">
    <h3 class="list-item-name">{{submission.name || 'Patient Submission'}}</h3>
    <div class="marker imported" v-if="submission.isImported"><img src="@/assets/done.png">Imported</div>
    <div class="marker submitted" v-else-if="submission.isSubmitted">Ready to Import</div>
    <div class="marker incomplete" v-else>Shared</div>
    <span class="list-item-date">{{ formatDate() }}</span>
    <!-- <span class="list-item-forms">{{  submission.furthestPageIndex }} of {{ submission.forms.length}} filled</span> -->
    <img src="@/assets/trash.png" alt="Delete Preset" class="icon-sm" @mousedown.prevent="$emit('deleteSubmission')">
  </div>
</template>

<script>
export default {
  name: 'SubmissionListItem',
  props: {
    submission: Object
  },
  methods: {
    formatDate() {
      const date = new Date(this.submission.dateCreated);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return 'just now'
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`
      }
      if (min <= 60) {
        return `${min} minutes ago`
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return '1 hour ago'
      }
      if (hr <= 24) {
        return `${hr} hours ago`
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return '1 day ago'
      }
      if (days <= 7) {
        return `${days} days ago`
      }

      return date.toLocaleDateString();
    }
  },
  emits: ['deleteSubmission']
}
</script>

<style scoped>
.submission-list-item {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 4px #00000022;
  cursor: pointer;
  border-radius: 10px;
}

.submission-list-item:not(:last-child) {
  margin-bottom: 20px;
}

.icon {
  display: block;
  height: 32px;
}

.icon-sm {
  display: block;
  height: 32px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

h3 {
  margin-left: 20px;
  font-weight: normal;
}

.list-item-date {
  margin-right: 20px;
  text-align: right;
  flex: 1;
  opacity: 0.5;
  font-size: 18.72px;
}

.list-item-forms {
  margin-right: 20px;
  opacity: 0.5;
  font-size: 18.72px;
}

.marker {
  padding: 5px 8px;
  margin-left: 20px;
  border-radius: 5px;
}

.marker.submitted {
  background: linear-gradient(to right, #a5ffab, #bbff9d);
  color: #006400;
}

.marker.imported {
  background: linear-gradient(to right, #e0e5ec, #e9edf2);
  display: flex;
  align-items: center;
  gap: 7px;
}

.marker.incomplete {
  color: #999;
  padding: unset;
}

.submission-list-item .marker img {
  display: block;
  height: 20px;
}
</style>