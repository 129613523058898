<template>
  <div class="annotation" ref="annot">
    <input
      class="input"
      ref="input"
      type="text"
      disabled="true"
      :value="getTextValue()"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
    />
    <img
      src="@/assets/trash.png"
      alt="trash"
      class="icon-sm"
      v-if="showTrash"
      @mousedown.prevent.stop="$emit('deleteAnnotation')"
    />
  </div>
</template>
  
<script>
export default {
  name: "ProviderTextAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  mounted() {
    this.refreshAnnotation();
  },
  watch: {
    annotation: {
      handler: function () {
        this.refreshAnnotation();
      },
      deep: true
    }
  },
  methods: {
    getTextValue() {
      return this.annotation.data || 'Example Text'
    },
    refreshAnnotation() {
      const annot = this.$refs.annot;
      if (!annot) return;

      const inputRef = this.$refs.input;
      if (!inputRef) return;

      // Compute font size based on rect size
      const rect = annot.getBoundingClientRect();
      if (rect.height == 0) return;
      inputRef.style.fontSize = rect.height + 'px';
      
      const ratio = inputRef.offsetWidth / inputRef.scrollWidth;
      if (ratio < 1) {
        inputRef.style.fontSize = (rect.height * ratio) + 'px';
      }
    },
  },
  emits: ['deleteAnnotation']
};
</script>
  
<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 100%;
  height: 25px;
  flex-direction: column;
  justify-content: flex-end;
}

.input {
  display: block;
  width: 100%;
  height: 1px;
  flex: 1;
  border: none;
  outline: none;
  font-size: 20px;
  background: transparent;
  opacity: 1 !important;
  border-radius: 0px;
  padding: 0px !important;
  color: #000 !important;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>