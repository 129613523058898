<template>
  <div class="form-group options-list-tab">
    <div class="form-label">Choices</div>
    <!-- If editing, show the search bar + dropdown -->
    <input ref="input" type="text" class="form-control" :value="optionsCloned.length + ' values'" @mousedown.prevent.stop="beginEditing">
    <div class="dropdown-list" v-if="editing" ref="optionsList">
      <div class="dropdown-item-option" v-for="option of optionsCloned" :key="option.idx">
        <input type="text" v-model="option.value" @change="updateOptions" ref="inputs" :disabled="locked">
      </div>
      <div class="dropdown-item-option" v-if="!locked">
        <input type="text" @input="createNewOption" placeholder="Add a new option...">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OptionsListTab',
  props: {
    options: Array,
    locked: Boolean
  },
  data() {
    return {
      editing: false,
      optionsCloned: []
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.onMouseDown);
  },
  unmounted() {
    window.removeEventListener('mousedown', this.onMouseDown);
  },
  watch: {
    options: {
      handler() {
        if (!this.options) {
          this.optionsCloned = [];
          return;
        }
        this.optionsCloned = this.options.map((opt, idx) => {
          return { idx: idx, value: opt }
        })
      },
      immediate: true
    }
  },
  methods: {
    beginEditing() {
      if (this.editing) return false;

      setTimeout(() => {
        this.editing = true;
      })
    },
    onMouseDown(event) {
      const optionsList = this.$refs.optionsList;
      if (this.editing && optionsList != null) {
        if (!optionsList.contains(event.target)) {
          this.editing = false;
          this.updateOptions();
        }
      }
    },
    updateOptions() {
      const options = this.optionsCloned
        .map(opt => opt.value)
        .filter(opt => opt.length >= 1)

      this.$emit('updateOptions', options);
    },
    createNewOption(event) {
      this.optionsCloned.push({
        idx: this.optionsCloned.length,
        value: event.target.value
      })

      event.target.blur();
      event.target.value = '';

      setTimeout(() => {
        const inputs = this.$refs.inputs;
        inputs[inputs.length - 1].focus();
        this.updateOptions();
      }, 1)
    }
  },
  emits: ['updateOptions']
}
</script>

<style scoped>
.form-group {
  position: relative;
}

.dropdown-list {
  left: 0px;
  top: 100%;
  position: absolute;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000044;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  z-index: 1000;
}

.dropdown-item-section:not(:first-child) {
  margin-top: 20px;
}

.dropdown-item-option input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #CCC;
  font-size: 16px;
}

.dropdown-item-option span {
  margin-left: 10px;
}

.dropdown-item-option:hover {
  background: #eef3ff;
  cursor: pointer;
}

.form-control {
  cursor: pointer;
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
}

</style>