<template>
  <div class="page-list">
    <PageNumListItem label="Prev" @click="prevPage()" />
    <PageNumListItem
      v-for="pageNum in getPageList()"
      :key="pageNum"
      :label="pageNum"
      :active="pageNum == store.submissionPage"
      @click="switchPage(pageNum)"
    />
    <PageNumListItem label="Next" @click="nextPage()" />
  </div>
</template>
  
  <script>
import { store, loadSubmissionsForCurrentPage } from "@/api";
import PageNumListItem from "./PageNumListItem.vue";

export default {
  name: "PageNumList",
  props: {
    isLoading: Boolean
  },
  methods: {
    getPageList() {
      // First find out left pad.
      const currentPage = store.submissionPage;
      const maxPage = store.totalPages;
      const pagesToShow = Math.min(5, maxPage);
      
      let numbers = [];
      if (currentPage <= Math.ceil(pagesToShow / 2)) {
        for (let i = 1; i <= pagesToShow; i++) {
          numbers.push(i);
        }
      } else if (currentPage >= maxPage - pagesToShow / 2) {
        for (let i = maxPage - pagesToShow + 1; i <= maxPage; i++) {
          numbers.push(i);
        }
      } else {
        for (let i = currentPage - Math.floor(pagesToShow / 2); i < currentPage + Math.ceil(pagesToShow / 2); i++) {
          numbers.push(i)
        }
      }
      
      return numbers;
    },
    async prevPage() {
      if (store.submissionPage <= 1) return;
      if (this.isLoading) return;
      store.submissionPage--;
      this.$emit('startLoading')
      await loadSubmissionsForCurrentPage();
      this.$emit('doneLoading')
    },
    async nextPage() {
      if (store.submissionPage >= store.totalPages) return;
      if (this.isLoading) return;
      store.submissionPage++;
      this.$emit('startLoading')
      await loadSubmissionsForCurrentPage();
      this.$emit('doneLoading')
    },
    async switchPage(pageNum) {
      if (this.isLoading) return;
      store.submissionPage = pageNum;
      this.$emit('startLoading')
      await loadSubmissionsForCurrentPage();
      this.$emit('doneLoading')
    },
  },
  data() {
    return {
      store,
    };
  },
  emits: ['startLoading', 'doneLoading'],
  components: { PageNumListItem },
};
</script>
  
<style scoped>
.page-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}
</style>