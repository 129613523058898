<template>
  <div class="properties-sidebar">
    <div class="properties-sidebar-list">
      <div class="list-content" v-if="annotationClone != null">
        <div class="section section-annotation">
          <h3>annotation</h3>
          <!-- EMR field -->
          <EhrFieldSearchTab :emrJson="emrJson" :selectedKey="annotationClone.key" @updateEhrField="updateEhrField"
            scope="FILE" />
            <!-- Part Options -->
          <div class="form-group" v-if="annotationClone.key && annotationClone.key.startsWith('partHistory')">
            <label for="image-height" class="form-label" style="word-break: break-all;">Part Label ({{ annotationClone.key }})</label>
            <input type="text" class="form-control" placeholder="Has condition XYZ" :value="getPartLabel()" @input="updatePartLabel">
          </div>
          <!-- Type -->
          <div class="form-group" v-if="canChangeType()">
            <label class="form-label">Type</label>
            <select type="text" class="form-control" v-model="annotationClone.type" @change="updateAnnotation">
              <option v-for="tp of types" :key="tp" :value="tp">{{ tp }}</option>
            </select>
          </div>
        </div>
        <div class="section section-properties">
          <h3>Properties</h3>
          <!-- Checkboxes -->
          <div class="form-group form-group-checkboxes">
            <div class="horizontal-checkbox-line" v-if="!isInputBox()">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="!annotationClone.optional"
                @updateValue="updateIsRequired" />
              <label>Field is required</label>
            </div>
            <div class="horizontal-checkbox-line">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="annotationClone.background == true"
                @updateValue="updateHasBackground" />
              <label>Field has background</label>
            </div>
            <div class="horizontal-checkbox-line" v-if="isInputBox()">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="annotationClone.group != null"
                @updateValue="updateIsInGroup" />
              <label>Field is in group</label>
            </div>
            <div class="horizontal-checkbox-line" v-if="annotationClone.type == 'TEXT'">
              <CheckBox colorFilled="#EEE" colorLine="#000" :value="annotationClone.wrapEnabled"
                @updateValue="updateWrapEnabled" />
              <label>Wrap to next line</label>
            </div>
          </div>
          <!-- Signature options -->
          <div class="form-group" v-if="annotationClone.type == 'SIGNATURE'">
            <label class="form-label">Signature Type</label>
            <select type="text" class="form-control" v-model="annotationClone.signatureType" @change="updateAnnotation()">
              <option value="signature-patient">Patient Signature</option>
              <option value="initials-patient">Patient Initials</option>
              <option value="signature-guardian">Guardian Signature</option>
              <option value="initials-guardian">Guardian Initials</option>
              <option value="signature-witness">Witness Signature</option>
              <option value="initials-witness">Witness Initials</option>
            </select>
          </div>
          <!-- Options List -->
          <OptionsListTab v-if="hasOptionsDropdown()" :options="annotationClone.options" :locked="!isDoNotImport()"
            @updateOptions="updateOptions" />
          <!-- Group Tab -->
          <GroupTab v-if="annotationClone.optional && annotationClone.group != null" :group="annotationClone.group"
            :allAnnotations="allAnnotations" @updateAnnotationGroup="updateAnnotationGroup" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EhrFieldSearchTab from '../form-editor-shared/EhrFieldSearchTab.vue'
import OptionsListTab from '../form-editor-shared/OptionsListTab.vue'
import GroupTab from '../form-editor-shared/GroupTab.vue'
import CheckBox from '@/components/CheckBox.vue'

export default {
  name: 'PropertiesSidebar',
  props: {
    emrJson: Object,
    annotation: Object,
    allAnnotations: Array
  },
  data() {
    return {
      types: ['TEXT', 'NUMBER', 'DATE', 'CHECKBOX', 'CIRCLEBOX', 'DROPDOWN', 'EMAIL', 'PHONE', 'SSN', 'SIGNATURE', 'STATE', 'MULTISELECT', 'DRAW_BOX', 'PROVIDER_SIGNATURE', 'PROVIDER_TEXT'],
      annotationClone: null
    }
  },
  watch: {
    annotation: {
      handler(newVal) {
        if (!newVal) {
          this.annotationClone = null;
        }
        this.annotationClone = structuredClone(this.annotation)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    updateEhrField(field) {
      // Update the annotation key
      if (field.unique) {
        this.annotationClone.key = field.key + this.generateRandomNonce();
      } else {
        this.annotationClone.key = field.key;
      }

      // Update the annotation type
      if (field.type != null) {
        this.annotationClone.type = field.type;
      }

      // Required flag
      if (field.optional != null) {
        this.annotationClone.optional = field.optional;
      } else {
        this.annotationClone.optional = false;
      }

      // Update options
      if (field.options != null) {
        this.annotationClone.options = field.options;
      }

      // Emit to parent
      this.updateAnnotation();
    },
    updateOptions(options) {
      this.annotationClone.options = options;
      this.updateAnnotation();
    },
    updateIsRequired(value) {
      this.annotationClone.optional = !value;
      this.annotationClone.group = undefined; // If it's a required field, ignore group
      this.updateAnnotation();
    },
    updateHasBackground(value) {
      this.annotationClone.background = value;
      this.updateAnnotation();
    },
    updateIsInGroup(value) {
      if (value) {
        const randomId = 'Group' + this.generateRandomNonce(3);
        this.annotationClone.group = { id: randomId, rule: 'FILL_ANY' }
      } else {
        this.annotationClone.group = undefined;
      }

      this.updateAnnotation();
    },
    updateWrapEnabled(value) {
      this.annotationClone.wrapEnabled = value;
      this.updateAnnotation();
    },
    updateAnnotationGroup(group) {
      this.annotationClone.group = group;
      this.updateAnnotation();
      this.$emit('updateGroupRule', group.id, group.rule);
    },
    generateRandomNonce(length = 10) {
      let id = "";
      for (let i = 0; i < length; i++) {
        id += Math.floor(Math.random() * 10);
      }
      return id;
    },
    isDoNotImport() {
      return this.annotationClone.key && this.annotationClone.key.startsWith('doNotImport');
    },
    canChangeType() {
      return this.annotationClone.key && (this.annotationClone.key.startsWith('doNotImport') || this.annotationClone.key.startsWith('partHistory'));
    },
    hasOptionsDropdown() {
      const type = this.annotationClone.type;
      return type == 'DROPDOWN' || type == 'MULTISELECT';
    },
    isInputBox() {
      const type = this.annotationClone.type;
      return type == 'CHECKBOX' || type == 'CIRCLEBOX';
    },
    updateAnnotation() {
      this.$emit('updateAnnotation', this.annotationClone);
    },
    updatePartLabel(event) {
      const key = this.annotationClone.key;
      const value = event.target.value;
      if (!key) return;

      let keyWithoutSuffix = key;
      if (keyWithoutSuffix.indexOf('_') > 0) {
        keyWithoutSuffix = key.substring(0, keyWithoutSuffix.indexOf('_'));
      }

      this.annotationClone.key = `${keyWithoutSuffix}_${encodeURIComponent(value)}`;
      this.updateAnnotation();
    },
    getPartLabel() {
      const key = this.annotationClone.key;
      if (!key) return;

      if (key.indexOf('_') > 0) {
        return decodeURIComponent(key.substring(key.indexOf('_') + 1));
      }

      return '';
    }
  },
  components: { EhrFieldSearchTab, OptionsListTab, GroupTab, CheckBox },
  emits: ['updateAnnotation', 'updateGroupRule']
}
</script>

<style scoped>
.properties-sidebar {
  flex-basis: 350px;
  height: 100%;
  padding: 25px;
  padding-top: 100px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.properties-sidebar::-webkit-scrollbar {
  display: none;
}

.properties-sidebar-list {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 8px #00000022;
  border-radius: 10px;
}

.section {
  padding: 25px;
  padding-bottom: 50px;
}

.section h3 {
  margin-bottom: 50px;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  line-height: 40px;
  font-size: 20px;
  letter-spacing: 2px;
}

.form-group-checkboxes {
  margin-top: 0px;
}

.horizontal-checkbox-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.horizontal-checkbox-line:not(:first-child) {
  margin-top: 15px;
}

.horizontal-checkbox-line .checkbox {
  flex-shrink: 0;
}

.horizontal-checkbox-line label {
  margin-left: 10px;
  font-size: 16px;
  color: var(--color-secondary);
}

.form-control {
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
}
</style>