<template>
  <div class="preset-list-item" @contextmenu.prevent="openDropdown" ref="item">
    <img src="@/assets/preset.png" alt="Preset" class="icon">
    <h3>{{preset.name}}</h3>
    <img src="@/assets/trash.png" alt="Delete Preset" class="icon-sm" @mousedown.prevent="$emit('deletePreset')">
    <!-- Dropdown options -->
    <transition name="appear" mode="out-in">
      <div class="dropdown" ref="dropdown" v-if="dropdownOpen" :style="{left: dropdownPosLeft, top: dropdownPosTop}">
        <div class="dropdown-option" @mousedown.prevent="openPresetInNewTab">Open in new tab</div>
        <div class="dropdown-option danger" @mousedown.prevent="deletePreset">Delete form</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PresetListItem',
  props: {
    preset: Object
  },
  data() {
    return {
      dropdownOpen: false,
      dropdownPosLeft: '0px',
      dropdownPosTop: '0px'
    }
  },
  unmounted() {
    if (this.dropdownOpen) {
      window.removeEventListener('mousedown', this.onMouseDown);
    }
  },
  methods: {
    onMouseDown(event) {
      const dropdown = this.$refs.dropdown;
      const target = event.target;
      if (dropdown && !dropdown.contains(target)) {
        this.dropdownOpen = false;
        window.removeEventListener('mousedown', this.onMouseDown);
      }
    },
    openDropdown(event) {
      const el = this.$refs.item;
      const bb = el.getBoundingClientRect();
      const x = event.pageX - bb.left;
      const y = event.pageY - bb.top;

      this.dropdownOpen = true;
      this.dropdownPosLeft = x + 'px';
      this.dropdownPosTop = y + 'px';

      window.addEventListener('mousedown', this.onMouseDown);
    },
    openPresetInNewTab() {
      this.dropdownOpen = false;
      const protocol = window.location.protocol;
      const host = window.location.host;
      const account = this.preset.ownerNameUnique;
      const presetUrl = this.preset.url;
      const url = `${protocol}//${host}/${account}/${presetUrl}`;
      window.open(url, '_blank');
    },
    deletePreset() {
      this.dropdownOpen = false;
      this.$emit('deletePreset');
    },
    openFormEditor() {
      this.dropdownOpen = false;
      this.$router.push(`/forms/${this.form.id}`);
    }
  },
  emits: ['deletePreset']
}
</script>

<style scoped>
.preset-list-item {
  position: relative;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 4px #00000022;
  background: #FFF;
  cursor: grab;
  border-radius: 10px;
}

.preset-list-item:not(:last-child) {
  margin-bottom: 20px;
}

.icon {
  display: block;
  height: 32px;
}

.icon-sm {
  display: block;
  height: 32px;
  filter: saturate(0);
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

h3 {
  margin-left: 20px;
  font-weight: normal;
  flex: 1;
}

.dropdown {
  position: absolute;
  background: #FFF;
  box-shadow: 0px 1px 4px #00000022;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.dropdown-option {
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
}

.dropdown-option.danger {
  color: #a90000;
}

.dropdown-option:hover {
  background: #F5F5F5;
}
</style>