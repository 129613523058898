import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView';
import RegisterView from '@/views/RegisterView';
// import PatientView from '@/views/PatientView';
import AccountFormsView from '@/views/AccountFormsView';
import AccountPresetsView from '@/views/AccountPresetsView';
import AccountSubmissionsView from '@/views/AccountSubmissionsView';
import AccountSettingsView from '@/views/AccountSettingsView';
import FormEditorView from '@/views/FormEditorView'
import FormFillerView from '@/views/FormFillerView';
import AdminPortalView from '@/views/AdminPortalView'
import CheckInView from '@/views/CheckInView'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/forms/:id',
    name: 'Form Editor',
    component: FormEditorView
  },
  {
    path: '/submission/:privateUrl',
    name: 'Patient View',
    component: FormFillerView
  },
  {
    path: '/forms',
    name: 'Account Forms',
    component: AccountFormsView
  },
  {
    path: '/presets',
    name: 'Account Presets',
    component: AccountPresetsView
  },
  {
    path: '/submissions',
    name: 'Account Submissions',
    component: AccountSubmissionsView
  },
  {
    path: '/settings',
    name: 'Account Settings',
    component: AccountSettingsView
  },
  {
    path: '/:office/:url',
    name: 'Form Filler',
    component: FormFillerView
  },
  {
    path: '/admin',
    component: AdminPortalView
  },
  {
    path: '/checkin/:uniqueName',
    name: 'Check In View',
    component: CheckInView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'is-active'
})

export default router;