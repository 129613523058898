<template>
  <div class="annotation" ref="annot">
    <canvas
      class="input"
      ref="canvas"
      @mousedown="toggleCheckbox()"
      :error="error"
      :filled="isAnnotationFilled()"
    ></canvas>
    <img
      src="@/assets/trash.png"
      alt="trash"
      class="icon-sm"
      v-if="showTrash"
      @mousedown.prevent.stop="$emit('deleteAnnotation')"
    />
  </div>
</template>

<script>
export default {
  name: "InputBoxAnnotation",
  props: {
    annotation: Object,
    interactable: Boolean,
    showTrash: Boolean,
  },
  data() {
    return {
      value: "No",
      error: false,
      image: null,
    };
  },
  mounted() {
    this.image = new Image();
    this.image.src = "/cross.png";

    // Render after image loads
    this.image.onload = () => {
      setTimeout(() => {
        this.refreshAnnotation();
      }, 0)
    }
  },
  watch: {
    annotation: {
      handler: function () {
        this.refreshAnnotation();
      },
      deep: true
    }
  },
  methods: {
    updateValue(value) {
      const lastVal = this.value;
      this.value = value;

      if (this.value != lastVal) {
        setTimeout(() => {
          this.refreshAnnotation();
        }, 0)
      }
    },
    onAnnotationChange() {
      this.$emit("onUpdateValue", this.value);
    },
    refreshAnnotation(scale = 3) {
      const canvas = this.$refs.canvas;
      if (!canvas) return;
      const context = canvas.getContext("2d");
      canvas.width = canvas.offsetWidth * scale;
      canvas.height = canvas.offsetHeight * scale;

      context.clearRect(0, 0, canvas.width, canvas.height);
      const isFilled = this.isAnnotationFilled();
      if (isFilled || !this.interactable) {
        if (this.interactable) {
          context.globalAlpha = 1;
        } else {
          context.globalAlpha = 0.3;
        }

        if (this.annotation.type == 'CHECKBOX') {
          if (canvas.width > canvas.height) {
            context.drawImage(this.image, (canvas.width - canvas.height) / 2, 0, canvas.height, canvas.height);
          } else {
            context.drawImage(this.image, 0, (canvas.height - canvas.width) / 2, canvas.width, canvas.width);
          }
        } else {
          context.strokeStyle = '#000';
          context.lineWidth = 5;
          context.beginPath();
          context.ellipse(canvas.width / 2, canvas.height / 2, canvas.width / 2 - 1, canvas.height / 2 - 1, 0, 0, Math.PI * 2);
          context.stroke();
        }

        context.globalAlpha = 1;
      }
    },
    toggleCheckbox() {
      if (!this.interactable) return;

      if (this.value == "No") {
        this.value = "Yes";
      } else {
        this.value = "No";
      }

      this.refreshAnnotation();
      this.onAnnotationChange();
    },
    isAnnotationFilled() {
      return this.value == "Yes";
    },
    setError(value) {
      this.error = value;
    },
    checkAnnotationIsFilled() {
      return true;
    },
  },
  emits: ["onUpdateValue", "deleteAnnotation"],
};
</script>

<style scoped>
.annotation {
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  flex-direction: column;
  justify-content: flex-end;
}

.input {
  display: block;
  width: 100%;
  height: 1px;
  flex: 1;
  border: none;
  outline: none;
  background: var(--color-patient-field);
  opacity: 1;
  cursor: pointer;
  border-radius: 0px;
  padding: 0px;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  /* border-bottom: 1px solid #000; */
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: calc(50% - 12.5px);
  left: -30px;
  height: 25px;
  filter: saturate(0);
  /* background: #FFF; */
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}
</style>