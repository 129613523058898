<template>
  <div class="group-tab form-group">
    <div class="form-group-inner">
      <div class="form-label">Group ID ({{ getGroupMemberCount() }} members)</div>
      <input class="form-control" v-model="groupCloned.id" @input="updateGroupId">
    </div>
    <div class="form-group-inner">
      <div class="form-label">Group rule</div>
      <select class="form-control" v-model="groupCloned.rule" @change="updateAnnotationGroup">
        <option value="FILL_ANY">Select any</option>
        <option value="FILL_UP_TO_ONE">Select up to one</option>
        <option value="FILL_ONE">Select exactly one</option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GroupTab',
  props: {
    allAnnotations: Array,
    group: Object
  },
  data() {
    return {
      groupCloned: null
    }
  },
  watch: {
    group: {
      handler() {
        this.groupCloned = structuredClone(this.group)
      },
      immediate: true
    }
  },
  methods: {
    updateAnnotationGroup() {
      this.$emit('updateAnnotationGroup', this.groupCloned)
    },
    updateGroupId() {

      // See if we can find this group in any of the other annotations
      const annot = this.allAnnotations.find(annot => {
        if (annot.group == null) return false;
        return annot.group.id == this.groupCloned.id;
      })

      // If we find it, copy the rule
      if (annot) {
        this.groupCloned.rule = annot.group.rule;
      }

      this.updateAnnotationGroup();
    },
    getGroupMemberCount() {
      const annots = this.allAnnotations.filter(annot => {
        if (annot.group == null) return false;
        return annot.group.id == this.groupCloned.id;
      })

      return annots.length;
    }
  },
  emits: ['updateAnnotationGroup']
}
</script>

<style scoped>
.form-group {
  position: relative;
}

.form-control {
  background: #F5F5F5;
  border-bottom: 1px solid #DDD;
  border-radius: 5px;
}


.form-group-inner:not(:first-child) {
  margin-top: 20px;
}
</style>