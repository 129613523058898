<template>
  <div id="scroll">
    <AccountNavbar />
    <div class="content">
      <div class="content-header">
        <h1>Account Presets</h1>
        <div class="button-list">
          <button class="button primary small" @click="openModal('create')">
            Create Preset
          </button>
        </div>
      </div>
      <transition name="appear">
        <div class="content-body" v-if="loaded">
          <draggable :list="store.presets" @end="onDragEnd">
            <PresetListItem
              v-for="preset of store.presets"
              :key="preset.id"
              :preset="preset"
              @click="openModal('details', preset)"
              @deletePreset="openModal('delete', preset)"
            />
          </draggable>
          <p v-if="store.presets.length == 0 && store.forms.length == 0">
            Before you can create a preset,
            <router-link to="/forms">please add some forms</router-link> to your
            account.
          </p>
          <p v-else-if="store.presets.length == 0">
            You have no presets in your account. Create one to get started.
          </p>
        </div>
      </transition>
    </div>
    <transition name="modal">
      <CreatePresetModal v-if="modal.id == 'create'" @closeModal="closeModal" />
      <PresetDetailsModal
        v-else-if="modal.id == 'details'"
        :preset="modal.data"
        @closeModal="closeModal"
      />
      <DeletePresetModal
        v-else-if="modal.id == 'delete'"
        :preset="modal.data"
        @closeModal="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import AccountNavbar from "../components/AccountNavbar.vue";
import PresetListItem from "../components/account-presets/PresetListItem.vue";
import { VueDraggableNext } from "vue-draggable-next";
import CreatePresetModal from "../modals/account-presets/CreatePresetModal.vue";
import PresetDetailsModal from "../modals/account-presets/PresetDetailsModal.vue";
import DeletePresetModal from "../modals/account-presets/DeletePresetModal.vue";
import { store, parallel, reorderPresets, fetchAccountForms, fetchAccountPresets } from "@/api";

export default {
  name: "AccountFormsView",
  data() {
    return {
      store,
      modal: {
        id: "",
        open: false,
        data: null,
      },
      loaded: false,
    };
  },
  async created() {
    try {
      this.loaded = false;
      await parallel(fetchAccountForms(), fetchAccountPresets());
    } catch (err) {
      console.error("Failed to fetch account forms or presets", err);
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    openModal(id, data) {
      this.modal = {
        open: true,
        id: id,
        data: data,
      };
    },
    closeModal() {
      this.modal = {
        open: false,
        id: "",
        data: null,
      };
    },
    async onDragEnd() {
      try {
        const presetIds = store.presets.map((preset) => preset.id);
        await reorderPresets(presetIds);
      } catch (err) {
        console.error("Failed to reorder presets", err);
      }
    },
  },
  components: {
    AccountNavbar,
    PresetListItem,
    draggable: VueDraggableNext,
    CreatePresetModal,
    PresetDetailsModal,
    DeletePresetModal,
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 1000px;
  min-height: 700px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 0px 8px #00000011;
  border-radius: 10px;
  margin: auto;
}

.content-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.button-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.button-list .button {
  margin-left: 20px;
}
</style>