<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 450px">
      <div class="card-header">
        <h1>Duplicate Form</h1>
        <p>What should we call this new form that we are about to copy?</p>
      </div>
      <form class="card-body" @submit.prevent="attemptDuplicate">
        <div class="form-group">
          <label for="name" class="form-label">Form Name</label>
          <input
            id="name"
            type="text"
            name="name"
            ref="name"
            class="form-control"
            v-model="name"
            :placeholder="form.name"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
        </div>
        <div class="form-group">
          <label for="name" class="form-label">Form ID (do not change)</label>
          <input
            id="id"
            type="text"
            name="id"
            class="form-control"
            v-model="id"
            placeholder="Form ID"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
        </div>
      </form>
      <div class="card-footer">
        <button class="button primary" @click.prevent="attemptDuplicate">
          <img
            v-if="pending"
            src="/loading.svg"
            alt="Loading"
            class="loading"
          />
          <span v-else>Duplicate Form</span>
        </button>
        <p class="error" v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { duplicateForm } from "@/api";

export default {
  name: "DuplicateFormModal",
  props: {
    form: Object
  },
  data() {
    return {
      name: "",
      id: this.form.id,
      error: "",
      pending: false,
    };
  },
  mounted() {
    setTimeout(() => {
      const nameRef = this.$refs.name;
      if (nameRef) nameRef.focus();
    }, 500);
  },
  methods: {
    async attemptDuplicate() {
      this.error = "";
      if (!this.name) {
        this.error = "Please provide a form name";
        return;
      }

      try {
        this.pending = true;
        await duplicateForm(this.name, this.id);
        this.$emit('closeModal');
      } catch (err) {
        console.error("Failed to create new form", err);
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    closeModal(event) {
      if (event.target.className != "modal") {
        return;
      }
      this.$emit("closeModal");
    },
  },
  emits: ["closeModal"],
};
</script>