<template>
  <button class="button" :active="isActive">{{ label }}</button>
</template>
  
  <script>
import { store } from "@/api";

export default {
  name: "PageNumListItem",
  props: {
    label: String,
    isActive: Boolean,
  },
  data() {
    return {
      store,
    };
  },
};
</script>
  
<style scoped>
.button {
  flex-basis: 50px;
  background: #fff;
  padding: 10px;
}

.button:hover {
  background-color: #eee;
}

.button[active="true"] {
  background-color: #eee;
}
</style>