<template>
  <div class="form" ref="form">
    <div class="page" v-for="page of pages" :key="page.pageNum">
      <img class="page-background" :src="page.pageSrc" :alt="page.pageNum" @load="onPageLoaded" />
      <HtmlAnnotation v-for="annot of page.annotations" ref="annotations" :key="annot" :formWidth="formWidth"
        :annotation="annot" :interactable="isFirstInstanceOfKey(annot)" :showTrash="false" :selected="false"
        :valuesMap="valuesMap" :style="{
      background: annot.background ? `#FFF` : '',
      left: `${annot.x * 100}%`,
      bottom: `${annot.y * 100}%`,
      width: `${annot.width * 100}%`,
      height: `${annot.height * 100}%`,
    }" @onUpdateValue="onUpdateValue(annot.key, $event)" @openSignatureModal="openSignatureModal(annot)"
        @wrapToNext="wrapToNext" @click="onClickAnnotation(annot)" />
    </div>
  </div>
</template>

<script>
import HtmlAnnotation from "../form-annotations-pdf/HtmlAnnotation.vue";

export default {
  name: "FillablePdfForm",
  props: {
    form: Object,
    valuesMap: Map,
    keysOccurringFirstTime: Array,
  },
  data() {
    return {
      pages: [],
      formWidth: 0,
      firstUnfilledElement: null,
    };
  },
  watch: {
    valuesMap: {
      handler() {
        this.updateValues();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadPages();

    window.addEventListener("resize", this.onWindowResize);

    setTimeout(() => {
      console.log(this.keysOccurringFirstTime);
      this.updateValues();
    }, 0);
  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    loadPages() {
      const publicImagesPath = process.env.VUE_APP_PUBLIC_IMAGES;

      let pageNum = 1;
      for (let filePagePath of this.form.filePagePaths) {
        const formFilePages = this.form.filePages || [];
        const pageInForm = formFilePages.find((pg) => pg.page == pageNum);
        const annotations = pageInForm ? pageInForm.pageAnnotations : [];

        this.pages.push({
          pageNum: pageNum,
          pageSrc: publicImagesPath + filePagePath,
          annotations: annotations,
        });
        pageNum++;
      }
    },
    onUpdateValue(key, value) {

      // If they update a checkbox that can only have one, unset the others
      const annot = this.getAnnotationByKey(key);
      if (annot && annot.group != null) { // TODO: what happens if a non-checkbox has a group
        const group = annot.group;
        if (group.rule == 'FILL_ONE' || group.rule == 'FILL_UP_TO_ONE') {
          const annotsInGroup = this.getAllAnnotationsInGroup(group.id)
          for (let ant of annotsInGroup) {
            if (ant.key == key) continue;
            this.$emit('onUpdateValue', ant.key, 'No');
          }
        }

        // Clear all annot errors
        const annotElsInGroup = this.getAllAnnotationElementsInGroup(group.id);
        for (let annotEl of annotElsInGroup) {
          annotEl.setError(false);
        }
      }

      this.$emit("onUpdateValue", key, value);
    },
    updateValues() {
      // Get component elements on page
      const annotationElements = this.$refs.annotations;
      if (!annotationElements) return;

      // Update all component values
      for (let annotationElement of annotationElements) {
        if (annotationElement.updateValue == undefined) continue;

        const annotation = annotationElement.annotation;
        if (annotation == null) continue;

        const key = annotation.key;
        if (key == null) continue;

        const value = this.valuesMap.get(key);
        if (value == null) continue;

        annotationElement.updateValue(value);
      }
    },
    onClickAnnotation(annot) {
      if (!this.isFirstInstanceOfKey(annot)) {
        this.$emit('openRepeatedFieldModal', annot.key);
      }
    },
    checkFormIsFilled() {
      this.firstUnfilledElement = null;

      // Check each annot if its filled
      let success = true;
      const annotationElements = this.$refs.annotations || [];
      for (let annotationEl of annotationElements) {
        const result = annotationEl.checkAnnotationIsFilled();
        if (!result) {
          if (this.firstUnfilledElement == null) {
            this.firstUnfilledElement = annotationEl;
          }

          success = false;
        }
      }

      // Store all annotations for each "FILL_ONE" groups
      const groupAnnots = new Map();
      for (let annotationEl of annotationElements) {
        // If annotation is part of a group and has rule fill "exactly one"
        const annot = annotationEl.annotation;
        const group = annot.group;
        if (!group || group.rule != 'FILL_ONE') continue;

        if (!groupAnnots.has(group.id)) {
          groupAnnots.set(group.id, []);
        }

        groupAnnots.get(group.id).push(annotationEl);
      }

      // Loop through all the groups
      for (let annotEls of groupAnnots.values()) {

        // Figure out if one is filled
        let oneFilled = false;
        for (let annotEl of annotEls) {
          if (annotEl.isAnnotationFilled()) {
            oneFilled = true;
          }
        }

        // If one is not filled, mark them all as error
        if (!oneFilled) {
          success = false;
          for (let annotEl of annotEls) {
            annotEl.setError(true);
            if (this.firstUnfilledElement == null) {
              this.firstUnfilledElement = annotEl;
            }
          }
        }
      }


      return success;
    },
    getFirstUnfilledElement() {
      return this.firstUnfilledElement;
    },
    getFirstElementOfKey(key) {
      const annotationElements = this.$refs.annotations;
      for (let annotationElement of annotationElements) {
        const annotation = annotationElement.annotation;
        if (annotation && annotation.key == key) {
          return annotationElement.$el;
        }
      }

      return null;
    },
    openSignatureModal(annotation) {
      this.$emit(
        "openSignatureModal",
        annotation.signatureType,
        annotation.key
      );
    },
    onWindowResize() {
      const annotationElements = this.$refs.annotations;
      if (!annotationElements) return;
      for (let annotationEl of annotationElements) {
        annotationEl.refreshAnnotation();
      }
    },
    onPageLoaded() {
      const annotations = this.$refs.annotations;
      if (!annotations) return;

      for (let annot of annotations) {
        annot.refreshAnnotation();
      }
    },
    isFirstInstanceOfKey(annotation) {
      const key = annotation.key;

      // First instance appears on another page
      if (!this.keysOccurringFirstTime.includes(key)) {
        return false;
      }

      // Check all instances on this form
      for (let page of this.pages) {
        for (let annot of page.annotations) {
          // Found the first instance!
          if (annot.key == key) {
            // Is it me?
            return annot == annotation;
          }
        }
      }

      return false;
    },
    getAllAnnotations() {
      const annotations = [];

      for (let page of this.pages) {
        for (let annot of page.annotations) {
          annotations.push(annot);
        }
      }

      return annotations;
    },
    getAllAnnotationsInGroup(id) {
      const annotations = [];

      for (let page of this.pages) {
        for (let annot of page.annotations) {
          if (annot.group == null) continue;
          if (annot.group.id !== id) continue;
          annotations.push(annot);
        }
      }

      return annotations;
    },
    getAllAnnotationElementsInGroup(id) {
      const annotationEls = [];

      const annotationElements = this.$refs.annotations || [];
      for (let annotationEl of annotationElements) {
        const annot = annotationEl.annotation;
        if (annot.group && annot.group.id == id) {
          annotationEls.push(annotationEl);
        }
      }

      return annotationEls;
    },
    getAnnotationByKey(key) {
      for (let page of this.pages) {
        for (let annot of page.annotations) {
          if (annot.key == key) {
            return annot;
          }
        }
      }

      return null;
    },
    getSortedAnnotations() {
      const annotations = [];

      for (let page of this.pages) {
        const pageAnnotations = [];
        for (let annot of page.annotations) {
          pageAnnotations.push(annot);
        }
        pageAnnotations.sort((a, b) => {
          // Determine the maximum starting point and minimum ending point
          const y1 = a.y;
          const y2 = a.y + a.height;
          const y3 = b.y;
          const y4 = b.y + b.height;
          const start = Math.max(y1, y3);
          const end = Math.min(y2, y4);

          // Calculate the overlap
          const overlap = Math.max(0, end - start);

          // Calculate the total range covered by both lines
          const totalRange = Math.max(y2, y4) - Math.min(y1, y3);

          // Calculate the overlap percentage relative to the total range
          // Ensure totalRange is not zero to avoid division by zero
          let overlapPercentage = 0;
          if (totalRange > 0) {
            overlapPercentage = (overlap / totalRange) * 100;
          }

          if (overlapPercentage < 0.5) {
            // go by Y
            return b.y - a.y;
          } else {
            // if they overlap at least 50% consider them as the same Y, go by x
            return a.x - b.x;
          }
        })

        annotations.push(...pageAnnotations);
      }

      return annotations;
    },
    wrapToNext(annotationRef, textToMove) {
      const annotation = annotationRef.annotation;
      const sortedAnnotations = this.getSortedAnnotations();
      const textAnnotations = sortedAnnotations.filter(annot => annot.type == 'TEXT');

      const idx = textAnnotations.findIndex(annot => annot == annotation);
      if (idx < 0) return;

      const nextAnnotation = textAnnotations[idx + 1];
      if (!nextAnnotation) return;

      const annotationRefs = this.$refs.annotations;
      const nextAnnotationRef = annotationRefs.find(ref => ref.annotation == nextAnnotation);
      if (!nextAnnotationRef) return;

      // Focus it
      const nextInputEl = nextAnnotationRef.$el.querySelector('input');
      if (!nextInputEl) return;
      nextInputEl.focus();
      nextInputEl.value = textToMove + nextInputEl.value;

      const charsToMove = textToMove.length;
      if (nextInputEl.createTextRange) {
        var FieldRange = nextInputEl.createTextRange();
        FieldRange.moveStart('character', charsToMove);
        FieldRange.collapse();
        FieldRange.select();
      } else {
        nextInputEl.setSelectionRange(charsToMove, charsToMove);
      }
    }
  },
  components: { HtmlAnnotation },
  emits: ["onUpdateValue", "openSignatureModal", "openRepeatedFieldModal"],
};
</script>

<style scoped>
.form {
  width: 100%;
  max-width: 1200px;
}

.page {
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 8px #00000022;
}

.page:not(:last-child) {
  margin-bottom: 15px;
}

.page-background {
  width: 100%;
  display: block;
  pointer-events: none;
  user-select: none;
}

.annotation {
  position: absolute;
}
</style>