<template>
  <div class="auth-code">
    <input
      type="tel"
      v-for="digit of digits"
      v-model="digit.value"
      class="form-control"
      ref="digits"
      :key="digit.index"
      @input="onInput(digit.index, $event)"
      @keydown="onKeyDown(digit.index, $event)"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
    />
  </div>
</template>

<script>
export default {
  name: "AuthCodeInput",
  props: {
    length: Number,
  },
  data() {
    return {
      digits: [],
    };
  },
  mounted() {
    for (let i = 0; i < this.length; i++) {
      this.digits.push({ index: i, value: '' });
    }
  },
  methods: {
    cleanValue(value) {
      value = value.replace(/[^0-9]/g, '');
      return value;
    },
    setDigit(index, value) {
      if (index >= this.digits.length) return;
      this.digits[index].value = value;
    },
    focusDigit(index) {
      const digitElements = this.$refs.digits;
      digitElements[Math.min(index, digitElements.length - 1)].focus();
    },
    onInput(index) {
      const value = this.digits[index].value;
      const cleanValue = this.cleanValue(value);
      const valueLength = cleanValue.length;

      // Apply value starting at current digit
      this.setDigit(index, '');
      for (let i = 0; i < valueLength; i++) {
        this.setDigit(index + i, cleanValue[i]);
      }

      // Focus next digit
      this.focusDigit(index + valueLength);
      this.updateValue();
    },
    onKeyDown(index, event) {
      const value = this.digits[index].value;
      const valueLength = value.length;

      if (event.code == 'Backspace') {
        if (valueLength > 0) {
          this.setDigit(index, '');
          this.updateValue();
        } else if (index > 0) {
          this.setDigit(index - 1, '');
          this.focusDigit(index - 1);
          this.updateValue();
        }
      }
    },
    updateValue() {
      const value = this.digits.map(dig => dig.value).join('')
      const cleanValue = this.cleanValue(value);
      this.$emit('updateValue', cleanValue) 
    },
  },
  emits: ['updateValue']
};
</script>

<style scoped>
.auth-code {
  display: flex;
  flex-direction: row;
}

.auth-code .form-control {
  text-align: center;
  width: 1px;
  flex: 1;
  font-weight: normal;
  font-size: 32px;
}

.auth-code .form-control:not(:last-child) {
  margin-right: 25px;
}
</style>