<template>
  <div class="component">
    <label class="label">{{ component.label }}<span class="optional-tag" v-if="component.optional">(optional)</span></label>
    <textarea
      class="input"
      :name="component.label"
      :disabled="!interactable"
      :filled="isComponentFilled()"
      :error="error"
      v-model="value"
      @input="onComponentInput()"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      rows="3"
    />
    <img src="@/assets/trash.png" alt="trash" class="icon-sm" v-if="showTrash" @click="deleteComponent">
  </div>
</template>

<script>

export default {
  name: "TextAreaComponent",
  props: {
    component: Object,
    interactable: Boolean,
    showTrash: Boolean
  },
  data() {
    return {
      value: '',
      error: false
    }
  },
  methods: {
    updateValue(value) {
      this.value = value;
    },
    onComponentInput() {
      // Pass to parent, who will help pass to valuesMap
      this.$emit('onUpdateValue', this.value);
    },
    deleteComponent() {
      this.$emit('deleteComponent')
    },
    isComponentFilled() {
      return this.value.length > 0;
    },
    checkComponentIsFilled() {
      if (this.component.optional) {
        return true;
      }

      if (this.isComponentFilled()) {
        return true;
      }

      this.error = true;
      return false;
    }
  },
  emits: ['onUpdateValue', 'deleteComponent']
};
</script>

<style scoped>
.component {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: var(--color-patient-field);
  border-bottom: 1px solid #00000022;
  opacity: 1;
  resize: vertical;
  border-radius: 0px;
}

.input[error="true"] {
  background: var(--color-patient-field-error);
}

.input[filled="true"] {
  background: var(--color-patient-field-filled);
}

.input:focus {
  border-bottom: 1px solid #000;
}

.input::placeholder {
  opacity: 0.3;
  color: #000;
}

.input:disabled {
  pointer-events: none;
}

.icon-sm {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  filter: saturate(0);
  background: #FFF;
}

.icon-sm:hover {
  cursor: pointer;
  filter: none;
}

.optional-tag {
  margin-left: 5px;
  opacity: 0.5;
}
</style>