<template>
  <div class="modal" @mousedown="closeModal">
    <div class="card" style="max-width: 1000px">
      <div class="card-header">
        <h1>{{ account.name }}</h1>
        <p>{{ account.email }}, {{ account.phoneNumber }}</p>
      </div>
      <form class="card-body">
        <div class="chart-section">
          <canvas ref="dailyChart"></canvas>
        </div>
        <div class="chart-section">
          <div class="chart-container">
            <canvas ref="osChart"></canvas>
          </div>
          <div class="chart-container">
            <canvas ref="ratingChart"></canvas>
          </div>
        </div>
      </form>
      <div class="card-footer">
        <button class="button primary" @click="restrictedLogin()">Restricted Login</button>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { postJson } from '@/api';

export default {
  name: "AccountDetailsModal",
  props: {
    account: Object,
  },
  data() {
    return {
      loading: false,
      pending: false,
      error: "",
      chart: null
    };
  },
  async mounted() {
    try {
      this.loading = true;
      const stats = await this.fetchSubmissionStats();
      this.setupDailyChart(stats.submissions);
      this.setupOSChart(stats.operatingSystems);
      this.setupRatingChart(stats.ratings);
    } catch (err) {
      console.error(err);
      alert('Error: ' + err.message);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async fetchSubmissionStats() {
      // Fetch submissions
      const stats = await postJson('/admin/submission-stats', { id: this.account.id });
      return stats;
    },
    async restrictedLogin() {
      if (!confirm('Are you sure you want to sign into this account? You will need to sign back into your admin account later.')) {
        return;
      }

      // Fetch submissions
      const json = await postJson('/admin/restricted-login', { id: this.account.id });
      console.log(json);
      this.$router.push('/forms');
    },
    setupDailyChart(submissions) {
      // Get min and max date
      const now = new Date();
      const day = 24 * 60 * 60 * 1000;
      let currDate = new Date(now.getTime() - 30 * day);
      let endDate = new Date();

      // For each day between sign up and today's date
      const dayCounts = [];
      while (currDate <= endDate) {
        const dateString = currDate.toLocaleDateString();

        // Get number of submissions created (broken down by category)
        let counts = { incomplete: 0, submitted: 0, imported: 0, checkin: 0 }
        if (submissions[dateString]) {
          counts = submissions[dateString]
        }

        // Push to list
        const dayCount = { date: dateString, ...counts };
        dayCounts.push(dayCount);

        // Continue
        currDate.setDate(currDate.getDate() + 1); // Increment day by one
      }

      const data = {
        labels: dayCounts.map(c => c.date),
        datasets: [
          {
            label: 'Incomplete',
            data: dayCounts.map(c => c.incomplete),
            backgroundColor: 'rgba(255, 99, 132, 0.7)',
            fill: true
          },
          {
            label: 'Checkin (Incomplete)',
            data: dayCounts.map(c => c.checkin),
            backgroundColor: 'rgba(255, 135, 99, 0.7)',
            fill: true
          },
          {
            label: 'Complete',
            data: dayCounts.map(c => c.submitted),
            backgroundColor: 'rgba(65, 235, 112, 0.7)',
            fill: true
          },
          {
            label: 'Imported',
            data: dayCounts.map(c => c.imported),
            backgroundColor: 'rgba(75, 192, 192, 0.7)',
            fill: true
          },
        ]
      }

      const config = {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              stacked: true
            },
            x: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Submissions'
            }
          }
        }
      }

      const chartCanvas = this.$refs.dailyChart;
      const chartContext = chartCanvas.getContext('2d');
      this.chart = new Chart(chartContext, config);

    },
    setupOSChart(operatingSystems) {

      const labels = [];
      const counts = [];

      for (let key of Object.keys(operatingSystems)) {
        labels.push(key);
        counts.push(operatingSystems[key]);
      }

      const data = {
        labels: labels,
        datasets: [
          {
            data: counts
          }
        ]
      }

      const config = {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Operating Systems'
            }
          }
        }
      }

      const chartCanvas = this.$refs.osChart;
      const chartContext = chartCanvas.getContext('2d');
      this.chart = new Chart(chartContext, config);

    },
    setupRatingChart(ratings) {

      const labels = [];
      const counts = [];

      for (let key of Object.keys(ratings)) {
        labels.push(key);
        counts.push(ratings[key]);
      }

      const data = {
        labels: labels,
        datasets: [
          {
            data: counts
          }
        ]
      }

      const config = {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Patient Ratings'
            }
          }
        }
      }

      const chartCanvas = this.$refs.ratingChart;
      const chartContext = chartCanvas.getContext('2d');
      this.chart = new Chart(chartContext, config);

    },
    closeModal(event) {
      const nameElement = this.$refs.name;
      if (nameElement && nameElement == document.activeElement) {
        return;
      }

      if (event.target.className != "modal") {
        return;
      }

      this.$emit("closeModal");
    },
  },
  emits: ["closeModal"],
};
</script>

<style scoped>
.card-body {
  height: 500px;
  overflow-y: auto;
}

.chart-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.chart-container {
  display: block;
  flex: 1;
  overflow: hidden;
}
</style>